import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import {
  createSeniorHomeRequest,
  getSeniorHomesRequest,
} from "../redux/actions";
import { AppState } from "../redux/types";
import { LinkContainer } from "react-router-bootstrap";

const SeniorHomeForm = () => {
  const { id } = useParams<{ id: string }>();
  const resources = useSelector((state: AppState) => state.seniorHomes);
  const { list } = resources;
  let senior_home = undefined;
  try {
    senior_home = list?.find((o) => o.id === parseInt(id));
  } catch (error) {}

  const [formData, setFormData] = useState({
    id: senior_home ? parseInt(senior_home.id) : -1,
    reference: senior_home?.reference || "",
    name: senior_home?.name || "",
    show: typeof senior_home?.show === "boolean" ? senior_home?.show : true,
    createdAt: undefined,
    updatedAt: undefined,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getSeniorHomesRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
        reference: value.replace(/\s/g, "_").toLocaleLowerCase(),
      };
    });
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: checked,
      };
    });
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      createSeniorHomeRequest({
        id: formData.id === -1 ? undefined : formData.id,
        reference: formData.reference,
        name: formData.name,
        show: formData.show,
        createdAt: formData.createdAt,
        updatedAt: formData.updatedAt,
      })
    );
    dispatch(getSeniorHomesRequest());
    history.push("/admin/senior_homes");
  };

  return (
    <Container className="register-form-container">
      <Form onSubmit={submitHandler} className="service-form">
        <Form.Group controlId="senior_home-text">
          <Form.Label>Name</Form.Label>
          <Form.Control
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            readOnly={user.readOnly || !user.isAdmin}
          />
        </Form.Group>
        <Form.Group controlId="senior_home-reference">
          <Form.Label>Reference</Form.Label>
          <Form.Control
            placeholder="Reference"
            name="reference"
            value={formData.reference}
            onChange={handleChange}
            readOnly={user.readOnly || !user.isAdmin}
          />
        </Form.Group>
        <Form.Group controlId="senior_home-show">
          <Form.Check
            label="Show"
            type="checkbox"
            name="show"
            checked={formData.show}
            onChange={handleCheck}
            disabled={user.readOnly || !user.isAdmin}
          />
        </Form.Group>
        <Row>
          <Col>
            <LinkContainer to={`/admin/senior_homes`}>
              <Button className="tallenna paymentmethod-back-button">
                Takaisin
              </Button>
            </LinkContainer>
          </Col>
          {!user.readOnly && user.isAdmin && (
            <Col
              style={{
                textAlign: "right",
              }}
            >
              <Button
                className="create-service-button tallenna"
                type="submit"
                disabled={!formData.name?.length || !formData.reference?.length}
              >
                Tallenna
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Container>
  );
};

export default SeniorHomeForm;
