import {
    BlogItem,
    CREATE_BLOG_FAIL,
    CREATE_BLOG_REQUEST,
    CREATE_BLOG_SUCCESS,
    DELETE_BLOG_FAIL,
    DELETE_BLOG_REQUEST,
    DELETE_BLOG_SUCCESS,
    DeleteBlogRequestAction,
    GET_BLOGS_FAIL,
    GET_BLOGS_REQUEST,
    GET_BLOGS_SUCCESS,
    RESET_BLOG,
} from '../types'

// For admin
// TODO: update and remove Blog
export const createBlogRequest = (blog: BlogItem) => {
    return {
        type: CREATE_BLOG_REQUEST,
        payload: {
            blog
        }
    }
}

export const createBlogSuccess = (blog: BlogItem) => {
    return {
        type: CREATE_BLOG_SUCCESS,
        payload: {
            blog
        }
    }
}

export const createBlogFail = (error: any) => {
    return {
        type: CREATE_BLOG_FAIL,
        payload: {
            error
        }
    }
}

export const deleteBlogRequest = (id: DeleteBlogRequestAction) => {
    return {
        type: DELETE_BLOG_REQUEST,
        payload: id
    }
}

export const deleteBlogSuccess = () => {
    return {
        type: DELETE_BLOG_SUCCESS,
    }
}

export const deleteBlogFail = (error: any) => {
    return {
        type: DELETE_BLOG_FAIL,
        payload: error,
    }
}

export const getBlogsRequest = () => {
    return {
        type: GET_BLOGS_REQUEST,
    }
}

export const getBlogsSuccess = (blogs: BlogItem[]) => {
    return {
        type: GET_BLOGS_SUCCESS,
        payload: blogs
    }
}
export const getBlogsFail = (error: any) => {
    return {
        type: GET_BLOGS_FAIL,
        payload: {
            error
        }
    }
}

export const ResetBlog = () => {
    return {
        type: RESET_BLOG,
        payload: {}
    }
}