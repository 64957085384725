import Customer from './Customer'
import Senior from './Senior'
import Relatives from './Relatives'

const MembersList = ({
  member,
  index,
  role,
    isSenior,
  mobileNumber,
  email,
  skypeEmail,
  nurseringHome,
  lastName,
  homeAddress,
  department,
}: any) => {
  return (
    <ul className='members-list'>
      {role === 'customer' && (
        <Customer
          member={member}
          isSenior={isSenior}
        />
      )}
      {role === 'senior' && (
        <Senior
          member={member}
        />
      )}
      {role === 'member' && (
      <Relatives member={member} index={index} role={role} />
      )}
    </ul>
  )
}

export default MembersList
