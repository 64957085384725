import {
  VideoMeet,
  JOIN_ROOM_REQUEST,
  VideoMeetActions,
  JOIN_ROOM_REQUEST_FAIL,
  JOIN_ROOM_REQUEST_SUCCESS,
  RESET_VIDEO_MEET,
  SET_LK_TOKEN,
  SET_PRE_JOIN_TOKEN,
  REMOVE_ROOM_NAME,
} from '../types';

const initialState: any = {
  loading: false,
  error: null,
  message: {},
  participants: [],
};

const videoMeet = (
  state = initialState,
  action: VideoMeetActions
): VideoMeet => {
  switch (action.type) {
    case JOIN_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case JOIN_ROOM_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case JOIN_ROOM_REQUEST_SUCCESS:
      const { preJoinToken, ...payload } = action.payload;
      return {
        ...state,
        loading: false,
        participants: state.participants.map((participant: any) =>
          participant.preJoinToken === preJoinToken
            ? { ...participant, ...payload }
            : participant
        ),
      };

    case RESET_VIDEO_MEET:
      return {
        ...initialState,
      };

    case SET_LK_TOKEN:
      return {
        ...state,
        participants: state.participants.map((participant: any) =>
          participant.roomId === action.payload.roomId
            ? {
                ...participant,
                lkAccessToken: action.payload.lkAccessToken,
                roomName: action.payload.roomName,
                total: action.payload.total,
              }
            : participant
        ),
      };

    case REMOVE_ROOM_NAME:
      return {
        ...state,
        participants: state.participants.map((participant: any) => {
          if (participant.roomId === action.payload.roomId) {
            const { roomName, userChoice, ...updatedParticipant } = participant;

            return {
              ...updatedParticipant,
              lkAccessToken: action.payload.lkAccessToken,
              total: action.payload.total,
            };
          } else {
            return participant;
          }
        }),
      };

    case SET_PRE_JOIN_TOKEN:
      const isExist = state.participants.find(
        (participant: any) => participant?.preJoinToken === action.payload
      );

      return {
        ...state,
        participants: isExist
          ? [...state.participants]
          : [...state.participants, { preJoinToken: action.payload }],
      };

    default:
      return state;
  }
};

export default videoMeet;
