import {
  JOIN_ROOM_REQUEST,
  JOIN_ROOM_REQUEST_FAIL,
  JOIN_ROOM_REQUEST_SUCCESS,
  RESET_VIDEO_MEET,
  SET_LK_TOKEN,
  SET_PRE_JOIN_TOKEN,
  REMOVE_ROOM_NAME,
} from '../types';

export const joinRoomRequest = (joinRequest: any) => {
  return {
    type: JOIN_ROOM_REQUEST,
    payload: joinRequest,
  };
};

export const joinRoomRequestSuccess = (participant: any) => {
  return {
    type: JOIN_ROOM_REQUEST_SUCCESS,
    payload: participant,
  };
};

export const joinRoomRequestFail = (error: any) => {
  return {
    type: JOIN_ROOM_REQUEST_FAIL,
    payload:
      error.response && error.response.data.message
        ? error.response.data
        : { status: error.response.status, message: error.message },
  };
};

export const resetVideoMeet = () => {
  return {
    type: RESET_VIDEO_MEET,
  };
};

export const setLkToken = (data: any) => {
  return {
    type: SET_LK_TOKEN,
    payload: data,
  };
};

export const setPreJoinToken = (token: string) => {
  return {
    type: SET_PRE_JOIN_TOKEN,
    payload: token,
  };
};

export const removeRoomName = (data: any) => {
  return {
    type: REMOVE_ROOM_NAME,
    payload: data,
  };
};
