import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Table} from 'react-bootstrap'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {deleteAlertRequest, getAlertsRequest} from '../redux/actions'
import {AppState} from '../redux/types'
import {useHistory} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";

const AlertsList = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector((state: AppState) => state.user)
    useEffect(() => {
        if (user && user.isAdmin) {
            dispatch(getAlertsRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const resources = useSelector((state: AppState) => state.alerts)
    const {list, loading, deleted_at, error} = resources

    const deleteHandler = (id: any) => {
        console.log('id', id)
        if (window.confirm('Haluatko varmasti poistaa hälytyksen?')) {
            dispatch(deleteAlertRequest(id))
        }
    }

    const addNewService = () => {
        history.push('/admin/alert/new')
    }

    useEffect(() => {
        dispatch(getAlertsRequest())
    }, [dispatch, deleted_at])

    return (
        <>
            <h1 className='my-3 ml-3' style={{color: '#4e5180', fontFamily: 'Poppins, sanf-serif'}}>Alerts</h1>
            <Button
                onClick={addNewService}
            >Add a new alert</Button>
            {loading ? (
                <Loader/>
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <Table striped bordered hover responsive className='users-table'>
                    <thead className='user-title'>
                    <tr className='user'>
                        <th className='text-center'>ID</th>
                        <th className='text-center'>TEXT</th>
                        <th className='text-center'>Start</th>
                        <th className='text-center'>End</th>
                        <th className='text-center'>{user.readOnly ? 'Katso' : 'Päivitä /Poista'}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {list && list.map((alert: any) => (
                        <tr key={alert.id}>
                            <td>{alert.id}</td>
                            <td>{alert.text}</td>
                            <td>{alert.start}</td>
                            <td>{alert.end}</td>
                            <td>
                                <LinkContainer to={`/admin/alert/${alert.id}`}>
                                    <Button className='btn-sm'>
                                        <i
                                            className={user.readOnly ? 'fas fa-eye' : 'fas fa-edit'}
                                            style={{color: '#fff'}}
                                        />
                                    </Button>
                                </LinkContainer>
                                {!user.readOnly &&
                                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(alert.id)}>
                                        <i className='fas fa-trash'/>
                                    </Button>
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
        </>
    )
}

export default AlertsList
