import { call, put, select, takeLatest } from "redux-saga/effects";
import api from "../../utils/api-helper/api";

import { createSeniorHomeFail, createSeniorHomeSuccess, deleteSeniorHomeFail, deleteSeniorHomeSuccess, getSeniorHomesFail, getSeniorHomesSuccess } from "../actions";
import { CreateSeniorHomeRequestAction, DeleteSeniorHomeRequestAction } from "../types";

function* getSeniorHomesSaga() {
    try {
        //@ts-ignore
        const res = yield api.get("/senior_homes");
        yield put(getSeniorHomesSuccess(res.data.payload));
    } catch (error) {
        yield put(getSeniorHomesFail(error));
    }
}

// for admin
function* createSeniorHomeSaga(action: CreateSeniorHomeRequestAction) {
    const { senior_home } = action.payload;

    try {
        //@ts-ignore
        const res = yield api.put("/senior_homes", senior_home);
        yield put(createSeniorHomeSuccess(res));
        yield call(getSeniorHomesSaga);
    } catch (error) {
        yield put(createSeniorHomeFail(error));
    }
}

function* deleteSeniorHomeSaga(action: DeleteSeniorHomeRequestAction) {
    const seniorHomeId = action.payload;
    try {
        //@ts-ignore
        const res = yield api.delete(`/senior_homes/${seniorHomeId}`);
        if (res.status === 200) {
            yield put(deleteSeniorHomeSuccess());
            yield call(getSeniorHomesSaga);
        }
    } catch (error) {
        yield put(deleteSeniorHomeFail(error));
    }
}

function* saveState() {
    //@ts-ignore
    const state = yield select();
    yield localStorage.setItem("initState", JSON.stringify(state));
}

const sagaWatcher = [takeLatest("GET_ALERTS_REQUEST", getSeniorHomesSaga), takeLatest("CREATE_SENIOR_HOME_REQUEST", createSeniorHomeSaga), takeLatest("DELETE_SENIOR_HOME_REQUEST", deleteSeniorHomeSaga), takeLatest("*", saveState)];

export default sagaWatcher;
