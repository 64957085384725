import Carousel from "react-bootstrap/Carousel";
import styles from "./carousel.module.css";

export type CarouselItem = {
    title: string;
    content: string;
    author: string;
};

function HomeCarousel({ items }: { items: CarouselItem[] }) {
    return (
        <Carousel indicators={false} interval={6000} className={styles.container}>
            {items.map((item, key) => (
                <Carousel.Item key={key}>
                    <div className={styles.item}>
                        <h3 className={styles.title}>{item.title}</h3>
                        <h4 className={styles.content}>{item.content}</h4>
                        <p className={styles.author}>{item.author}</p>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default HomeCarousel;
