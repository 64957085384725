import {
    AlertItem,
    CREATE_ALERT_FAIL,
    CREATE_ALERT_REQUEST,
    CREATE_ALERT_SUCCESS,
    DELETE_ALERT_FAIL,
    DELETE_ALERT_REQUEST,
    DELETE_ALERT_SUCCESS,
    DeleteAlertRequestAction,
} from '../types'

// For admin
// TODO: update and remove Alert
export const createAlertRequest = (alert: AlertItem) => {
    return {
        type: CREATE_ALERT_REQUEST,
        payload: {
            alert
        }
    }
}

export const createAlertSuccess = (alert: AlertItem) => {
    return {
        type: CREATE_ALERT_SUCCESS,
        payload: {
            alert
        }
    }
}

export const createAlertFail = (error: any) => {
    return {
        type: CREATE_ALERT_FAIL,
        payload: {
            error
        }
    }
}

export const deleteAlertRequest = (id: DeleteAlertRequestAction) => {
    return {
        type: DELETE_ALERT_REQUEST,
        payload: id
    }
}

export const deleteAlertSuccess = () => {
    return {
        type: DELETE_ALERT_SUCCESS,
    }
}

export const deleteAlertFail = (error: any) => {
    return {
        type: DELETE_ALERT_FAIL,
        payload: error,
    }
}
