import {select, takeLatest} from 'redux-saga/effects'


function* saveState() {
    //@ts-ignore
    const state = yield select()
    yield localStorage.setItem('initState', JSON.stringify(state))
}

const sagaWatcher = [
    takeLatest('*', saveState)
]

export default sagaWatcher
