import { GET_PRODUCTS_FAIL, GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, ProductType, UPDATE_PRODUCTS } from "../types";

export const getProductsSuccess = (products: ProductType[]) => {
    return {
        type: GET_PRODUCTS_SUCCESS,
        payload: products,
    };
};
export const getProductsFail = (error: any) => {
    return {
        type: GET_PRODUCTS_FAIL,
        payload: {
            error,
        },
    };
};
export const getProductsRequest = (category?: string) => {
    return {
        type: GET_PRODUCTS_REQUEST,
        payload: category,
    };
};
export const updateProducts = (products: ProductType[]) => {
    return {
        type: UPDATE_PRODUCTS,
        payload: products,
    };
};
