import { classNames } from "../../utils/classnames";
import styles from "./icon-header.module.css";
type IconHeaderType = {
    icon: string;
    text: string;
    className?: string;
};
const IconHeader = ({ icon, text, className }: IconHeaderType) => {
    return (
        <div className={classNames(styles.container, className)}>
            <img src={icon} className={styles.icon} alt="I" />
            <span className={styles.text}>{text}</span>
        </div>
    );
};

export default IconHeader;
