import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { getNursesRequest, removeNurseRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import moment from "moment";
import Loader from "../components/Loader";
import Message from "../components/Message";

const Nurses = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  const nurses = useSelector((state: any) => state.nurses);
  const { list, loading, error, message } = nurses;

  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getNursesRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const handleDeleteNurse = (id: number) => {
    dispatch(removeNurseRequest(id));
  };

  return (
    <>
      <div className="alert-message">
        {Object.keys(message).length !== 0 && (
          <Message variant={message?.status === 200 ? "info" : "danger"}>
            <div className="message-text">{message?.message}</div>
          </Message>
        )}
      </div>
      <div className="headind-button-div">
        <h1 className="my-3 ml-3 module-title">Hoitaja</h1> {/* Nurse */}
        {user.isAdmin && (
          <LinkContainer to={`/admin/nurses/add`}>
            <Button className="add-button">LISÄÄ UUSI SAIRAANHOITAJA</Button>
            {/* ADD NEW NURSE */}
          </LinkContainer>
        )}
      </div>
      <div>
        {loading ? (
          <Loader />
        ) : error ? (
          <div className="error-message">
            <Message variant="danger">
              <div className="message-text">{error.message}</div>
            </Message>
          </div>
        ) : list?.length > 0 ? (
          <Table striped bordered hover responsive className="users-table">
            <thead className="user-title">
              <tr className="user">
                <th className="text-center">ID</th>
                <th className="text-center">Etunimi</th>
                <th className="text-center">Sukunimi</th>
                <th className="text-center">Seniori määrätty</th>
                <th className="text-center">Sähköposti tunnus</th>
                <th className="text-center">Puhelinnumero</th>
                <th className="text-center">Päiväys Päivitetty</th>
                <th className="text-center">Hoivakoti</th>
                <th className="text-center">
                  {user.isAdmin ? "Päivitä /Poista" : "Näkymä"}
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item: any) => (
                <tr key={item.userId}>
                  <td className="text-center">{item.userId}</td>
                  <td className="text-center">{item.firstName}</td>
                  <td className="text-center">{item.lastName}</td>
                  <LinkContainer
                    to={`/admin/nurses/seniors/${item.userId}`}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <td className="text-center">{item.seniorIds.length}</td>
                  </LinkContainer>
                  <td className="text-center">{item.email}</td>
                  <td className="text-center">{item.mobileNumber}</td>
                  <td className="text-center">
                    {moment(item?.updatedAt).format("MM/DD/YYYY")}
                  </td>
                  <td className="text-center">
                    {item.seniorHomes === null ? "-" : item.seniorHomes?.name}
                  </td>
                  <td className="text-center">
                    <LinkContainer
                      className="action-items"
                      to={`/admin/nurses/${item.userId}`}
                    >
                      <Button className="btn-sm">
                        <i
                          className={
                            user.isAdmin ? "fas fa-edit" : "fas fa-eye"
                          }
                          style={{ color: "#fff" }}
                        />
                      </Button>
                    </LinkContainer>
                    {user.isAdmin && (
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => handleDeleteNurse(item.userId)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Message variant="light">No Nurse Available!!</Message>
        )}
      </div>
    </>
  );
};

export default Nurses;
