import {Col, Container, Row} from 'react-bootstrap'

const ContactInfo = () => {
    return (
        <>
            <Container
                fluid
                style={{
                    height: `calc(100vh - 66px)`,
                    width: '100vw',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/connect2.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'right top'
                }}
                className='img-fluid contact-pic'
            >
                <Row>
                    <Col xs={12} md={6} lg={7} xl={8} className='contact-texts-column'>
                        <p className='ota-yhteyttä'>Ota yhteyttä</p>
                        <p className='apu'>ja kysy lisää!</p>
                    </Col>
                    <Col xs={12} md={6} lg={5} xl={4} className='contact-texts-column'>
                        <Container className='contact-container justify-content-md-center'>
                            <div className="contact-form ">
                                <div className="texts-for-companies">
                                    Digihappy Mediti Oy
                                    <br/>
                                    Otakaari 5, 02150 Espoo
                                    <br/>
                                    puh <a className='linkki' href="tel:010 517 2070">010 517 2070</a>
                                    <br/>
                                    WhatsApp <a className='linkki' href=" https://wa.me/358503210100" target="_blank">050
                                    321 0100</a>
                                    <br/>
                                    <a className='linkki' href="mailto:digihappy@mediti.fi">digihappy@mediti.fi</a>
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ContactInfo
