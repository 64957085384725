import { put, select, takeLatest } from "redux-saga/effects";

import { getCategoriesFail, getCategoriesSuccess } from "../actions";
import { GET_CATEGORIES_REQUEST, GetCategoriesRequestAction } from "../types";
import cmsApi from "../../utils/api-helper/cmsApi";

function* getCategoriesSaga(action: GetCategoriesRequestAction) {
    try {
        //@ts-ignore
        const res = yield cmsApi.get(`/categories?populate=*&filters[slug]=${action.payload}`);
        yield put(getCategoriesSuccess(res.data.data[0]));
    } catch (error) {
        yield put(getCategoriesFail(error));
    }
}

function* saveState() {
    //@ts-ignore
    const state = yield select();
    yield localStorage.setItem("initState", JSON.stringify(state));
}

const sagaWatcher = [takeLatest(GET_CATEGORIES_REQUEST, getCategoriesSaga), takeLatest("*", saveState)];

export default sagaWatcher;
