// import { useSelector } from 'react-redux'
import {LinkContainer} from 'react-router-bootstrap'
import {Nav, Navbar} from 'react-bootstrap'
import {useSelector} from "react-redux";
import {AppState} from "../redux/types";

// import { AppState } from '../redux/types'

const SubNavBar = () => {
    const user = useSelector((state: AppState) => state.user)
    // const orders = useSelector((state: AppState) => state.user.orders)
    // const isPaid = orders?.map((o: any) => o.isPaid)
    // isPaid default = false

    return (
        <>
            {user?.id && <Navbar expand='lg' collapseOnSelect className='subnavbar userpage-nav'>
                {/*{isPaid ? (*/}
                {/*  <LinkContainer to='/user'>*/}
                {/*    <Nav.Link className='mr-auto account-link'>Asiakastili</Nav.Link>*/}
                {/*  </LinkContainer>*/}
                {/*) : (*/}
                <nav className='justify-content-left'>
                    {/*<LinkContainer to='/video' style={{color: '#fff'}}>*/}
                    {/*    <Nav.Link className='mr-auto video-link'>Videopalvelu</Nav.Link>*/}
                    {/*</LinkContainer>*/}
                    <LinkContainer to='/instructions'>
                        <Nav.Link className='mr-auto health-link'>
                        <span
                            style={{
                                fontWeight: window.location.pathname.includes('/instructions') ? 'bold' : 'unset',
                            }}
                        >
                            <span>
                            Ohjeet
                            </span>
                        </span>
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/tili'>
                        <Nav.Link className='mr-auto account-link'>
                        <span
                            style={{
                                fontWeight: window.location.pathname.includes('/tili/') ? 'bold' : 'normal',
                            }}
                        >
                            <span>
                            Asiakastili
                                </span>
                        </span>
                        </Nav.Link>
                    </LinkContainer>
                </nav>
                {/*)}*/}
            </Navbar>}
        </>
    )
}

export default SubNavBar
