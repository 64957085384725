import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Banner from "../components/layout/banner/Banner";
import { useSelector } from "react-redux";
import { AppState } from "../redux/types";

const styles = {
    customBody: {
        border: "none",
    },
};

const ForCompanies = () => {
    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;
    const currentDate = Date.now();
    return (
        <>
            <Helmet>
                <title>Hoivapalveluihin turvarannekkeet, tablettipalvelut, videopuhelut, kuulutukset </title>
                <meta name="description" content="Innovatiiviset tablettipalvelut ja turvarannekkeet hoivapalveluihin. Videopuhelut. Kuulutukset. Uudet aktiviteetit. Kirjaaminen." />
            </Helmet>
            <Banner
                backgroundImage="/assets/images/banners/company.webp"
                height={`calc(100vh - ${
                    56 +
                    56 *
                        list?.filter((el) => {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title">Etähoitoon, asumispalveluihin, kunnille ja järjestöille</h1>
                    <p className="global-banner-text">Digihappylta saat samalla enemmän!</p>
                </div>
            </Banner>

            <Container fluid className="my-5 services-container services-container-80" style={{ minWidth: "min(900px, 100%)" }}>
                <div>
                    <h2 className="global-section-title global-primary">Tablettipalvelu Kotihoitoon - Etähoito</h2>
                    <p className="global-text global-text-normal global-text-dark">Toimiva etähoito edullisesti. Videopuhelupalvelu hoitajan ja perheen kanssa. Samalla seniorille kiinnostavaa tekemistä.</p>
                </div>
                <div>
                    <h2 className="global-section-title global-primary">Tablettipalvelu Kunnille ja Järjestöill - Tue hauraimpia</h2>
                    <p className="global-text global-text-normal global-text-dark">
                        Tue vanhuksen tai vammaisen toimintakykyä ja sosiaalisuutta. Helpota omaishoitajan taakkaa. Helpot videopuhelut perheen, omaishoitajan ja vapaaehtoistyöntekijän kanssa. Lisäksi aktivoivaa tekemistä.
                    </p>
                </div>
                <div>
                    <h2 className="global-section-title global-primary">Hoitajatabletti - Uudet aktiviteetit ja kirjaaminen</h2>
                    <p className="global-text global-text-normal global-text-dark">
                        Arvosta hoitajaa parhailla työvälineillä. Uusiin aktiviteetteihin ja helppoon kirjaamiseen. Jumppa, musiikki, pelit, toiminnanohjaus. Lääketieteellisesti validoidut terveystestit ja kyselyt. Koulutus,
                        lääketieteellinen konsultaatio ja ohjeet sekä it-tuki. Kaikki valmiina.
                    </p>
                </div>
                <div>
                    <h2 className="global-section-title global-primary">Turvaranneke - Paikannus, puhe ja hälytykset</h2>
                    <p className="global-text global-text-normal global-text-dark">
                        Ruotsin suosituin paikantava turvaranneke Posifon Digihappylta. Puhe ja tarkka gps-paikannus kaikkialla. Helppo turvaranneke tai kaulanappi. Automaattihälytys seniorin poistuessa alueelta, kaatuessa tai akun
                        loppuessa.{" "}
                        <Link to="#" className="global-text">
                            Lue lisää.
                        </Link>
                    </p>
                </div>
            </Container>

            <h2 className="global-section-title global-primary global-text-strong text-center mt-6 mb-5">Miksi Digihappy?</h2>

            <section className="image-rows">
                <div className="global-grid-container-2">
                    <Card className="home-image-card">
                        <Card.Img src={"/assets/images/company/1.webp"} className="global-card-image" />

                        <Card.Body className="company-body" style={styles.customBody}>
                            <Card.Title as="div" className="image-text">
                                Helpot videopuhelut. Vastaus haluttaessa ilman kosketusta, soitto kuvaa painamalla.
                            </Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="home-image-card">
                        <Card.Img src={"/assets/images/company/2.webp"} className="global-card-image" />

                        <Card.Body className="company-body" style={styles.customBody}>
                            <Card.Title as="div" className="image-text">
                                Kivaa tekemistä. Valmiina halutut, testatut sovellukset ja parhaat asetukset. Äänikirjat, radiot, YleAreena, uutiset ja vaikka pasianssi.
                            </Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="home-image-card">
                        <Card.Img src="/assets/images/company/3.jpg" className="global-card-image" />

                        <Card.Body className="company-body" style={styles.customBody}>
                            <Card.Title as="div" className="image-text">
                                “Moderni keskusradio”, jolla hoitaja viestii video- ja äänikuulutuksilla samanaikaisesti kaikille asukkaille. Helposti omasta kännykästä.
                            </Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className="home-image-card">
                        <Card.Img src="/assets/images/company/hoitaja-käyttää-senioritabletti.webp" width="auto" className="global-card-image" alt="Vihreään paitaan pukeutunut nainen pitelee tablettia." />

                        <Card.Body className="company-body" style={styles.customBody}>
                            <Card.Title as="div" className="image-text">
                                Helpota hoitajan työtä. Uudet aktiviteetit ja helppo kirjaaminen. Kaikki valmiina.
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </div>
                <Container fluid className="my-5 services-container services-container-80" style={{ minWidth: "min(900px, 100%)", textAlign: "center" }}>
                    <Link className="ota-yhteyttä-linkki text-center" to="/yhteydenotto">
                        Ota yhteyttä
                    </Link>
                    <p className="global-text global-text-normal text-center my-3">Tablettipalvelun kustannus alkaen 40 € (alv 0) /kk. Samsung-tabletti, jalusta, liittymä, videopuhelupalvelu, sovellukset, it-tuki ja etähallinta.</p>
                </Container>
            </section>
        </>
    );
};

export default ForCompanies;
