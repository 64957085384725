import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteUserRequest, getUsersRequest } from "../redux/actions";
import { AppState } from "../redux/types";

const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getUsersRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, user, history]);

  const users: any = useSelector((state: AppState) => state.users);
  console.log(users);
  const { loading, error, list } = users;

  const deleteHandler = (id: any) => {
    console.log("id", id);
    const user = list.users?.find((el: { id: any; }) => el.id === id);
    if (window.confirm(`Haluatko varmasti poistaa asiakkaan "${user?.firstName} ${user?.lastName}" ID:${id} ?`)) {
      dispatch(deleteUserRequest(id));
    }
  };

  return (
    <>
      <h1
        className="my-3 ml-3"
        style={{ color: "#4e5180", fontFamily: "Poppins, sanf-serif" }}
      >
        Asiakkaat
      </h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error.message}</Message>
      ) : (
        <Table striped bordered hover responsive className="users-table">
          <thead className="user-title">
            <tr className="user">
              <th className="text-center">ID</th>
              <th className="text-center">R-ID</th>
              <th className="text-center">ROOLI</th>
              <th className="text-center">ETUNIMI</th>
              <th className="text-center">SUKUNIMI</th>
              <th className="text-center">KÄYTTÄJÄNIMI</th>
              <th className="text-center">PUHELIN</th>
              <th className="text-center">SÄHKÖPOSTI</th>
              <th className="text-center">SKYPE</th>
              <th className="text-center">Vanhainkoti</th>
              <th className="text-center">Paikkakunta</th>
              <th className="text-center">OSASTO</th>
              <th className="text-center">KOTIOSOITE</th>
              <th className="text-center">POSTINUMERO</th>
              <th className="text-center">KAUPUNKI</th>
              <th className="text-center">ADMIN</th>
              <th className="text-center">ITSE SENIORI</th>
              <th className="text-center">Updated at</th>
              <th className="text-center">
                {user.readOnly || !user.isAdmin ? "Näkymä" : "Päivitä /Poista"}
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.users
              ?.sort((a: any, b: any) => a?.id - b?.id)
              .map((item: any) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.groupId}</td>
                  <td>{item.role}</td>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.username}</td>
                  <td>{item.mobileNumber}</td>
                  <td>
                    <a className="mailto" href={`mailto:${item.email}`}>
                      {item.email}
                    </a>
                  </td>
                  <td>{item.skypeEmail}</td>
                  <td>{item.nursingHome}</td>
                  <td>{item.nursingHomeAddress}</td>
                  <td>{item.department}</td>
                  <td>{item.homeAddress}</td>
                  <td>{item.homePostalCode}</td>
                  <td>{item.homeCity}</td>
                  <td style={{textAlign: "center"}}>
                    {item.isAdmin ? (
                      <b style={{color: "green"}}>
                        ADMIN
                        <span>{item.readOnly && "(read only)"}</span>
                      </b>
                    ) : (
                        <div>USER</div>
                    )}
                  </td>
                  <td style={{textAlign: "center"}}>
                    {item.isSenior ? (
                      <b style={{color: "green"}}>
                        Seniori
                      </b>
                    ) : (
                        <div>Muu</div>
                    )}
                  </td>
                  <td>{item.updatedAt}</td>
                  <td>
                    <LinkContainer to={`/admin/users/${item.id}`}>
                      <Button className="btn-sm">
                        <i
                          className={
                            user.readOnly || !user.isAdmin
                              ? "fas fa-eye"
                              : "fas fa-edit"
                          }
                          style={{
                            color: "#fff",
                          }}
                        />
                      </Button>
                    </LinkContainer>
                    {!user.readOnly && user.isAdmin && (
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => deleteHandler(item.id)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Users;
