import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  getItSupportListRequest,
  removeItSupportProfileRequest,
} from "../redux/actions";
import { AppState } from "../redux/types";

const ITSupport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  const itSupport: any = useSelector((state: AppState) => state.itSupport);
  const { loading, error, list } = itSupport;

  useEffect(() => {
    if (user && user.isAdmin) {
      dispatch(getItSupportListRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const handleDeleteItSupport = (id: number) => {
    dispatch(removeItSupportProfileRequest(id));
  };

  return (
    <>
      <div className="alert-message">
        {Object.keys(itSupport.message).length !== 0 && (
          <Message
            variant={itSupport.message?.status === 200 ? "info" : "danger"}
          >
            <div className="message-text">{itSupport.message?.message}</div>
          </Message>
        )}
      </div>
      {/* <h1 className="my-3 ml-3 module-title">IT Support</h1>
      <LinkContainer to={`/admin/it_support/add`}>
        <Button>Add New IT Support</Button>
      </LinkContainer> */}

      <div className="headind-button-div">
        <h1 className="my-3 ml-3 module-title">It-tuki</h1> {/* IT Support */}
        {/* IT Support */}
        <LinkContainer to={`/admin/it_support/add`}>
          <Button className="add-button">LISÄÄ UUSI IT-TUKI</Button>
          {/* ADD NEW IT SUPPORT */}
        </LinkContainer>
      </div>

      <div>
        {loading ? (
          <Loader />
        ) : error ? (
          <div className="error-message">
            <Message variant="danger">
              <div className="message-text">{error.message}</div>
            </Message>
          </div>
        ) : list?.length > 0 ? (
          <Table striped bordered hover responsive className="users-table">
            <thead className="user-title">
              <tr className="user">
                <th className="text-center">ID</th>
                <th className="text-center">Etunimi</th>
                <th className="text-center">Sukunimi</th>
                <th className="text-center">Sähköposti tunnus</th>
                <th className="text-center">Puhelinnumero</th>
                <th className="text-center">Päivitä /Poista</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item: any) => (
                <tr key={item.userId}>
                  <td className="text-center">{item.userId}</td>
                  <td className="text-center">{item.firstName}</td>
                  <td className="text-center">{item.lastName}</td>
                  <td className="text-center">{item.email}</td>
                  <td className="text-center">{item.mobileNumber}</td>
                  <td className="text-center">
                    <LinkContainer
                      className="action-items"
                      to={`/admin/it_support/${item.userId}`}
                    >
                      <Button className="btn-sm">
                        <i className="fas fa-edit" style={{ color: "#fff" }} />
                      </Button>
                    </LinkContainer>
                    {
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => handleDeleteItSupport(item.userId)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Message variant="light">IT-tukea ei ole saatavilla!!</Message> // No IT Support Available!!
        )}
      </div>
    </>
  );
};

export default ITSupport;
