import {GET_NURSES_SUCCESS, ADD_NURSE_REQUEST, NurseActions, Nurses, ASSIGNED_SENIOR_SUCCESS, UPDATE_NURSE_SUCCESS, GET_NURSE_PROFILE_SUCCESS, ASSIGNED_SENIOR_LIST_SUCCESS,
    SIGNUP_NURSE_SUCCESS, GET_NURSES_REQUEST, GET_NURSES_FAIL, GET_NURSE_PROFILE_REQUEST, ASSIGNED_SENIOR_LIST_REQUEST, ASSIGNED_SENIOR_LIST_FAILED, GET_NURSE_PROFILE_FAILED, ASSIGNED_SENIOR_REQUEST,
    ASSIGNED_SENIOR_FAILED, UPDATE_NURSE_REQUEST, UPDATE_NURSE_FAILED, ADD_NURSE_SUCCESS, ADD_NURSE_FAILED, SIGNUP_NURSE_REQUSET, SIGNUP_NURSE_FAILED, RESET_NURSE_MESSAGE,
    SET_NURSE_MESSAGE, DELETE_NURSE_REQUEST, DELETE_NURSE_REQUEST_SUCCESS, DELETE_NURSE_REQUEST_FAILED} from '../types'

const initialState: any = {
    list: [],
    loading: false,
    error: null,
    profile: {},
    seniorList: [],
    message: {},
}

const nurses = (state = initialState, action: NurseActions): Nurses => {
    switch (action.type) {
        case DELETE_NURSE_REQUEST:
        case SIGNUP_NURSE_REQUSET:
        case ADD_NURSE_REQUEST:
        case UPDATE_NURSE_REQUEST:
        case ASSIGNED_SENIOR_REQUEST:
        case ASSIGNED_SENIOR_LIST_REQUEST:
        case GET_NURSE_PROFILE_REQUEST:
        case GET_NURSES_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
                profile: {},
                seniorList: [],
            };

        case GET_NURSES_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false,
            }

        case ASSIGNED_SENIOR_FAILED:
        case ASSIGNED_SENIOR_LIST_FAILED:
        case GET_NURSE_PROFILE_FAILED:
        case GET_NURSES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        case ASSIGNED_SENIOR_LIST_SUCCESS:                    
            return{
                ...state,
                seniorList : action?.payload?.seniors,
                loading: false,
            }

        case GET_NURSE_PROFILE_SUCCESS:
            return{
                ...state,
                profile: action.payload,
                loading: false,
            }

        case ASSIGNED_SENIOR_SUCCESS:                    
            return{
                ...state,
                seniorList : action?.payload?.seniors,
                loading: false,
            }

        case SIGNUP_NURSE_SUCCESS:
        case ADD_NURSE_SUCCESS:
        case UPDATE_NURSE_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case SIGNUP_NURSE_FAILED:
        case ADD_NURSE_FAILED:
        case UPDATE_NURSE_FAILED:
            return {
                ...state,
                message: action.payload,
                loading: false,
            }

        case RESET_NURSE_MESSAGE:
            return {
                ...state,
                message: {},
            }

        case SET_NURSE_MESSAGE:
            return {
                ...state,
                message: action.payload,
            }

        case DELETE_NURSE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case DELETE_NURSE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}

export default nurses
