import SubNavBar from "../components/SubNavBar";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/types";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import api from "../utils/api-helper/api";

const Instructions = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [edit, setEdit] = useState(false);
    const [content, setContent] = useState("");
    const [page, setPage] = useState({});
    console.log(user);
    useEffect(() => {
        if (user.id) {
            api.get(`/page/instructions`)
                .then((resp: any) => {
                    console.log(resp);
                    setPage(resp.data.payload);
                    setContent(resp.data.payload?.content);
                })
                .catch((error) => {
                    const body = error.response.data;
                    console.log(body);
                    setPage({});
                    setContent("");
                })
                .finally(() => {});
        } else {
            history.push("/kirjaudu");
        }
        return () => {};
    }, [dispatch, history, user]);

    const savePage = () => {
        api.post(`/page`, {
            ...page,
            route: "instructions",
            content: content,
            public: false,
        })
            .then((resp: any) => {
                console.log(resp);
                setPage(resp.data.payload);
                setContent(resp.data.payload?.content);
                setEdit(false);
            })
            .catch((error) => {
                const body = error.response.data;
                console.log(body);
            })
            .finally(() => {});
    };

    return (
        <>
            <SubNavBar />
            <Container fluid>
                <Row className="justify-content-md-left">
                    <Col xs={12} data-color-mode="light">
                        <h1 className='senior-detail-header'>Ohjeet</h1>
                        {edit ? <MDEditor
                            value={content}
                            // @ts-ignore
                            onChange={setContent}
                        /> : <MDEditor.Markdown source={content}/>}
                    </Col>
                    {user.isAdmin && !user.readOnly && (
                        <Col
                            xs={12}
                            style={{
                                textAlign: "right",
                            }}
                        >
                            {!edit && (
                                <Button className="create-service-button tallenna" type="submit" onClick={() => setEdit(true)}>
                                    Edit
                                </Button>
                            )}

                            {edit && (
                                <Button className="create-service-button tallenna" type="submit" onClick={() => setEdit(false)}>
                                    Cancel
                                </Button>
                            )}

                            {edit && (
                                <Button className="create-service-button tallenna" type="submit" onClick={() => savePage()}>
                                    Save
                                </Button>
                            )}
                        </Col>
                    )}
                </Row>
            </Container>
        </>
    );
};

export default Instructions;
