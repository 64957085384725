import { Redirect, Route, Switch } from "react-router-dom";

import Home from "./screens/home/Home";
import Services from "./screens/Services";
// import ServiceScreen from './screens/ServiceScreen'
import Login from "./screens/Login";
import UserPage from "./screens/UserPage";
import Cart from "./screens/Cart";
import Contact from "./screens/Contact";
import ForCompanies from "./screens/ForCompanies";
import ServiceForm from "./components/ServiceForm";
import Register from "./screens/Register";
import RecoverPassword from "./screens/RecoverPassword";
import UpdatePassword from "./screens/UpdatePassword";
import Orders from "./screens/Orders";
import Users from "./screens/Users";
import Health from "./screens/Health";
import Video from "./screens/Video";
import Shipping from "./screens/Shipping";
import PaymentMethod from "./screens/PaymentMethod";
import PlaceOrder from "./screens/PlaceOrder";
import ContactInfo from "./screens/ContactInfo";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import { clearUserError } from "./redux/actions";
import { useDispatch } from "react-redux";
import ServicesList from "./screens/ServicesList";
import TermsOfUse from "./screens/TermsOfUse";
import AlertsList from "./screens/AlertsList";
import AlertForm from "./components/AlertForm";
import CouponsList from "./screens/CouponsList";
import CouponForm from "./components/CouponForm";
import BlogForm from "./components/BlogForm";
import BlogsList from "./screens/BlogsList";
import Article from "./screens/Article";
import UserEditForm from "./components/UserEditForm";
import AboutUs from "./screens/AboutUs";
import SeniorHomesList from "./screens/SeniorHomesList";
import SeniorHomeForm from "./components/SeniorHomeForm";
import Nurse from "./screens/Nurse";
import NurseSeniors from "./screens/NurseSeniors";
import NurseEditForm from "./components/NurseEditForm";
import NurseForm from "./components/NurseForm";
import RegisterNurseForm from "./components/RegisterNurseForm";
import ITSupport from "./screens/ITSupport";
import ITSupportForm from "./components/ITSupportForm";
import RegisterItSupport from "./components/RegisterItSupport";
import ITSupportEditForm from "./components/ITSupportEditForm";
import Instructions from "./screens/Instructions";
import FAQPage from "./screens/FAQ";
import VideoCallRoom from "./screens/VideoCallRoom";
import Osta from "./screens/osta/Osta";
import Phone from "./screens/phone/Phone";
import Watch from "./screens/watch/Watch";
import ProductsScreen from "./screens/products/Products";
import Thanks from "./screens/thanks/Thanks";

const Routes = () => {
    const dispatch = useDispatch();
    dispatch(clearUserError());

    return (
        <main
            style={{
                minHeight: `calc(100vh - 226px)`,
            }}
        >
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/osta" component={Osta} exact />
                <Route path="/senioritabletti" component={Services} exact />
                <Route path="/senioripuhelin" component={Phone} exact />
                <Route path="/turvaranneke" component={Watch} exact />
                <Route path="/thanks" component={Thanks} exact />
                <Route path="/osta/:cat" component={ProductsScreen} exact />
                <Route path="/copyright" component={PrivacyPolicy} exact />
                <Route path="/palveluehdot" component={TermsOfUse} exact />
                <Route path="/kirjaudu" component={Login} />
                <Route path="/tili/:tab_id" component={UserPage} />
                <Route path="/tili">
                    <Redirect to="/tili/tilaus" />
                </Route>
                <Route path="/ostoskori" component={Cart} />
                <Route path="/toimitus" component={Shipping} />
                <Route path="/maksutapa" component={PaymentMethod} />
                <Route path="/tee_tilaus" component={PlaceOrder} />
                <Route path="/yhteydenotto" component={Contact} />
                <Route path="/contact_info" component={ContactInfo} />
                <Route path="/yrityksille" component={ForCompanies} />
                <Route path="/usein_kysyttya" component={FAQPage} />
                <Route path="/admin/users/:id" component={UserEditForm} />
                <Route path="/admin/users" component={Users} />
                <Route path="/admin/orders" component={Orders} />
                <Route path="/admin/service/:id" component={ServiceForm} />
                <Route path="/admin/services" component={ServicesList} />
                <Route path="/admin/alert/:id" component={AlertForm} />
                <Route path="/admin/alerts" component={AlertsList} />
                <Route path="/admin/coupon/:id" component={CouponForm} />
                <Route path="/admin/coupons" component={CouponsList} />
                <Route path="/admin/blog/:id" component={BlogForm} />
                <Route path="/admin/blogs" component={BlogsList} />
                <Route path="/luo_tili" component={Register} />
                <Route path="/salasana/vaihda" component={RecoverPassword} />
                <Route path="/happysenior_blogi" component={Health} />
                <Route path="/instructions" component={Instructions} />
                <Route path="/blog/:id" component={Article} />
                <Route path="/video" component={Video} />
                <Route path="/password/reset/:userId/:token" component={UpdatePassword} />
                <Route path="/aboutus" component={AboutUs} />
                <Route path="/admin/senior_home/:id" component={SeniorHomeForm} />
                <Route path="/admin/senior_homes" component={SeniorHomesList} />
                <Route path="/admin/nurse" exact component={Nurse} />
                <Route path="/admin/nurses/add" exact component={NurseForm} />
                <Route path="/admin/nurses/:id" exact component={NurseEditForm} />
                <Route path="/admin/nurses/seniors/:id" exact component={NurseSeniors} />
                <Route path="/invite/staff/nurse" component={RegisterNurseForm} />
                <Route path="/admin/it_support" exact component={ITSupport} />
                <Route path="/admin/it_support/add" exact component={ITSupportForm} />
                <Route path="/invite/staff/it_support" component={RegisterItSupport} />
                <Route path="/admin/it_support/:id" exact component={ITSupportEditForm} />
                <Route path="/meet/:token/:id" exact component={VideoCallRoom} />

                <Route path="/services">
                    <Redirect to="/palvelut" />
                </Route>
                <Route path="/subscribe">
                    <Redirect to="/tilaa" />
                </Route>
                <Route path="/shoppingcart">
                    <Redirect to="/ostoskori" />
                </Route>
                <Route path="/shipping">
                    <Redirect to="/toimitus" />
                </Route>
                <Route path="/paymentmethod">
                    <Redirect to="/maksutapa" />
                </Route>
                <Route path="/placeorder">
                    <Redirect to="/tee_tilaus" />
                </Route>
                <Route path="/terms">
                    <Redirect to="/palveluehdot" />
                </Route>
                <Route path="/login">
                    <Redirect to="/kirjaudu" />
                </Route>
                <Route path="/contact">
                    <Redirect to="/yhteydenotto" />
                </Route>
                <Route path="/companies">
                    <Redirect to="/yrityksille" />
                </Route>
                <Route path="/register">
                    <Redirect to="/luo_tili" />
                </Route>
                <Route path="/password/recover">
                    <Redirect to="/salasana/vaihda" />
                </Route>
                <Route path="/health">
                    <Redirect to="/happysenior_blogi" />
                </Route>
            </Switch>
        </main>
    );
};

export default Routes;
