import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { updateUserRequest } from "../redux/actions";
import Message from "./Message";
import Loader from "./Loader";
import { AppState } from "../redux/types";

const FeatureChoicesPhone = ({ onSelect, key }: any) => {
    const member = useSelector((state: AppState) => state.user);
    const { error, loading } = member;
    const [serviceLang, setServiceLang] = useState(member?.tabletLanguage || "suomi");
    const [autoAnswer, setAutoAnswer] = useState(member?.autoAnswer || "false");
    const [option, setOption] = useState(member?.serviceOption || "puhelimen3");
    const relativeDoneEmailId = useSelector((state: AppState) => state.categories.category?.attributes.RelativeAccountDone);

    const dispatch = useDispatch();

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        dispatch(
            updateUserRequest({
                ...member,
                password: "",
                tabletLanguage: serviceLang,
                autoAnswer: autoAnswer,
                serviceOption: option,
                relativeDoneEmailId,
                chosenService: "phone",
            })
        );

        setTimeout(function () {
            onSelect("tilaus");
        }, 3000);
    };

    const auto_answer = (
        <Form.Group className="feature_choices-form">
            <label>Videopuhelun avautuminen seniorin puhelimessa, kun perheenjäsen soittaa:</label>
            <div
                style={{
                    marginLeft: 127,
                }}
            >
                <Form.Check
                    className="radio-button"
                    type="radio"
                    label={`Videopuhelu avautuu automaattisesti ilman kosketusta `}
                    id="true"
                    name="autoAnswer"
                    value={"true"}
                    checked={autoAnswer === "true"}
                    onChange={() => setAutoAnswer("true")}
                />
                <Form.Check
                    className="radio-button"
                    type="radio"
                    label={`Videopuhelu ei avaudu automaattisesti ilman kosketusta`}
                    id="false"
                    name="autoAnswer"
                    value={"false"}
                    checked={autoAnswer === "false"}
                    onChange={() => setAutoAnswer("false")}
                />
            </div>
        </Form.Group>
    );

    const lang = (
        <Form.Group className="feature_choices-form">
            <label>Puhelimen kieli: </label>
            <Form.Check
                style={{
                    display: "inline-block",
                    margin: 8,
                }}
                className="radio-button"
                type="radio"
                label="Suomi"
                id="suomi"
                name="serviceLang"
                value={"suomi"}
                checked={serviceLang === "suomi"}
                onChange={() => setServiceLang("suomi")}
            />
            <Form.Check
                style={{
                    display: "inline-block",
                    margin: 8,
                }}
                className="radio-button"
                type="radio"
                label="Ruotsi"
                id="ruotsi"
                name="serviceLang"
                value={"ruotsi"}
                checked={serviceLang === "ruotsi"}
                onChange={() => setServiceLang("ruotsi")}
            />
        </Form.Group>
    );

    const phone_options = (
        <Form.Group className="feature_choices-form">
            <label>Sovellusvalikoima:</label>
            <div
                style={{
                    marginLeft: 127,
                }}
            >
                <Form.Check className="radio-button" type="radio" label="Videopuhelu" id="puhelimen1" name="option" value={"puhelimen1"} checked={option === "puhelimen1"} onChange={() => setOption("puhelimen1")} />
                <Form.Check
                    className="radio-button"
                    type="radio"
                    label="Videopuhelu, puhelut, yhteystiedot, tekstiviestit, kamera, galleria"
                    id="puhelimen2"
                    name="option"
                    value={"puhelimen2"}
                    checked={option === "puhelimen2"}
                    onChange={() => setOption("puhelimen2")}
                />
                <Form.Check
                    className="radio-button"
                    type="radio"
                    label="Videopuhelu, puhelut, yhteystiedot, tekstiviestit, kamera, galleria, Google Chrome, Iltasanomat, Play -kauppa (voi lisätä omia sovelluksia)"
                    id="puhelimen3"
                    name="option"
                    value={"puhelimen3"}
                    checked={option === "puhelimen3"}
                    onChange={() => setOption("puhelimen3")}
                />
            </div>
        </Form.Group>
    );

    return (
        <Container fluid>
            <Row className="justify-content-md-left">
                <Col xs={12}>
                    {error && <Message variant="danger">{error.message}</Message>}
                    {loading && <Loader />}
                    <h1 className="member-form-header">4 Räätälöi puhelimen ominaisuudet halutessasi</h1>
                    {member?.id && (
                        <div className="feature_choices">
                            <div className="text-for-companies-second">
                                Suosittelemme tämän kohdan täyttämistä, mutta se ei ole välttämätöntä. Oletusasetuksina on suomi, videopuhelun avautuminen yhdellä klikillä painamalla vastaa/hylkää ja suosittu sovellusvalikoima. Jatka
                                valitsemalla haluamasi ominaisuudet tai siirry eteenpäin.
                            </div>
                            <Form onSubmit={submitHandler}>
                                {lang}
                                {auto_answer}
                                {phone_options}
                            </Form>

                            <Button type="button" className="tallenna" onClick={submitHandler}>
                                Valmis
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};
export default FeatureChoicesPhone;
