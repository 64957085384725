import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppState } from "../redux/types";

const StepsHeader = ({ step1, step2, step3, step4, step5 }: any) => {
    const category = useSelector((state: AppState) => state.categories.category?.attributes.slug);
    return (
        <>
            <Row>
                <Col sm={12} lg={12} md={12}>
                    <section className="justify-content-center my-4 steps-section">
                        <ul className="circles-row">
                            {step1 ? (
                                <li className="step active">
                                    <i className="icon-in-circle active fas fa-shopping-cart" />
                                    Ostoskori
                                </li>
                            ) : (
                                <li className="step">
                                    <i className="icon-in-circle fas fa-shopping-cart" />
                                    Ostoskori
                                </li>
                            )}
                            {category !== "alarm" && (
                                <>
                                    <li className="step-line" />
                                    {step2 ? (
                                        <li className="step active">
                                            <i className="icon-in-circle active fas fa-user-circle" />
                                            Tilaa ja luo tili
                                        </li>
                                    ) : (
                                        <li className="step">
                                            <i className="icon-in-circle fas fa-user-circle" />
                                            Tilaa ja luo tili
                                        </li>
                                    )}
                                </>
                            )}
                            <li className="step-line" />
                            {step3 ? (
                                <li className="step active">
                                    <i className="icon-in-circle active fas fa-truck" />
                                    Yhteystiedot, toimitus
                                </li>
                            ) : (
                                <li className="step">
                                    <i className="icon-in-circle fas fa-truck" />
                                    Yhteystiedot, toimitus
                                </li>
                            )}
                            <li className="step-line" />
                            {step4 ? (
                                <li className="step active">
                                    <i className="icon-in-circle far fa-credit-card active" />
                                    Maksu
                                </li>
                            ) : (
                                <li className="step">
                                    <i className="icon-in-circle far fa-credit-card" />
                                    Maksu
                                </li>
                            )}
                            <li className="step-line" />
                            {step5 ? (
                                <li className="step active">
                                    <i className="icon-in-circle fas fa-address-card active" />
                                    {category !== "alarm" ? "Luo perhetili" : "Yhteenveto"}
                                </li>
                            ) : (
                                <li className="step">
                                    <i className="icon-in-circle fas fa-address-card" />
                                    {category !== "alarm" ? "Luo perhetili" : "Yhteenveto"}
                                </li>
                            )}
                        </ul>
                    </section>
                    {/* <section className='justify-content-center my-4 step-texts-section'>
        <ul className='steps-row'>
          {step1 ? (
            <li className='steps-name active service-step-text'>Palvelu</li>
          ) : (
            <li className='steps-name service-step-text'>Palvelu</li>
          )}
          {step2 ? (
            <li className='steps-name active register-step-text'>Luo tili ja kirjaudu</li>
          ) : (
            <li className='steps-name account-step-text'>Luo tili ja kirjaudu</li>
          )}
          {step3 ? (
            <li className='steps-name active register-step-text'>
              Toimitusosoite
            </li>
          ) : (
            <li className='steps-name address-step-text'>Toimitusosoite</li>
          )}
          {step4 ? (
            <li id='payment-step-text' className='steps-name active'>Maksutapa ja yhteenveto <br></br>Maksa</li>
          ) : (
            <li className='steps-name payment-step-text'>Maksutapa ja yhteenveto<br></br> Maksa</li>
          )}
          {step5 ? (
            <li className='steps-name active info-step-text'>
              Omat henkilötiedot <br></br> Seniorin ja
              läheisten henkilötiedot
            </li>
          ) : (
            <li className='steps-name info-step-text'>
              Omat henkilötietot <br></br> Seniorin ja
              läheisten henkilötiedot
            </li>
          )}
        </ul>
      </section> */}
                </Col>
            </Row>
        </>
    );
};

export default StepsHeader;
