import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PreJoinRoom from '../components/videoMeet/PreJoinRoom';
import ActiveRoom from '../components/videoMeet/ActiveRoom';
import { AppState } from '../redux/types';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setLkToken, setPreJoinToken } from '../redux/actions/videoMeet';
import { useSocket } from '../context/socketProvider';

function VideoCallRoom() {
  const params: any = useParams();
  const dispatch = useDispatch();
  const socket: any = useSocket();
  const history = useHistory();
  const { loading, participants } = useSelector(
    (state: AppState) => state.videoMeet
  );

  // const navigator: any = window.navigator;

  // const checkUrl = () => {
  //   if (navigator) {
  //     if (
  //       /iPhone|iPad|ios/i.test(navigator.userAgent) ||
  //       /iPhone|iPad|ios/i.test(navigator.platform)
  //     ) {
  //       const currentDomain = sessionStorage.getItem('web-domain');
  //       if (
  //         currentDomain === `${process.env.REACT_APP_VIDEO_URL}` ||
  //         /iPhone/i.test(navigator.platform)
  //       ) {
  //         // window.location.href = `${process.env.REACT_APP_VIDEO_URL}${history.location.pathname}`;
  //         window.history.pushState(
  //           null,
  //           '',
  //           `${process.env.REACT_APP_VIDEO_URL}${history.location.pathname}`
  //         );
  //       } else {
  //         window.location.href = `${process.env.REACT_APP_IOS_VIDEO_URL}${history.location.pathname}`;
  //       }
  //       setTimeout(() => {
  //         window.history.pushState(
  //           null,
  //           '',
  //           `${process.env.REACT_APP_VIDEO_URL}${history.location.pathname}`
  //         );
  //       }, 500);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   checkUrl();
  // }, []);

  useEffect(() => {
    if (params.token && participants.length === 0) {
      dispatch(
        setPreJoinToken(
          sessionStorage.getItem('preJoinToken')
            ? sessionStorage.getItem('preJoinToken')
            : params.token
        )
      );
    }

    if (params.token && participants.length > 0) {
      const user: any = participants.find(
        (participant: any) => participant?.roomName === params.token
      );
      if (user === 'undefined') {
        dispatch(
          setPreJoinToken(
            sessionStorage.getItem('preJoinToken')
              ? sessionStorage.getItem('preJoinToken')
              : params.token
          )
        );
      }
    }
  });

  useEffect(() => {
    const _token = sessionStorage.getItem('preJoinToken')
      ? sessionStorage.getItem('preJoinToken')
      : params.token;
    const user: any = participants.find(
      (participant: any) => participant?.preJoinToken === _token
    );
    if (
      user &&
      Object.keys(user).includes('userChoice') &&
      !Object.keys(user).includes('roomName')
    ) {
      socket.emit('JOIN_CALL_FROM_WEB', {
        user_name: user.userName,
        room_id: user.roomId,
      });
    }
  }, [participants, params.token, socket]);

  const handleInitiateCall = useCallback(
    (data: any) => {
      const user = {
        lkAccessToken: data.token,
        roomId: data.room_id,
        roomName: data.room_name,
        total: data.room_length + 1,
      };

      if (data.room_length < 6) {
        dispatch(setLkToken(user));
        history.push(`/meet/${data.room_name}/${params.id}`);
      } else {
        toast.error('Room allows maximum 6 users', {
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    },
    [dispatch, history, params.id]
  );

  useEffect(() => {
    socket.on('INITIATE_CALL_FROM_WEB', handleInitiateCall);
    return () => {
      socket.off('INITIATE_CALL_FROM_WEB', handleInitiateCall);
    };
  }, [socket, handleInitiateCall]);

  const isActive = () => {
    const participant: any = participants.find(
      (participant: any) => participant?.roomName === params.token
    );

    if (participant) {
      return Object.keys(participant).length > 0;
    } else {
      return false;
    }
  };

  const getParticipant = () =>
    participants.find(
      (participant: any) => participant.roomName === params.token
    );
console.log(params,"video call room params")
console.log(participants,"video call room participant")
  return (
    <div>
      {!loading && participants.length > 0 && isActive() ? (
        <ActiveRoom participant={getParticipant()} />
      ) : (
        <PreJoinRoom
          participants={participants}
          preJoinToken={
            sessionStorage.getItem('preJoinToken')
              ? sessionStorage.getItem('preJoinToken')
              : params.token
          }
          isActive={isActive()}
        />
      )}
    </div>
  );
}

export default VideoCallRoom;
