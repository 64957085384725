import { GET_CATEGORIES_FAIL, GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS, CategoryType } from "../types";

export const getCategoriesSuccess = (category: CategoryType) => {
    return {
        type: GET_CATEGORIES_SUCCESS,
        payload: category,
    };
};
export const getCategoriesFail = (error: any) => {
    return {
        type: GET_CATEGORIES_FAIL,
        payload: {
            error,
        },
    };
};
export const getCategoriesRequest = (category?: string) => {
    return {
        type: GET_CATEGORIES_REQUEST,
        payload: category,
    };
};
