import {
    ADD_TO_CART,
    CartActions,
    CartState,
    PickupPoint,
    REMOVE_FROM_CART,
    RESET_CART,
    SAVE_PAYMENT_METHOD,
    SAVE_SHIPPING_ADDRESS,
    SAVE_STRIPE_PAYMENT_METHOD,
    SET_COUPON
} from '../types'

export default function service(
    state: CartState = {
        usePickupPoint: false,
        pickupPoint: {
            address1: null,
            address2: null,
            city: null,
            contact: null,
            countryCode: null,
            email: null,
            fax: null,
            id: null,
            name: null,
            openingHours: null,
            phone: null,
            serviceCode: null,
            serviceType: null,
            sms: null,
            state: null,
            zipcode: null,
        },
        inCart: [],
        shippingMethod: '',
        name: '',
        address: '',
        postalCode: '',
        city: '',
        country: '',
        email: '',
        mobile: '',
        paymentMethod: '',
        stripePaymentMethod: '',
        price: 0,
        shippingPrice: 0,
        taxPrice: 0,
        totalPrice: 0,
        coupon: null,
        couponData: null,
        nursingHome: null,
    },
    action: CartActions
): {
    stripePaymentMethod: { data: any };
    pickupPoint: PickupPoint;
    country: string;
    usePickupPoint: boolean;
    address: string;
    city: string;
    totalPrice: number;
    shippingMethod: string;
    postalCode: string;
    mobile: string;
    shippingPrice: number;
    price: number;
    name: string;
    paymentMethod: any;
    taxPrice: number;
    email: string;
    inCart: any[];
    coupon: any;
    couponData: any;
} {
    switch (action.type) {
        case ADD_TO_CART: {
            const {service} = action.payload
            if (state.inCart.find((s) => s.id === service.id)) {
                return state
            }

            return {...state, inCart: [...state.inCart, service]}
        }

        case REMOVE_FROM_CART: {
            return {
                ...state,
                inCart: state.inCart.filter((s) => s.id !== action.payload.id),
            }
        }

        case RESET_CART: {
            return {
                usePickupPoint: true,
                pickupPoint: {
                    address1: null,
                    address2: null,
                    city: null,
                    contact: null,
                    countryCode: null,
                    email: null,
                    fax: null,
                    id: null,
                    name: null,
                    openingHours: null,
                    phone: null,
                    serviceCode: null,
                    serviceType: null,
                    sms: null,
                    state: null,
                    zipcode: null,
                },
                inCart: [],
                shippingMethod: '',
                name: '',
                price: 0,
                shippingPrice: 0,
                address: '',
                postalCode: '',
                city: '',
                country: '',
                email: '',
                mobile: '',
                paymentMethod: '',
                stripePaymentMethod: {data: ''},
                taxPrice: 0,
                totalPrice: 0,
                coupon: null,
                couponData: null,
            }
        }

        case SAVE_SHIPPING_ADDRESS: {
            return {
                ...state,
                ...action.payload
            }
        }

        case SAVE_PAYMENT_METHOD: {
            return {
                ...state,
                paymentMethod: action.payload,
            }
        }

        case SAVE_STRIPE_PAYMENT_METHOD: {
            return {
                ...state,
                stripePaymentMethod: action.payload,
            }
        }

        case SET_COUPON: {
            console.log(action.payload)
            return {
                ...state,
                coupon: action.payload?.code,
                couponData: action.payload,
            }
        }

        default:
            return state
    }
}