import {Button, Container} from 'react-bootstrap'
import React from "react";

const Intro = ({onSelect}: any) => {
    const handleClick = () => {
        onSelect('asiakas')
    }

    return (
        <Container className='introduction'>
            <h1 className='introduction-header'>Täydennä asiakastilisi yhteystiedot</h1>
            <h4 className='welcome-greeting'>
                Kiitos tilauksestasi ja tervetuloa Digihappy-asiakkaaksi!
            </h4>
            <p className='instructions'>
                Täydentämällä omat, seniorin ja läheisten yhteystiedot saat palvelun käyttöön.
            </p>
            <p style={{textAlign: 'right'}}>
                <Button
                    onClick={handleClick}
                    className='tallenna'
                >
                    Seuraava
                </Button>
            </p>
            <p>
                Huom! Lähetämme sinulle 2 sähköpostia tilaukseen ja asiakastiliin liittyen. Jos käytät Gmail-osoitetta,
                tarkastathan myös "Tarjoukset" ("Promotions") kansion.
            </p>
        </Container>
    )
}

export default Intro
