import React, { Component } from "react";
import api from "../utils/api-helper/api";
import { Link } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";

import FormContainer from "../components/FormContainer";
import Message from "../components/Message";

class UpdatePassword extends Component {
    state = {
        userId: undefined,
        token: undefined,
        password: "",
        confirmPassword: "",
        submitted: false,
        message: "",
        showPass: false,
        showPass2: false,
    };

    componentDidMount() {
        //@ts-ignore
        const { userId, token } = this.props?.match.params;
        this.setState({
            userId: userId,
            token: token,
        });
    }

    handleChange = (key: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const obj = this;
        this.setState({ [key]: e.target.value }, function () {
            let msg = "";
            if (obj.state.password.length > 0 && obj.state.password.length < 11) {
                msg += "Salasana on liian lyhyt";
            } else if (obj.state.password.length > 0 && obj.state.confirmPassword.length > 0 && obj.state.password !== obj.state.confirmPassword) {
                msg += "Salasanat eivät täsmää\n";
            }
            obj.setState({
                message: msg,
            });
        });
    };

    updatePassword = (e: React.FormEvent) => {
        e.preventDefault();
        //@ts-ignore
        const { password, userId, token } = this.state;
        api.post(`/email/new-password/${userId}/${token}`, {
            password,
        })
            .then((res) => console.log("RESPONSE FROM SERVER TO CLIENT:", res))
            .catch((err) => console.log("SERVER ERROR TO CLIENT:", err));
        this.setState({ submitted: !this.state.submitted });
    };

    showPassHandler = () => {
        this.setState({
            showPass: !this.state.showPass,
        });
    };
    showPassHandler2 = () => {
        this.setState({
            showPass2: !this.state.showPass2,
        });
    };

    render() {
        const { submitted, showPass, showPass2 } = this.state;
        return (
            <FormContainer>
                <h3 style={{ paddingBottom: "1.25rem", paddingTop: "2rem" }}>Vaihda salasana</h3>
                {this.state.message && (
                    <Message className="message" variant="danger">
                        {this.state.message}
                    </Message>
                )}
                {submitted ? (
                    <div className="reset-password-form">
                        <p>Salasanasi on vaihdettu.</p>
                        <Link to="/kirjaudu" className="tallenna">
                            Kirjaudu sisään
                        </Link>
                    </div>
                ) : (
                    <div className="reset-password-form">
                        <Form onSubmit={this.updatePassword} style={{ paddingBottom: "1.5rem" }}>
                            <Form.Label htmlFor="password" className="form-label">
                                Uusi salasana
                            </Form.Label>
                            <Form.Group>
                                <InputGroup>
                                    <Form.Control required onChange={this.handleChange("password")} value={this.state.password} placeholder="Uusi salasana" type={showPass ? "text" : "password"} />
                                    <Button variant="outline-secondary shadow-none show-password-btn" onClick={this.showPassHandler}>
                                        <i className={showPass ? "fas fa-eye-slash" : "fas fa-eye"}></i>
                                    </Button>
                                </InputGroup>
                                <div className="show-password">Näet salasanasi klikkaamalla silmäkuvaketta</div>
                            </Form.Group>
                            <Form.Label htmlFor="password" className="form-label">
                                Toista salasana
                            </Form.Label>
                            <Form.Group>
                                <InputGroup>
                                    <Form.Control required onChange={this.handleChange("confirmPassword")} value={this.state.confirmPassword} placeholder="Vahvista salasana" type={showPass2 ? "text" : "password"} />
                                    <Button variant="outline-secondary shadow-none show-password-btn" onClick={this.showPassHandler2}>
                                        <i className={showPass2 ? "fas fa-eye-slash" : "fas fa-eye"}></i>
                                    </Button>
                                </InputGroup>
                                <div className="show-password">Näet salasanasi klikkaamalla silmäkuvaketta</div>
                            </Form.Group>
                            <Button type="submit" className="btn-primary password-reset-btn tallenna" disabled={this.state.password.length < 11 || this.state.password !== this.state.confirmPassword}>
                                Tallenna
                            </Button>
                        </Form>
                    </div>
                )}
            </FormContainer>
        );
    }
}

export default UpdatePassword;
