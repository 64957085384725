import React, { useCallback, useEffect, useState } from 'react';
import {
  AudioTrack,
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  TrackRefContext,
  useTracks,
} from '@livekit/components-react';
import { Track, VideoPresets } from 'livekit-client';
import { useHistory } from 'react-router-dom';
import { useSocket } from '../../context/socketProvider';
import { removeRoomName } from '../../redux/actions/videoMeet';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

function ActiveRoom(props: any) {
  const dispatch = useDispatch();
  const socket: any = useSocket();
  const history = useHistory();
  const { lkAccessToken, userChoice, total, preJoinToken } = props.participant;
  const [isConnect, setIsConnect] = useState<boolean>(false);

  useEffect(() => {
    setIsConnect(true);
    sessionStorage.setItem('preJoinToken', preJoinToken);
  }, [preJoinToken]);

  const handleCallEnd = useCallback(() => {
    const elements: any = document.getElementsByClassName(
      'lk-disconnect-button'
    );
    if (elements.length > 0) {
      sessionStorage.removeItem('preJoinToken');
      elements[0].click();
    }
  }, []);

  console.log(lkAccessToken,"lkAccessToken", userChoice,"userChoice", total,"total", preJoinToken,"preJoinToken","active room token access")
  useEffect(() => {
    socket.on('CALL_ENDED', handleCallEnd);
    return () => {
      socket.off('CALL_ENDED', handleCallEnd);
    };
  }, [socket, handleCallEnd]);

  const handleLimitExceedWarning = useCallback((data: any) => {
    return toast.warning('The video call ends in 5 minutes', {
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }, []);

  const handleLimitExceed = useCallback((data: any) => {
    return toast.info('The video call ended', {
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }, []);

  useEffect(() => {
    socket.on('MAX_CALL_LIMIT_EXCEEDED_WARNING', handleLimitExceedWarning);

    return () => {
      socket.off('MAX_CALL_LIMIT_EXCEEDED_WARNING', handleLimitExceedWarning);
    };
  }, [socket, handleLimitExceedWarning]);

  useEffect(() => {
    socket.on('MAX_CALL_LIMIT_EXCEEDED', handleLimitExceed);

    return () => {
      socket.off('MAX_CALL_LIMIT_EXCEEDED', handleLimitExceed);
    };
  }, [socket, handleLimitExceed]);

  const handleDisconnect = () => {
    sessionStorage.removeItem('preJoinToken');
    dispatch(removeRoomName(props.participant));
    window.location.hostname = `${process.env.REACT_APP_VIDEO_URL}`;
    history.goBack();
  };
  return (
    <div>
      {Object.keys(props.participant).length > 0 && (
        <LiveKitRoom
          token={lkAccessToken}
          serverUrl={process.env.REACT_APP_LK_SERVER_URL}
          connect={isConnect}
          video={userChoice.videoEnabled}
          audio={userChoice.audioEnabled}
          style={{ height: '100vh' }}
          data-lk-theme='default'
          onDisconnected={handleDisconnect}
          connectOptions={{
            maxRetries: 5,
            peerConnectionTimeout: 30000,
            websocketTimeout: 30000,
          }}
          options={{
            adaptiveStream: true,
            publishDefaults: { simulcast: false },
            videoCaptureDefaults: { resolution: VideoPresets.h720.resolution },
            dynacast: false,
          }}
        >
          <VideoConference total={total} />
          <ControlBar
            controls={{
              screenShare: false,
              chat: false,
            }}
          />
        </LiveKitRoom>
      )}
    </div>
  );
}

const VideoConference = ({ total }: any) => {
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
      { source: Track.Source.Microphone, withPlaceholder: true },
    ],
    { onlySubscribed: false }
  );
  const filteredTracks = tracks.filter(
    (track) => track.source !== 'microphone'
  );
  const filterAudio = tracks.filter((track) => track.source === 'microphone');
  return (
    <>
      <GridLayout
        className={`${total > 4 ? 'crowded-room' : ''}`}
        tracks={filteredTracks}
        style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}
        draggable={false}
      >
        <TrackRefContext.Consumer>
          {(track) => {
            if (track?.source !== 'microphone') {
              return <ParticipantTile {...track as any} />;
            }
          }}
        </TrackRefContext.Consumer>
      </GridLayout>
      {filterAudio.map((track) => {
        return <AudioTrack {...track as any} />;
      })}
    </>
  );
};

export default ActiveRoom;
