import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Col, Form, Row } from 'react-bootstrap'

import { getItSupportProfileRequest, updateItSupportProfileRequest } from '../redux/actions'
import Message from './Message'
import Loader from './Loader'
import FormContainer from './FormContainer'
import { AppState } from '../redux/types'

const ITSupportEditForm = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector((state: AppState) => state.user)
    const [formData, setFormData] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
    })
    const [isDisabled, setIsDisabled] = useState(true);
    const itSupport: any = useSelector((state: AppState) => state.itSupport)

    useEffect(() => {
        dispatch(getItSupportProfileRequest(id));
    }, [dispatch, id])

    useEffect(() => {
        if (user && user.isAdmin) {
            setFormData((prevValue: any) => {
                return {
                    ...prevValue,
                    id: itSupport.itSupportProfile?.id || '',
                    firstName: itSupport.itSupportProfile?.firstName || '',
                    lastName: itSupport.itSupportProfile?.lastName || '',
                    email: itSupport.itSupportProfile?.email || '',
                    mobileNumber: itSupport.itSupportProfile?.mobileNumber || '',
                }
            })
        } else {
            history.push('/kirjaudu')
        }
    }, [dispatch, history, user, itSupport]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target

        setIsDisabled(false);
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            }
        })
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()

        let data = {
            id: formData.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            mobileNumber: formData.mobileNumber,
        }
        dispatch(updateItSupportProfileRequest(data))
        history.push('/admin/it_support');
    }

    return (
        <FormContainer>
            <h1 className='user-form-header'>Edit IT Support Profile</h1>

            {
                itSupport.loading ? (
                    <Loader />
                ) : itSupport.error ? (
                    <Message variant='danger'>{itSupport.error.message}</Message>
                ) : (
                    <Form onSubmit={submitHandler} className='user-form'>
                        <Form.Group controlId='firstname-nurse'>
                            <Form.Label>*First Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='First Name'
                                name='firstName'
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId='lastname-nurse'>
                            <Form.Label>*Last Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Last Name'
                                name='lastName'
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId='email-nurse'>
                            <Form.Label>*Email</Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='Email'
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId='mobileNumber-nurse'>
                            <Form.Label>*Phone Number</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder='0'
                                name='mobileNumber'
                                value={formData.mobileNumber}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Row className="justify-content-md-center" style={{ minHeight: 100 }}>
                            <Col xs={6}>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    style={{float: 'right'}}
                                    type='submit'
                                    className='tallenna user-form-button'
                                    disabled={isDisabled}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </FormContainer>
    )
}
export default ITSupportEditForm