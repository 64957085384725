import {Container} from 'react-bootstrap';

import Links from './Links';

import CopyrightPPLink from './CopyrightPPLink';

const Footer = ({className}: any) => {
    return (
        <footer className={`footer ${className && className}`}>
            <Container>
                <div className='page-links text-center'>
                    <Links/>
                    <hr/>
                    <CopyrightPPLink/>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
