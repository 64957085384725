import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

interface ISeniorHome {
  id: Number;
  createdAt: Date;
  updatedDate: Date;
  reference: String;
  name: String;
  show: Boolean;
}

interface IProps {
  onSendInvite: (data: any) => void;
  seniorHomes?: Array<ISeniorHome>;
}

const UserInviteForm = (props: IProps) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    seniorHomeIds: "",
  });
  const [error, setError] = useState<any>({});

  const checkValidation = () => {
    const { firstName, lastName, email } = formData;
    const formError: any = {};

    if (firstName === "") formError.firstName = "This is required field!";
    if (lastName === "") formError.lastName = "This is required field!";
    if (email === "") formError.email = "This is required field!";
    return formError;
  };

  const resetError = (name: string) => {
    delete error[name];
    setError(error);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    resetError(name);
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formError = checkValidation();
    if (Object.keys(formError).length > 0) {
      setError(formError);
    } else {
      const _formData =
        formData.seniorHomeIds === ""
          ? {
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
            }
          : { ...formData, seniorHomeIds: Number(formData.seniorHomeIds) };
      props.onSendInvite(_formData);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        seniorHomeIds: "",
      });
    }
  };

  return (
    <Form className="user-invite-form" onSubmit={handleSubmit}>
      <Form.Group controlId="firstname-invite">
        <Form.Label>*Etunimi</Form.Label> {/* First Name */}
        <Form.Control
          type="text"
          placeholder="Etunimi"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          isInvalid={error.firstName}
        />
        <Form.Control.Feedback type="invalid">
          {error.firstName}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="lastname-invite">
        <Form.Label>*Sukunimi</Form.Label> {/* Last Name */}
        <Form.Control
          type="text"
          placeholder="Sukunimi"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          isInvalid={error.lastName}
        />
        <Form.Control.Feedback type="invalid">
          {error.lastName}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="email-invite">
        <Form.Label>*Sähköposti</Form.Label> {/* Email */}
        <Form.Control
          type="email"
          placeholder="Sähköposti"
          name="email"
          value={formData.email}
          onChange={handleChange}
          isInvalid={error.email}
        />
        <Form.Control.Feedback type="invalid">
          {error.email}
        </Form.Control.Feedback>
      </Form.Group>

      {Array.isArray(props?.seniorHomes) && (
        <Form.Group controlId="seniorHome">
          <Form.Label>Hoivakoti</Form.Label>
          <Form.Control
            as="select"
            name="seniorHomeIds"
            onChange={handleChange}
            value={String(formData.seniorHomeIds)}
          >
            <option value="">Valitse hoivakoti</option>
            {props?.seniorHomes.map((seniorHome) => {
              return (
                <option
                  value={String(seniorHome.id)}
                  key={String(seniorHome.id)}
                >
                  {seniorHome.name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      )}

      <Button
        style={{ float: "right" }}
        className="tallenna user-form-button"
        type="submit"
        disabled={
          formData.firstName !== "" &&
          formData.lastName !== "" &&
          formData.email !== ""
            ? false
            : true
        }
      >
        Lähetä kutsu {/* Send an Invitation */}
      </Button>
    </Form>
  );
};

export default UserInviteForm;
