import {useSelector} from 'react-redux'
import {Col, ListGroup, Row} from 'react-bootstrap'

import Message from './Message'

import {AppState} from '../redux/types'

const OrderInfo = () => {
    const user = useSelector((state: AppState) => state.user)
    const {list} = useSelector((state: AppState) => state.seniorHomes)
    const {firstName, lastName, orders} = user

    // console.log(orders)


    return (
        <>
            {/* <h5 className='order-header'>Tilaustiedot</h5> */}
            {/* TODO: fix */}
            {!orders ? (
                <Message>Sinulla ei ole voimassaolevaa tilausta</Message>
            ) : (
                <ListGroup variant='flush'
                           style={{
                               fontFamily: "'Poppins', sans-serif",
                               color: '#35413e',
                               padding: '0 15px',
                           }}
                >
                    {orders.sort((a, b) => b.id - a.id).map((order: any, index) => (
                        <div key={`${order.id}_${index}`}
                             style={{
                                 // border: '2px solid rgba(0,0,0,.125)'
                             }}
                        >
                            <ListGroup.Item style={{border: 'none'}}>
                                <h2 className='order-headers'>Toimitusosoite</h2>
                                <Row key={`order_number_${order.id}`}>
                                    <Col xs={8} sm={9}>
                                        <p className=''>Tilausnumero</p>
                                    </Col>
                                    <Col xs={4} sm={3}>
                                        <p style={{textAlign: 'right'}}>#{order.id}</p>
                                    </Col>
                                </Row>
                                <p className='customer-name'>
                                    {firstName} {lastName}
                                </p>
                                {order.shippingMethod === 'direct' && 'Toimitus seniorille kotisairaanhoitoon, palveluasuntoon tai yhteistyöhoivakotiin'}
                                {order.shippingMethod === 'posti' && 'Toimitus asiakkaalle Postin noutopisteeseen'}
                                {order.shippingMethod === 'posti' &&
                                    <p className='order-texts'>
                                        Osoite: {order.address},{' '}
                                        {order.postalCode},{' '}
                                        {order.city}
                                    </p>
                                }
                                {order.shippingMethod === 'posti' && order.usePickupPoint &&
                                    <div>
                                        {`${order.pickupPointData?.name} (${order.pickupPointData?.address1}, ${order.pickupPointData?.city})`}
                                    </div>
                                }
                                {order.shippingMethod === 'direct' &&
                                    <p className='order-texts'>
                                        Toimituskohde: {list.find(el => el.reference === order.nursingHome)?.name}
                                    </p>
                                }
                                {/*{order.isDelivered ? (*/}
                                {/*  <Message variant='success'>*/}
                                {/*    Toimitettu {order.deliveredAt}*/}
                                {/*  </Message>*/}
                                {/*) : (*/}
                                {/*  <Message variant='danger'>Toimittamatta</Message>*/}
                                {/*)}*/}
                            </ListGroup.Item>

                            <ListGroup.Item style={{border: 'none'}}>
                                <h2 className='order-headers'>Maksu</h2>
                                <div className='order-texts'>
                                    Maksutapa: {order.paymentMethod === 'card' && 'Kortti'}
                                    {order.paymentMethod === 'email_billing' && 'Sähköpostilasku'}
                                    {order.paymentMethod === 'contract_billing' && 'Laskutussopimus kotisairaanhoidon, hoivakodin tai palveluasunnon kautta'}
                                </div>
                                {/*{order.isPaid ? (*/}
                                {/*  <Message variant='success'>Maksettu {order.paidAt}</Message>*/}
                                {/*) : (*/}
                                {/*  <Message variant='danger'>Maksamatta</Message>*/}
                                {/*)}*/}
                            </ListGroup.Item>

                            <ListGroup.Item style={{border: 'none'}}>
                                <h2 className='placeorder-headers'>Palvelu</h2>
                                {order.services.map((item: any, index: any) => (
                                    <Row key={index}>
                                        <Col xs={8} sm={9}>
                                            <p className=''>
                                                {item.name}
                                                {order.coupon && <div className='selected-product'>
                                                    <small><em>Maksuton kokeilujakso: {order.coupon}</em></small>
                                                </div>}
                                            </p>

                                        </Col>
                                        <Col xs={4} sm={3}>
                                            <p className='selected-product-price'>{item.price} €</p>
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Col xs={8} sm={8} className=''>Toimitusmaksu</Col>
                                    <Col xs={4} sm={4} className='selected-product-price'>{order.shippingPrice} €</Col>
                                </Row>
                                <Row>
                                    <Col xs={8} sm={9}>
                                        <p className='selected-product'>Loppusumma</p>
                                    </Col>
                                    <Col xs={4} sm={3}>
                                        <p className='selected-product selected-product-price'>{order.totalPrice} €</p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </div>
                    ))}
                </ListGroup>
            )}
        </>
    )
}

export default OrderInfo
