import SubNavBar from '../components/SubNavBar'
import {Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../redux/types";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {getBlogsRequest} from "../redux/actions";
import {Helmet} from "react-helmet";
import ArticleFeatured from "../components/ArticleFeatured";
import ArticleItem from "../components/ArticleItem";


const Health = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        dispatch(getBlogsRequest())
        return () => {
        }
    }, [dispatch, history])

    const resources = useSelector((state: AppState) => state.blogs)
    const {list} = resources
    console.log(list)
    const featured_article = list.find(el => el.featured) || list[0] || {
        id: null,
        title: '',
        topics: [{name: ''}],
        cover: '',
        createdAt: '',
        content_preview: '',
    };
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Blogi seniorin terveydestä ja hyvinvoinnista. Ja vinkkejä iloiseen tabletin käyttöön. Lääketieteen, hyvinvoinnin ja digin ammattilaiset kirjoittajina."
                />
            </Helmet>
            <SubNavBar/>
            <div className="blog-wrapper">
            </div>
            {featured_article?.id && <ArticleFeatured
                featured_article={featured_article}
            />}
            {featured_article?.id &&
                <div className="blog-wrapper">
                </div>
            }
            <div className="blog-wrapper">
                <Row className="blog-items">
                    {list?.filter(el => el.id !== featured_article.id)?.map(item => {
                        return <Col
                            md={4}
                            style={{
                                marginBottom: 48
                            }}
                            key={`col_article_${item?.id}`}
                        >
                            <ArticleItem
                                item={item}
                            />
                        </Col>
                    })
                    }
                </Row>
            </div>
        </>
    )
}

export default Health
