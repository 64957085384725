import { useDispatch, useSelector } from "react-redux";

import SubNavBar from "../components/SubNavBar";
import StepsHeader from "../components/StepsHeader";
import Tab from "../components/Tab";
import Order from "../components/Order";
import UserForm from "../components/UserForm";
import MemberForm from "../components/MemberForm";
import FeatureChoices from "../components/FeatureChoices";
import SeniorDetail from "../components/SeniorDetail";
import { AppState } from "../redux/types";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import FeatureChoicesPhone from "../components/FeatureChoicesPhone";

const UserPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const category = useSelector((state: AppState) => state.categories.category?.attributes.slug);
    // console.log(user)
    useEffect(() => {
        if (!user?.id) {
            history.push("/kirjaudu");
        }
        return () => {};
    }, [dispatch, history, user]);

    //const { orders } = user
    //const paid = orders?.map((order: any) => order.isPaid)

    const { tab_id } = useParams<{ tab_id: string }>();
    // console.log(tab_id)

    const onSelect = (k: any) => {
        history.push(`/tili/${k}`);
    };

    return (
        <div className="user-page">
            <SubNavBar />
            {tab_id && user && user.group && !user.group.members ? (
                <>
                    <StepsHeader step1 step2 step3 step4 step5 />
                    <Tab
                        key1={tab_id}
                        onSelect={onSelect}
                        orders={<Order onSelect={onSelect} />}
                        userForm={<UserForm onSelect={onSelect} />}
                        memberForm={<MemberForm onSelect={onSelect} />}
                        seniorDetail={<SeniorDetail onSelect={onSelect} />}
                        featureChoices={category === "tablet" ? <FeatureChoices onSelect={onSelect} key={tab_id} /> : <FeatureChoicesPhone onSelect={onSelect} key={tab_id} />}
                    />
                </>
            ) : (
                <Tab
                    key1={tab_id}
                    onSelect={onSelect}
                    orders={<Order onSelect={onSelect} />}
                    userForm={<UserForm onSelect={onSelect} />}
                    memberForm={<MemberForm onSelect={onSelect} />}
                    seniorDetail={<SeniorDetail onSelect={onSelect} />}
                    featureChoices={category === "tablet" ? <FeatureChoices onSelect={onSelect} key={tab_id} /> : <FeatureChoicesPhone onSelect={onSelect} key={tab_id} />}
                />
            )}
        </div>
    );
};

export default UserPage;
