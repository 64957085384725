// src/api.ts
import axios from "axios";
import LocalStorage from "../../local-storage";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
});

api.interceptors.request.use((config: any) => {
    const baseUrl = process.env.REACT_APP_API_ENDPOINT || "https://api.digihappy.fi/";
    const token = LocalStorage.getToken();
    if (config.baseURL?.startsWith(baseUrl) && token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
});

export default api;
