const ComponentOnImage = ({
  height,
  width,
  backgroundRepeat,
  backgroundImage,
  backgroundSize,
  imgClassName,
  className,
  component,
}: any) => {
  return (
    <div className={`img-fluid ${imgClassName}`}
      style={{
        height: `${height}`,
        width: `${width}`,
        backgroundRepeat: `${backgroundRepeat}`,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: `${backgroundSize}`
      }}
    >
        <div className={className}>{component}</div>
    </div>
  )
}

export default ComponentOnImage
