import React, { ButtonHTMLAttributes } from "react";
import "./button.css";
import { classNames } from "../../utils/classnames";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    primary?: boolean;
    secondary?: boolean;
    children: React.ReactNode;
    emphasized?: boolean;
    className?: string;
}

const Button: React.FC<ButtonProps> = ({ primary = false, secondary = false, emphasized = false, className, children, ...props }) => {
    return (
        <button className={classNames("digi-btn", { "digi-btn-primary": primary, "digi-btn-secondary": secondary, "digi-btn-emphasized": emphasized }, className)} {...props}>
            {children}
        </button>
    );
};

export default Button;
