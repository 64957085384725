import {
    GET_USERS_FAIL, GET_USERS_REQUEST, GET_USERS_SUCCESS, User, GET_USER_EDIT_FORM_DATA_REQUEST, GET_USER_EDIT_FORM_DATA_SUCCESS,
    GET_USER_EDIT_FORM_DATA_FAIL, SET_USER_EDIT_FORM_DATA_REQUEST, SET_USER_EDIT_FORM_DATA_SUCCESS, SET_USER_EDIT_FORM_DATA_FAIL, RESET_USERS
} from '../types'

export const getUsersRequest = () => {
    return {
        type: GET_USERS_REQUEST
    }
}

export const getUsersSuccess = (list: User[]) => {
    return {
        type: GET_USERS_SUCCESS,
        payload: list
    }
}

export const getUsersFail = (error: any) => {
    return {
        type: GET_USERS_FAIL,
        payload: {
            error: error.response && error.response.data.message ?
                error.response.data.message :
                error.message
        }
    }
}

export const getUserEditFormData = (id: any) => {
    return {
        type: GET_USER_EDIT_FORM_DATA_REQUEST,
        payload: id,
    }
}

export const getUserEditFormDataSuccess = (customer: any) => {
    return {
        type: GET_USER_EDIT_FORM_DATA_SUCCESS,
        payload: customer
    }
}

export const getUserEditFormDataFail = (error: any) => {
    return {
        type: GET_USER_EDIT_FORM_DATA_FAIL,
        payload: error.response && error.response.data.message
            ? error.response.data
            : { status: error.response.status, message: error.message },
    }
}

export const setUserEditFormDataRequest = (data: any) => {
    return {
        type: SET_USER_EDIT_FORM_DATA_REQUEST,
        payload: data,
    }
}

export const setUserEditFormDataSuccess = () => {
    return {
        type: SET_USER_EDIT_FORM_DATA_SUCCESS,
    }
}

export const setUserEditFormDataFail = (error: any) => {
    return {
        type: SET_USER_EDIT_FORM_DATA_FAIL,
        payload: error.response && error.response.data.message
            ? error.response.data
            : { status: error.response.status, message: error.message },
    }
}

export const ResetUsers = () => {
    return {
        type: RESET_USERS,
        payload: {}
    }
}
