import { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import api from "../utils/api-helper/api";
import FormContainer from "../components/FormContainer";

class RecoverPassword extends Component {
    state = {
        username: "",
        submitted: false,
    };

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ username: e.target.value });
    };

    sendPasswordResetEmail = (e: React.FormEvent) => {
        e.preventDefault();
        const { username } = this.state;
        console.log("click");
        console.log("email", username);
        api.post(`/email/reset-password/user/${username}`);
        this.setState({ username: "", submitted: true });
    };
    render() {
        const { username, submitted } = this.state;
        return (
            <FormContainer>
                <h2 className="my-4 text-center">Vaihda salasana</h2>
                {submitted ? (
                    <div className="reset-password-form">
                        <p>Jos tili on systeemissämme, lähetimme sinulle sähköpostissa linkin salasanan vaihtamista varten.</p>
                        <Link to="/kirjaudu" className="tallenna">
                            Kirjaudu
                        </Link>
                    </div>
                ) : (
                    <div className="reset-password-form-wrapper">
                        <p className="my-3">Anna sähköpostiosoitteesi ja lähetämme sinulle ohjeet salasanan vaihtamista varten.</p>
                        <Form onSubmit={this.sendPasswordResetEmail}>
                            <Form.Label htmlFor="email" className="form-label">
                                Sähköpostiosoite
                            </Form.Label>
                            <Form.Group controlId="recover-password-email">
                                <Form.Control required onChange={this.handleChange} value={username} placeholder="Sähköpostiosoite" />
                            </Form.Group>
                            <Button className="password-reset-btn tallenna" type="submit" style={{ width: "100%" }}>
                                Lähetä pyyntö salasanan vaihtamiseksi
                            </Button>
                        </Form>
                        <Link to="/kirjaudu" className="muistan-salasanan">
                            Muistan salasanani
                        </Link>
                    </div>
                )}
            </FormContainer>
        );
    }
}
export default RecoverPassword;
