import { useDispatch } from "react-redux";
import { Row, Col, ListGroup, Button } from "react-bootstrap";

import { removeFromShoppingcart } from "../redux/actions/cart";
import { ProductType } from "../redux/types";
import { classNames } from "../utils/classnames";

const CartItem = ({ service }: { service: ProductType }) => {
    const dispatch = useDispatch();
    const mainCategory = ["tablet", "phone", "alarm"];
    const disabled = mainCategory.includes(service.category) || service.locked || false;
    return (
        <>
            <ListGroup.Item key={service.id}>
                <Row>
                    <Col md={7}>
                        <p className="service-name">{service.name}</p>
                    </Col>
                    <Col className="shoppingcart-service-price" md={4}>
                        {service.price}€ {service.priceType === "recurring" && `/${service.period}kk`}
                    </Col>
                    <Col md={1}>
                        <Button
                            className={classNames("shoppingcart-service-trash-button", { disabled: disabled })}
                            type="button"
                            variant="light"
                            arial-label="delete"
                            onClick={() => {
                                if (disabled) return;
                                dispatch(removeFromShoppingcart(service.id));
                            }}
                        >
                            <i className="fas fa-trash" />
                        </Button>
                    </Col>
                </Row>
            </ListGroup.Item>
        </>
    );
};

export default CartItem;
