import { useSelector } from 'react-redux'

import MembersList from './MembersList'
import { AppState } from '../redux/types'
import React from "react";
import {Container} from "react-bootstrap";

const Group = () => {
  const user = useSelector((state: AppState) => state.user)

  return (
    <div className='group'>
      {user &&
        user.group &&
        user.group.members &&
        user.group.members.length > 1 && (
          <h2 className='order-headers'
              style={{
                fontFamily: "Poppins, sans-serif",
                color: "#35413e",
                paddingTop: 12
              }}
          >Yhteystiedot</h2>
        )}
      {user &&
        user.group &&
        user.group.members &&
        user.group.members.length > 1 &&
        user.group.members.filter(obj=> {
          return obj.role === 'customer'
        }).map((member: any, index) => (
          <MembersList
            key={member.id}
            member={member.firstName}
            role={member.role}
            isSenior={member.isSenior}
            index={index}
          />
        ))}
      {user &&
      user.group &&
      user.group.members &&
      user.group.members.length > 1 &&
      user.group.members.filter(obj=> {
        return obj.role === 'senior'
      }).map((member: any, index) => (
          <MembersList
              key={member.id}
              member={member.firstName}
              role={member.role}
              index={index}
          />
      ))}
      {user &&
      user.group &&
      user.group.members &&
      user.group.members.length > 1 &&
      user.group.members.filter(obj=> {
        // console.log(obj)
        return obj.role === 'member'
      }).map((member: any, index) => (
          <MembersList
              key={member.id}
              member={member.firstName}
              role={member.role}
              index={index}
          />
      ))}

        <Container className='introduction'
                   style={{
                       paddingLeft: '1rem',
                   }}
        >
            Huom! Lähetämme sinulle 2 sähköpostia tilaukseen ja asiakastiliin liittyen. Jos käytät Gmail-osoitetta,
            tarkastathan myös "Tarjoukset" ("Promotions") kansion.
        </Container>
    </div>
  )
}

export default Group
