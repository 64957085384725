import { Link, useParams } from "react-router-dom";
import IconHeader from "../../components/icon-header/IconHeader";
import Container from "../../components/layout/containers/Container";
import styles from "./products.module.css";
import Product, { ProductHeader } from "../../components/products/Product";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToShoppingcart, getCategoriesRequest, getProductsRequest, getShippingRequest, ResetShoppingcart } from "../../redux/actions";
import { AppState } from "../../redux/types";
import Button from "../../components/button/Button";
import { classNames } from "../../utils/classnames";
import { CATEGORIES } from "../../utils/constants";
import { Helmet } from "react-helmet";

const ProductsScreen = () => {
    const { cat } = useParams<{ cat: string }>();
    const category = CATEGORIES[cat as keyof typeof CATEGORIES] as "tablet" | "phone" | "alarm";
    console.log(cat, category);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCategoriesRequest(category));
        return () => {};
    }, [dispatch, category]);

    useEffect(() => {
        dispatch(getShippingRequest());
    }, [dispatch]);

    const _categories = useSelector((state: AppState) => state.categories.category?.attributes.sub_categories?.data);
    const categories = useMemo(() => {
        return _categories?.sort((a, b) => a.attributes.SortNumber - b.attributes.SortNumber);
    }, [_categories]);

    const categoryIds = useMemo(() => categories?.map((c) => c.attributes.slug), [categories]);

    useEffect(() => {
        dispatch(getProductsRequest());
        return () => {};
    }, [dispatch]);

    useEffect(() => {
        if (categoryIds && categoryIds.length > 0) {
            console.log("resetting shopping cart...");
            dispatch(ResetShoppingcart());
        }
    }, [categoryIds, dispatch]);

    const _products = useSelector((state: AppState) => state.products.products);
    const products = useMemo(() => {
        return _products.filter((p) => categoryIds?.includes(p.category));
    }, [_products, categoryIds]);

    useEffect(() => {
        products.forEach((p) => {
            if (p.locked || p.selected) {
                dispatch(addToShoppingcart(p));
            }
        });
        return () => {};
    }, [dispatch, products]);

    const selectedProducts = useSelector((state: AppState) => state.cart.inCart);

    console.log(categoryIds, selectedProducts);
    const totalPrice = selectedProducts.reduce((total, item) => total + (item.price || 0), 0);
    const title = {
        tablet: "Osta senioritabletti palveluna | Helpot videopuhelut, it-tuki",
        phone: "Osta senioripuhelin palveluna | Parhaat ominaisuudet",
        alarm: "Osta turvaranneke | Gps-paikannus ja hälytys",
    };
    const description = {
        tablet: "Osta senioritabletti palveluna. Käyttövalmis. Videopuhelut ilman kosketusta, kivaa tekemistä, it-tuki.",
        phone: "Osta senioripuhelin palveluna. Käyttövalmis. Parhaat ominaisuudet, helpot videopuhelut. It-tuki.",
        alarm: "Osta turvaranneke. Gps-paikannus ja helppo hälytys. Asennus ja it-tuki.",
    };
    return (
        <>
            <Helmet>
                <title>{title[category]}</title>
                <meta name="description" content={description[category]} />
            </Helmet>
            <Container>
                {category === "tablet" && <IconHeader icon="/assets/icons/group.svg" text="Valitse Senioritablettipalvelu" className={styles.header} />}
                {category === "alarm" && <IconHeader icon="/assets/icons/group.svg" text="Valitse Turvahälytinpalvelu" className={styles.header} />}
                <div className={styles.container}>
                    {categories?.map((category, key) => (
                        <div className={styles.categoryContainer} key={key}>
                            <ProductHeader category={category} />
                            <div className={styles.productsContainer}>
                                {products
                                    .filter((product) => product.category === category.attributes.slug)
                                    .sort((a, b) => (a.sortNumber || 0) - (b.sortNumber || 0))
                                    .map((product, key) => {
                                        return <Product product={product} optional={category.attributes.Optional} single={category.attributes.Selection === "Single"} key={key} />;
                                    })}
                            </div>
                        </div>
                    ))}
                    <div className={styles.bottomLineContainer}>
                        <h5>Valitut tuotteet</h5>
                        <div className={styles.cartContainer}>
                            <div className={styles.itemsContainer}>
                                {selectedProducts
                                    .sort((a, b) => (a.sortNumber || 0) - (b.sortNumber || 0))
                                    .map((c, key) => (
                                        <div className={styles.cartItem} key={key}>
                                            <span className={styles.itemName}>{c.name}</span>
                                            <span className={styles.itemName}>
                                                {c.price || 0}€ {c.priceType === "recurring" && `/${c.period}kk`}
                                            </span>
                                        </div>
                                    ))}
                                <div className={styles.cartItem} style={{ marginTop: "3rem" }}>
                                    <span className={classNames(styles.itemName, styles.total)}>Yhteensä </span>
                                    <span className={classNames(styles.itemName, styles.total)}>{totalPrice}€</span>
                                </div>
                            </div>
                            <div className={styles.shoppingCart}>
                                <div className={styles.cartImageContainer}>
                                    <img src="/assets/icons/cart.png" className={styles.cartImage} alt="C" />
                                    <span className={styles.cartNumber}>{selectedProducts.length}</span>
                                </div>
                                <Link to={`/ostoskori?category=${category}`}>
                                    <Button className={styles.cartButton}>Siirry koriin</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default ProductsScreen;
