import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert, Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import StepsHeader from "../components/StepsHeader";
import CartItem from "../components/CartItem";
import { AppState, ProductType } from "../redux/types";
import StepsNotification from "../components/StepsNotification";
import React from "react";

const Cart: React.FC<RouteComponentProps> = ({ history }) => {
    const location = useLocation();
    const category = new URLSearchParams(location.search).get("category");
    const user = useSelector((state: AppState) => state.user);
    const selectedServices = useSelector((state: AppState) => state.cart.inCart);

    const submitHandler = () => {
        if (user.isLoggedIn || category === "alarm") {
            history.push("/toimitus");
        } else {
            history.push("/luo_tili");
        }
    };

    // back button
    const handleClick = () => {
        history.goBack();
    };

    const totalPrice = selectedServices.reduce((total, item) => total + (item.price || 0), 0);

    return (
        <>
            <StepsHeader step1 user={1} payment={1} account={1} />
            <Container className="shoppingcart-container">
                <Row>
                    <Col className="shoppingcart-services-col" md={8}>
                        <h1 className="shoppingcart-header">Ostoskori</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className="shoppingcart-services-col" md={8}>
                        {selectedServices.length === 0 ? (
                            <Alert
                                style={{
                                    background: "rgba(78,81,128,0.8)",
                                    color: "#ffffff",
                                    marginBottom: 42,
                                }}
                            >
                                Ostoskorisi on tyhjä{" "}
                                <Link
                                    to="/osta"
                                    style={{
                                        color: "#ffffff",
                                    }}
                                >
                                    Valitse palvelu
                                </Link>
                            </Alert>
                        ) : (
                            <ListGroup variant="flush">
                                {selectedServices.map((service: ProductType, key) => (
                                    <CartItem key={key} service={service} />
                                ))}
                            </ListGroup>
                        )}
                        <Button onClick={handleClick} className="btn-block shoppingcart-back-button">
                            Edellinen
                        </Button>
                    </Col>
                    <Col md={4} className="price-sum-col">
                        <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="text-center">
                                    <h2>{totalPrice} €</h2>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Button type="button" className="btn-block tallenna" onClick={submitHandler} disabled={!selectedServices.length}>
                                        Seuraava
                                    </Button>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <StepsNotification />
        </>
    );
};

export default Cart;
