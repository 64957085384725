import {DELETE_SERVICE_SUCCESS, GET_SERVICES_SUCCESS, Resources, ServicesActions} from '../types'

export const resources = (
    state: any = {
        services: [],
        //   loading: false,
        //   error: null,
    },
    action: ServicesActions
): Resources => {
    switch (action.type) {
        case GET_SERVICES_SUCCESS:
            return {
                ...state,
                services: action.payload,
            }
        case DELETE_SERVICE_SUCCESS:
            return {...state, deleting: false, deleted_at: Math.round(new Date().getTime() / 1000)}

        default:
            return state
    }
}

export default resources
  