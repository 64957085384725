import {GET_ITSUPPORT_LIST_REQUEST, GET_ITSUPPORT_LIST_SUCCESS, GET_ITSUPPORT_LIST_FAIL,
    GET_ITSUPPORT_PROFILE_REQUEST, GET_ITSUPPORT_PROFILE_SUCCESS, GET_ITSUPPORT_PROFILE_FAIL,
    UPDATE_ITSUPPORT_PROFILE_REQUEST, UPDATE_ITSUPPORT_PROFILE_SUCCESS, UPDATE_ITSUPPORT_PROFILE_FAIL, SEND_INVITE_ITSUPPORT_REQUEST,
    SEND_INVITE_ITSUPPORT_SUCCESS, SEND_INVITE_ITSUPPORT_FAIL, SIGNUP_ITSUPPORT_REQUEST, SIGNUP_ITSUPPORT_SUCCESS, SIGNUP_ITSUPPORT_FAIL,
    RESET_ITSUPPORT_MESSAGE, SET_ITSUPPORT_MESSAGE, DELETE_IT_SUPPORT_PROFILE_REQUEST, DELETE_IT_SUPPORT_PROFILE_REQUEST_SUCCESS, DELETE_IT_SUPPORT_PROFILE_REQUEST_FAILED} from '../types';

export const getItSupportListRequest = () => {
    return {
        type: GET_ITSUPPORT_LIST_REQUEST
    }
}

export const getItSupportListSuccess = (list: any[]) => {
    return {
        type: GET_ITSUPPORT_LIST_SUCCESS,
        payload: list
    }
}

export const getItSupportListFail = (error: any) => {
    return {
        type: GET_ITSUPPORT_LIST_FAIL,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}

export const getItSupportProfileRequest = (id: string) => {
    return {
        type: GET_ITSUPPORT_PROFILE_REQUEST,
        payload: id,
    }
}

export const getItSupportProfileSuccess = (data: any) => {
    return {
        type: GET_ITSUPPORT_PROFILE_SUCCESS,
        payload: data,
    }
}

export const getItSupportProfileFail = (error: any) => {
    return {
        type: GET_ITSUPPORT_PROFILE_FAIL,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}

export const updateItSupportProfileRequest = (profileData: any) => {
    return {
        type: UPDATE_ITSUPPORT_PROFILE_REQUEST,
        payload: profileData,
    }
}

export const updateItSupportProfileSuccess = (data: any) => {
    return {
        type: UPDATE_ITSUPPORT_PROFILE_SUCCESS,
        payload: data,
    }
}

export const updateItSupportProfileFail = (error: any) => {
    return {
        type: UPDATE_ITSUPPORT_PROFILE_FAIL,
        payload: error?.response && error?.response?.data?.message
            ? error?.response.data
            : {status: error?.response?.status, message: error?.message},
    }
}

export const sendInviteItSupportRequest = (data: any) => {
    return {
        type: SEND_INVITE_ITSUPPORT_REQUEST,
        payload: data,
    }
}

export const sendInviteItSupportSuccess = () => {
    return {
        type: SEND_INVITE_ITSUPPORT_SUCCESS,
    }
}

export const sendInviteItSupportFail = (error: any) => {
    return {
        type: SEND_INVITE_ITSUPPORT_FAIL,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}

export const signUpItSupportRequest = (data: any) => {
    return {
        type: SIGNUP_ITSUPPORT_REQUEST,
        payload: data,
    }
}

export const signUpItSupportSuccess = () => {
    return {
        type: SIGNUP_ITSUPPORT_SUCCESS,
    }
}

export const signUpItSupportFail = (error: any) => {
    return {
        type: SIGNUP_ITSUPPORT_FAIL,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}

export const resetItSupportMessage = () => {
    return {
        type: RESET_ITSUPPORT_MESSAGE,
    }
}

export const setItSupportMessage = (response: any) => {
    return {
        type: SET_ITSUPPORT_MESSAGE,
        payload: response,
    }
}

export const removeItSupportProfileRequest = (id: number) => {
    return {
        type: DELETE_IT_SUPPORT_PROFILE_REQUEST,
        payload: {userId: id},
    }
}

export const removeItSupportProfileRequestSuccess = () => {
    return {
        type: DELETE_IT_SUPPORT_PROFILE_REQUEST_SUCCESS
    }
}

export const removeItSupportProfileRequestFailed = () => ({
    type: DELETE_IT_SUPPORT_PROFILE_REQUEST_FAILED
})