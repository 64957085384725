import {
    BlogsActions,
    BlogsState,
    CREATE_BLOG_SUCCESS,
    DELETE_BLOG_SUCCESS,
    GET_BLOGS_SUCCESS,
    RESET_BLOG
} from '../types'

export const blog = (
    state: any = {
        list: [],
        loading: false,
        deleted_at: null,
        error: null,
    },
    action: BlogsActions
): BlogsState => {
    switch (action.type) {
        case CREATE_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                alert: action.payload
            }
        case DELETE_BLOG_SUCCESS:
            return {
                ...state,
                deleted_at: Math.round(new Date().getTime() / 1000)
            }

        case GET_BLOGS_SUCCESS:
            return {
                ...state,
                list: action.payload
            }

        case RESET_BLOG:
            return {
                list: [],
                loading: false,
                deleted_at: null,
                error: null,
            }

        default:
            return state
    }
}

export default blog
