import { put, select, takeLatest } from "redux-saga/effects";

import { getShippingFail, getShippingSuccess } from "../actions";
import { CMSShippingMethod, GET_SHIPPING_REQUEST, GetShippingRequestAction } from "../types";
import cmsApi from "../../utils/api-helper/cmsApi";

function* getShippingSaga(action: GetShippingRequestAction) {
    try {
        //@ts-ignore
        const res = yield cmsApi.get("/shipping-methods");
        const shippings = (res.data.data as CMSShippingMethod[]).map((shipping) => ({
            id: shipping.id,
            name: shipping.attributes.Name,
            shippingMethod: shipping.attributes.ShippingMethod,
            stripeShippingRateId: shipping.attributes.StripeShippingRateId,
            stripeFreeShippingRateId: shipping.attributes.StripeFreeShippingRateId,
            price: shipping.attributes.Price,
        }));
        yield put(getShippingSuccess(shippings));
    } catch (error) {
        yield put(getShippingFail(error));
    }
}

// for admin

function* saveState() {
    //@ts-ignore
    const state = yield select();
    yield localStorage.setItem("initState", JSON.stringify(state));
}

const sagaWatcher = [takeLatest(GET_SHIPPING_REQUEST, getShippingSaga), takeLatest("*", saveState)];

export default sagaWatcher;
