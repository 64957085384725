import {AlertsActions, AlertsState, CREATE_ALERT_SUCCESS, DELETE_ALERT_SUCCESS, GET_ALERTS_SUCCESS} from '../types'

export const alerts = (
    state: any = {
        list: [],
        loading: false,
        deleted_at: null,
        error: null,
    },
    action: AlertsActions
): AlertsState => {
    switch (action.type) {
        case CREATE_ALERT_SUCCESS:
            return {
                ...state,
                loading: false,
                alert: action.payload
            }
        case DELETE_ALERT_SUCCESS:
            return {
                ...state,
                deleted_at: Math.round(new Date().getTime() / 1000)
            }

        case GET_ALERTS_SUCCESS:
            return {
                ...state,
                list: action.payload
            }

        default:
            return state
    }
}

export default alerts
