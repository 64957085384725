import {
    AlertItem,
    CREATE_SENIOR_HOME_FAIL,
    CREATE_SENIOR_HOME_REQUEST,
    CREATE_SENIOR_HOME_SUCCESS,
    DELETE_SENIOR_HOME_FAIL,
    DELETE_SENIOR_HOME_REQUEST,
    DELETE_SENIOR_HOME_SUCCESS,
    DeleteSeniorHomeRequestAction,
    GET_SENIOR_HOMES_FAIL,
    GET_SENIOR_HOMES_REQUEST,
    GET_SENIOR_HOMES_SUCCESS,
    SeniorHomeItem,
} from '../types'


export const getSeniorHomesRequest = () => {
    return {
        type: GET_SENIOR_HOMES_REQUEST,
    }
}

export const getSeniorHomesSuccess = (senior_homes: AlertItem[]) => {
    return {
        type: GET_SENIOR_HOMES_SUCCESS,
        payload: senior_homes
    }
}

export const getSeniorHomesFail = (error: any) => {
    return {
        type: GET_SENIOR_HOMES_FAIL,
        payload: {
            error
        }
    }
}


// For admin
// TODO: update and remove SeniorHome
export const createSeniorHomeRequest = (senior_home: SeniorHomeItem) => {
    return {
        type: CREATE_SENIOR_HOME_REQUEST,
        payload: {
            senior_home
        }
    }
}

export const createSeniorHomeSuccess = (senior_home: SeniorHomeItem) => {
    return {
        type: CREATE_SENIOR_HOME_SUCCESS,
        payload: {
            senior_home
        }
    }
}

export const createSeniorHomeFail = (error: any) => {
    return {
        type: CREATE_SENIOR_HOME_FAIL,
        payload: {
            error
        }
    }
}

export const deleteSeniorHomeRequest = (id: DeleteSeniorHomeRequestAction) => {
    return {
        type: DELETE_SENIOR_HOME_REQUEST,
        payload: id
    }
}

export const deleteSeniorHomeSuccess = () => {
    return {
        type: DELETE_SENIOR_HOME_SUCCESS,
    }
}

export const deleteSeniorHomeFail = (error: any) => {
    return {
        type: DELETE_SENIOR_HOME_FAIL,
        payload: error,
    }
}
