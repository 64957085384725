import RegisterCustomerForm from '../components/RegisterCustomerForm'
import FormContainer from '../components/FormContainer'
import StepsHeader from '../components/StepsHeader'
import {useSelector} from "react-redux";
import {AppState} from "../redux/types";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const Register = () => {
    const user = useSelector((state: AppState) => state.user)
    const history = useHistory()

    const goto = () => {
        history.push('/toimitus')
    }

  return (
    <>
    <StepsHeader step1 step2 user={1} payment={1} account={1}/>
    <FormContainer>
        {user.isLoggedIn ?
            <div>
                <div>
                    You are already registered. Go to the next step
                </div>
                <Button className='register-button tallenna' onClick={goto}>
                    Yhteystiedot, toimitus
                </Button>
            </div>
            :
            <RegisterCustomerForm/>
        }
    </FormContainer>
    </>
  )
}

export default Register
