import { Container } from "react-bootstrap";
import ServicesAccordionTable from "../components/ServicesAccordionTable";
import ServicesInfo2 from "../components/ServicesInfo2";
import texts from "../texts";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAlertsRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../components/button/Button";

const Services = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const [play, setPlay] = useState(0);
    return (
        <>
            <Helmet>
                <title>Senioritabletti palveluna | Helpot videopuhelut ja it-tuki</title>
                <meta name="description" content="Senioritabletti on helppo ja käyttövalmis palvelu. Videopuhelut ilman kosketusta, kivaa tekemistä, it-tuki." />
            </Helmet>
            <Container
                fluid
                style={{
                    height: `calc(100vh - ${
                        56 +
                        56 *
                            list?.filter((el) => {
                                // @ts-ignore
                                return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                            })?.length -
                        1
                    }px)`,
                    width: "100vw",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url('/assets/images/banners/paras-tabletti-senorille.webp')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    minHeight: 640,
                }}
                aria-label="Henkilö käyttää tablettia, jonka näytöllä näkyy erilaisia sovelluksia."
                className="img-fluid contact-pic"
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title global-white">{texts[9].text1}</h1>
                    <p className="global-banner-text global-white">
                        {texts[9].text2}
                        <br />
                        {texts[9].text3}
                    </p>
                    <Link to="/osta/senioritabletti" style={{ marginTop: "auto" }}>
                        <Button secondary emphasized>
                            Osta senioritabletti
                        </Button>
                    </Link>
                </div>
            </Container>
            <Container fluid className="my-3 services-container services-container-80" style={{ minWidth: "min(900px, 100%)" }}>
                <h1 className="services-header">
                    Tablettipalvelun <strong>kuukausimaksuun sisältyy kaikki</strong>
                </h1>
                <ServicesInfo2 />
            </Container>
            <Container fluid className="my-3 services-container service-table-container" id="palvelut">
                <div>
                    <h1 className="services-header" style={{ marginBottom: 0, fontWeight: 600 }}>
                        Mikä palvelu on paras seniorille?
                    </h1>
                    <p className="what-is-best" id="best">
                        Kaikkiin palveluihin sisältyy tabletti, jalusta, pitkä johto, suojakalvo, tabletin ja kotinäkymän muokkaus, sim, rajaton data, videopuhelupalvelu, alkuneuvonta, it-tuki , etähallinta, päivitykset, tietoturva,
                        maksuttomat äänikirjat
                    </p>
                </div>
                <ServicesAccordionTable />
                <div className="services-button-container">
                    <Link to="/osta/senioritabletti">
                        <Button>Osta senioritabletti</Button>
                    </Link>
                    <p className="global-text text-normal text-center global-text-dark">
                        Saatte ensimmäisen kuukauden Digihappy-palvelua koskevaa it- tukea maksutta ilman rajoituksia. Tämän jälkeen yksilöllinen it-tuki on maksutonta 45 minuuttia /kk Lisätuen kustannus on 10 €/10 minuuttia, minimiveloitus
                        on 10 €/puhelu. Jos kyseessä on palvelun tai laitteen meihin liittyvä toimintahäiriö, sen ratkaiseminen on aina teille maksutonta. Seniorin tabletissa on valmiina maksuton äänikirjasovellus, jonka tuottaa Celia.
                        Palvelu on henkilöille, joilla on lukemisvaikeus, esimerkiksi ikäihmisellä heikentynyt näkö, lihaskunto, toimintakyky tai muisti. Digihappy neuvoo, miten saat palvelun käyttöön ja omat tunnukset kirjastosta.
                    </p>
                    <Button onClick={() => setPlay(1)}>Katso video</Button>
                    <div className="global-video-wrapper">
                        <iframe
                            src={`https://www.youtube.com/embed/U3AGXnauUQg?autoplay=${play}`}
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay"
                            allowFullScreen
                            title="Digihappy Senioritabletit"
                            style={{
                                margin: "0 auto",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                aspectRatio: 16 / 9,
                            }}
                        />
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Services;
