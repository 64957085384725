const Customer = ({ member, isSenior }: any) => {
  return (
    <>
      <p className='role'>
        <strong>Asiakas {isSenior && `(${'Seniori'})`}</strong>
      </p>

      <li className='membership'>{member}</li>
    </>
  )
}

export default Customer
