const ServicesAccordionTable = () => {
    return (
        <div className="palvelut-table-div">
            <table className="palvelut-table">
                <colgroup>
                    <col
                        style={{
                            width: "40%",
                        }}
                    />
                    <col
                        style={{
                            width: "20%",
                        }}
                    />
                    <col
                        style={{
                            width: "20%",
                        }}
                    />
                    <col
                        style={{
                            width: "20%",
                        }}
                    />
                </colgroup>
                <tbody>
                    <tr>
                        <td />
                        <td>
                            <h1 className="accordion-button-header">Huoleton</h1>
                            <div className="accordion-button-text">kun tabletin käyttö on vaikeaa ja toiveena on lähinnä videopuhelut. Edullisin ja pienin.</div>
                        </td>
                        <td>
                            <h1 className="accordion-button-header">Helppo</h1>
                            <div className="accordion-button-text">kun tabletin käyttö on vaikeaa ja onnistuu osittain. Suosituin.</div>
                        </td>
                        <td>
                            <h1 className="accordion-button-header">Mestari</h1>
                            <div className="accordion-button-text">kun tabletin käyttö onnistuu vaivatta. Aktiiviselle seniorille.</div>
                        </td>
                    </tr>
                    <tr>
                        <td>Koko</td>
                        <td>8,7”</td>
                        <td>10,5” / 11,0”</td>
                        <td>10,5” / 11,0”</td>
                    </tr>
                    <tr>
                        <td>Sovellukset valmiina tabletissa</td>
                        <td>Vain Digihappy videopuhelut tai myös radiot, äänikirjat</td>
                        <td>Vain Digihappy videopuhelut tai valinnan mukaan radiot, äänikirjat, Chrome, YleAreena, lehdet, pasianssi, muistipeli, shakki, taide, lääke, Whatsapp</td>
                        <td>Kattava sovellusvalikoima</td>
                    </tr>
                    <tr>
                        <td>Omia sovelluksia voi lisätä</td>
                        <td></td>
                        <td>
                            <i className="icon fas fa-check-circle fa-4x" />
                        </td>
                        <td>
                            <i className="icon fas fa-check-circle fa-4x" /> <i className="icon fas fa-check-circle fa-4x" />
                        </td>
                    </tr>
                    <tr>
                        <td>Kotinäkymä</td>
                        <td>Muokattu helpoksi</td>
                        <td>Muokattu helpoksi</td>
                        <td>Samsung näkymä</td>
                    </tr>
                    <tr>
                        <td>Tabletti</td>
                        <td>Samsung Galaxy Tab A7 Lite LTE/A9 5G</td>
                        <td>Samsung Galaxy Tab A8 lte/A9+ 5G</td>
                        <td>Samsung Galaxy Tab A8 lte/A9+ 5G</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ServicesAccordionTable;
