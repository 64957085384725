import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav } from "react-bootstrap";

const Links = () => {
    return (
        <Navbar className="links">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="global-links-container">
                    <LinkContainer to="/#">
                        <Nav.Link className="page-links">Koti</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/palvelut">
                        <Nav.Link className="page-links">Palvelut</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/contact_info">
                        <Nav.Link className="page-links">Yhteystiedot</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/yrityksille">
                        <Nav.Link className="page-links">Yrityksille</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/kirjaudu">
                        <Nav.Link className="page-links">Kirjaudu</Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Links;
