import {
  CREATE_GROUP_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_USER_SUCCESS, LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_CUSTOMER_FAIL,
  REGISTER_CUSTOMER_SUCCESS,
  SEND_CONTACT_MAIL_SUCCESS,
  SEND_EMAIL_CUSTOMER_SUCCESS,
  SEND_EMAIL_MEMBER_SUCCESS,
  UPDATE_GROUP_SUCCESS,
  UPDATE_USER_SUCCESS,
  UserActions,
  UserState,
  UPDATE_USER_EDIT_REQUEST,
  UPDATE_USER_EDIT_REQUEST_SUCCESS,
  UPDATE_USER_EDIT_REQUEST_FAIL
} from '../types'

const initState: UserState = {
  id: null,
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  loading: false,
  error: null,
  group: {
    id: null,
    name: '',
    members: [],
  },
  orders: [],
  isLoggedIn: false,
  mobileNumber: '',
  skypeEmail: '',
  isAdmin: false,
  readOnly: false,
  isSenior: false,
  tabletLanguage: '',
  autoAnswer: '',
  serviceOption: '',
  chosenService: '',
  stripePublicKey: '',
}

const user = (state = initState, action: UserActions): UserState => {
  switch (action.type) {
    case REGISTER_CUSTOMER_SUCCESS:
      return { ...state, ...action.payload }
    case REGISTER_CUSTOMER_FAIL:
      return { ...state, ...action.payload }
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
      }
    case LOGIN_USER_FAIL:
      return {
        ...state,
        ...action.payload,
        isLoggedIn: false,
      }
    case LOGOUT_USER:
      return initState
    case GET_USER_SUCCESS:
      return { ...state, ...action.payload, loading: false }
    case UPDATE_USER_SUCCESS:
      return { ...state, ...action.payload, loading: false }
    case DELETE_USER_SUCCESS: 
      return { ...state, loading: false}
    case CREATE_GROUP_SUCCESS:
      return { ...state, group: action.payload, loading: false }
    case UPDATE_GROUP_SUCCESS:
      return { ...state, group: action.payload, loading: false }
    case SEND_EMAIL_MEMBER_SUCCESS:
      return { ...state, ...action.payload, loading: false }
    case SEND_EMAIL_CUSTOMER_SUCCESS:
      return { ...state, ...action.payload, loading: false }
    case SEND_CONTACT_MAIL_SUCCESS:
      return { ...state, ...action.payload, loading: false }
    case UPDATE_USER_EDIT_REQUEST:
      return {...state, loading: true}
    case UPDATE_USER_EDIT_REQUEST_SUCCESS:
      return {...state, loading: false}
    case UPDATE_USER_EDIT_REQUEST_FAIL:
      return {...state, loading: false, error: action.payload}
    default:
      return state
  }
}

export default user