import React, { CSSProperties, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactNode, useEffect } from "react";
import { getAlertsRequest } from "../../redux/actions";
import { AppState } from "../../redux/types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button";
import Banner from "../../components/layout/banner/Banner";
import styles from "./phone.module.css";
import { classNames } from "../../utils/classnames";

export type ServicesType = {
    content: ReactNode;
    image: string;
    credit?: string;
    alt?: string;
};
const Phone = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const services: ServicesType[] = [
        {
            content: (
                <>
                    <div className="services-info-title">Puhelin, lisävarusteet </div>
                    <ul className="services-info-text">
                        <li>
                            <a href="https://www.samsung.com/fi/smartphones/galaxy-a/galaxy-a15-5g-blue-128gb-sm-a156bzbdeub" target="_blank" rel="noreferrer">
                                <span className="global-text global-text-strong">Samsung Galaxy</span>
                            </a>{" "}
                            A15 5G, 128 GB
                        </li>
                        <li>Android 14, sininen</li>
                        <li>Lisävarusteet valinnan mukaan</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/senoripuhelin.webp",
            alt: "Nainen pitää kädessään sinistä puhelinta",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Helpoksi muokattu kotinäkymä ja toiminnot</div>
                    <ul className="services-info-text">
                        <li>Kotinäkymä muokattu helpoksi</li>
                        <li>Toiminnot muokattu helpoiksi</li>
                        <li>
                            Tarpeelliset apit valmiina
                            <ul>
                                <li>Pelkkä videopuhelunäkymä tai mm. puhelut, tekstarit, kamera, uutiset - ja mahdollisuus lisätä omia appeja</li>
                            </ul>
                        </li>
                        <li>Jos asiakas myöhemmin luopuu</li>
                        <p>Digihappy palvelusta, puhelin palautuu normaaliksi Samsung älypuhelimeksi.</p>
                        <a href="https://www.youtube.com/watch?v=ThIlFx8pbn8" className="global-link" target="_blank" rel="noreferrer">
                            Katso video
                        </a>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/paras-senioripuhelin.webp",
            alt: "Henkilö pitelee älypuhelinta, jonka näytöllä näkyy erilaisia sovelluksia",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Helppo ja turvallinen videopuhelu</div>

                    <ul className="services-info-text">
                        <li>Digihappy videoappi on seniorilla puhelimessa ja läheisellä omassa kännykässä</li>
                        <li>Kun läheinen soittaa, seniorin video avautuu haluttaessa ilman kosketusta</li>
                        <li>Seniori soittaa koskemalla läheisen kuvaa</li>
                        <li>Turvallinen, vain perhetilin jäsenet voivat soittaa</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/videopuhelu-senioripuhelimella.webp",
            alt: "Älypuhelin pöydällä näyttää videopuhelua, jossa on nainen",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Perhetili videopuheluihin</div>
                    <ul className="services-info-text">
                        <li>Saat palveluun kuusi perheenjäsentä tai ystävää. Jäsen voi soittaa videopuhelun, joka avautuu haluttaessa automaattisesti seniorin tabletissa. Jäsenen kuva on seniorin videopuhelunäkymässä.</li>
                        <li>Jäsenen käytössä on it-tuki.</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/helpot-videopuhelut-senioripuhelimella.webp",
            alt: "Uusi Samsung Galaxy S10 5G ja kosketusnäyttökynä pöydällä. ",
        },
        {
            content: (
                <>
                    <div className="services-info-title">It-tuki, etähallinta, tietoturva, päivitykset</div>
                    <ul className="services-info-text">
                        <li>Aloituksessa videoneuvonta</li>
                        <li>Jatkuva it-tuki, puhelin- ja videoneuvonta, viestikanava</li>
                        <li>Puhelimen etähallinta, myös ilman seniorin aktiivista osallistumista</li>
                        <li>Tietoturva ja päivitykset</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/5.webp",
        },
    ];

    const [table, openTable] = useState(false);
    const [play, setPlay] = useState(0);

    return (
        <>
            <Helmet>
                <title>Senioripuhelin palveluna | Parhaat ominaisuudet ja it-tuki</title>
                <meta name="description" content="Senioripuhelin, jossa on kaikki tarpeellinen, ei turhaa. Helpot videopuhelut. Käyttövalmis, it-tuki." />
            </Helmet>
            <Banner
                backgroundImage="/assets/images/banners/helpot-videopuhelut.webp"
                label="Nainen pitelee älypuhelinta, jonka näytöllä näkyy yhteystietoluettelo"
                height={`calc(100vh - ${
                    56 +
                    56 *
                        list?.filter((el) => {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title global-white">Senioripuhelin palveluna</h1>
                    <p className="global-banner-text global-white">Helpoin älypuhelin seniorille. Kaikki tarpeellinen, ei turhaa.</p>
                    <Link to="/osta/senioripuhelin" style={{ marginTop: "auto" }}>
                        <Button secondary emphasized>
                            OSTA SENIORIPUHELIN
                        </Button>
                    </Link>
                </div>
            </Banner>
            <Container fluid className="my-3 services-container services-container-80" style={{ minWidth: "min(900px, 100%)" }}>
                <h1 className="services-header">Puhelin ja Digihappy palvelu - Teemme kaiken puolestasi</h1>
                <div className="global-box-line-container">
                    {services.map((service, key) => (
                        <div className="global-box-line" key={key}>
                            {key % 2 === 0 ? (
                                <React.Fragment>
                                    <div className="global-box global-background-gray">{service.content}</div>
                                    <div className="global-image-container">
                                        <img src={service.image} alt={service.alt} className="global-image" />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="global-image-container">
                                        <img src={service.image} alt={service.alt} className="global-image" />
                                    </div>
                                    <div className="global-box global-background-gray">{service.content}</div>
                                </React.Fragment>
                            )}
                        </div>
                    ))}
                </div>
            </Container>
            <Container fluid className="my-3 services-container service-table-container" id="palvelut">
                <div className={styles.serviceContainer}>
                    <h1 className="services-header text-center" style={{ marginBottom: 0, fontWeight: 600 }}>
                        Senioriälypuhelinvertailu
                    </h1>
                    <p className={styles.description} id="best">
                        Digihappy senioriälypuhelin pärjää erinomaisesti helppokäyttöisyydessä, suorituskyvyssä ja palvelussa suhteessa vastaaviin tuotteisiin.
                    </p>
                    <div className={styles.tableDropdownContainer} onClick={() => openTable((table) => !table)}>
                        <span className={styles.dropDownText}>Avaa seniori älypuhelinvertailu</span>
                        <div className={table ? styles.iconOpen : styles.icon}>
                            <img className={styles.dropDownIcon} src="/assets/icons/arrow_left.svg" alt="<" />
                        </div>
                    </div>
                    <div className={table ? styles.tableOpen : styles.tableHidden}>
                        <ComparisonTable />
                        <p className={classNames(styles.bottomText, "global-text-small text-center")}>
                            Saatte ensimmäisen kuukauden Digihappy-palvelua koskevaa it- tukea maksutta ilman rajoituksia. Tämän jälkeen yksilöllinen it-tuki on maksutonta 45 minuuttia /kk Lisätuen kustannus on 10 €/10 minuuttia,
                            minimiveloitus on 10 €/puhelu. Jos kyseessä on palvelun tai laitteen meihin liittyvä toimintahäiriö, sen ratkaiseminen on aina teille maksutonta.
                        </p>
                    </div>
                </div>
                <div className="services-button-container">
                    <Button onClick={() => setPlay(1)}>Katso video</Button>
                    <div className="global-video-wrapper">
                        <iframe
                            src={`https://www.youtube.com/embed/ThIlFx8pbn8?autoplay=${play}`}
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay"
                            allowFullScreen
                            title="Digihappy Senioripuhelin"
                            style={{
                                margin: "0 auto",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                aspectRatio: 16 / 9,
                            }}
                        />
                    </div>
                </div>
            </Container>
        </>
    );
};

const ComparisonTable = () => (
    <div className="palvelut-table-div">
        <table className={classNames("palvelut-table", styles.table)}>
            <colgroup>
                <col
                    style={{
                        width: "40%",
                    }}
                />
                <col
                    style={{
                        width: "20%",
                    }}
                />
                <col
                    style={{
                        width: "20%",
                    }}
                />
                <col
                    style={{
                        width: "20%",
                    }}
                />
            </colgroup>
            <tbody>
                <tr>
                    <td />
                    <td className={styles.header}>
                        <h1 className="accordion-button-header">Digihappy /Samsung</h1>
                    </td>
                    <td className={styles.header}>
                        <h1 className="accordion-button-header">Samsung</h1>
                    </td>
                    <td className={styles.header}>
                        <h1 className="accordion-button-header">Doro</h1>
                    </td>
                </tr>
                <tr>
                    <td>Puhelinmalli</td>
                    <td>Samsung Galaxy A15</td>
                    <td>Samsung Galaxy A15</td>
                    <td>Doro 8210</td>
                </tr>
                <tr>
                    <td>Verkkoyhteys</td>
                    <td>5G</td>
                    <td>5G</td>
                    <td>4G</td>
                </tr>
                <tr>
                    <td>Käyttöjärjestelmä</td>
                    <td>Android 14</td>
                    <td>Android 14</td>
                    <td>Android 12</td>
                </tr>
                <tr>
                    <td>Kamera</td>
                    <td>50 MP</td>
                    <td>50 MP</td>
                    <td>16 MP</td>
                </tr>
                <tr>
                    <td>Muisti</td>
                    <td>4G/128 GB</td>
                    <td>4G/128 GB</td>
                    <td>4G/64 GB</td>
                </tr>
                <tr>
                    <td>Vedenkestävyys</td>
                    <td>Ei ilmoitettu</td>
                    <td>Ei ilmoitettu</td>
                    <td>Roiskeet</td>
                </tr>
                <tr>
                    <td>Akunkesto - puheaika</td>
                    <td>44 tuntia</td>
                    <td>44 tuntia</td>
                    <td>23 tuntia</td>
                </tr>
                <tr>
                    <td>Pikalataus</td>
                    <td>1 tunti 25 min</td>
                    <td>1 tunti 25 min</td>
                    <td>ei pikalatausta</td>
                </tr>
                <tr>
                    <td>Suorituskyky - Geekbench - isompi arvo parempi</td>
                    <td>684/1831</td>
                    <td>684/1831</td>
                    <td>182/800</td>
                </tr>
                <tr>
                    <td>Hätäpuhelu napista painaen</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                </tr>
                <tr>
                    <td>Muokattu kotinäkymä ja toiminnot</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                </tr>
                <tr>
                    <td>Käyttövalmis - optimoidut asetukset valmiina</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td>
                        <Star />
                    </td>
                </tr>
                <tr>
                    <td>Käyttövalmis - liittymä haluttaessa valmiina</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Isot kuvakkeet</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td>
                        <div style={{ display: "flex", columnGap: "2px", justifyContent: "space-between" }}>
                            <Star />
                            <span style={{ textAlign: "right" }}>
                                osittain <br />
                                asetettavissa
                            </span>
                        </div>
                    </td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                </tr>
                <tr>
                    <td>Kaikki apit yhdessä näkymässä</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Ei turhia appeja</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td>
                        <div style={{ display: "flex", columnGap: "2px", justifyContent: "space-between" }}>
                            <Star />
                            <span style={{ textAlign: "right" }}>
                                osittain <br />
                                asetettavissa
                            </span>
                        </div>
                    </td>
                    <td>
                        <Star />
                    </td>
                </tr>
                <tr>
                    <td>Kotinäkymä toimii ilman svaippausta</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Iso kotinäppäin</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td>
                        <div style={{ display: "flex", columnGap: "2px", justifyContent: "space-between", flexWrap: "wrap", alignItems: "flex-end" }}>
                            <div>
                                <Star />
                                <Star />
                            </div>
                            <span style={{ textAlign: "right", marginLeft: "auto" }}>asetettavissa</span>
                        </div>
                    </td>
                    <td>
                        <Star />
                    </td>
                </tr>
                <tr>
                    <td>Ääni, fontti, lukittu orientaatio</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td>
                        <div style={{ display: "flex", columnGap: "2px", justifyContent: "space-between", flexWrap: "wrap", alignItems: "flex-end" }}>
                            <div>
                                <Star />
                                <Star />
                            </div>
                            <span style={{ textAlign: "right", marginLeft: "auto" }}>asetettavissa</span>
                        </div>
                    </td>
                    <td>
                        <div style={{ display: "flex", columnGap: "2px", justifyContent: "space-between", flexWrap: "wrap", alignItems: "flex-end" }}>
                            <div>
                                <Star />
                                <Star />
                            </div>
                            <span style={{ textAlign: "right", marginLeft: "auto" }}>asetettavissa</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        Helpot videopuhelut perheen kanssa <br />
                        (Digihappy appi)
                    </td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Vastaus videopuheluun myös ilman kosketusta</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Soitto painamalla isoa läheisen kuvaa</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Alkuneuvonta</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Jatkuva it-tuki, päivitykset</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Etähallinta osana it-palvelua</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Etähallinta läheisen toteuttamana</td>
                    <td>
                        <Star />
                    </td>
                    <td>
                        <Star />
                    </td>
                    <td>
                        <Star />
                    </td>
                </tr>
                <tr>
                    <td>Seniorikäyttäjän jälkeen puhelin voidaan helposti asentaa korkealaatuiseksi älypuhelimeksi</td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td>
                        <Star />
                        <Star />
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td className={styles.top}>Hinta heinäkuu 2024</td>
                    <td className={styles.top}>165€ puhelin 35 € asennus, alkuneuvonta 11 €/kk jatkuva it-tuki, etähallinta, videoappi, muokattu näkymä ja toiminnot</td>
                    <td className={styles.top}>149€- 239 €</td>
                    <td className={styles.top}>275€-339€</td>
                </tr>
            </tbody>
        </table>
    </div>
);

const Star = ({ style }: { style?: CSSProperties }) => {
    return <img className={styles.star} src="/assets/icons/star.svg" alt="*" style={style} />;
};

export default Phone;
