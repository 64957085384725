import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

interface IProps {
  onCreateAccount: (data: any) => void;
}

const SignupForm = (props: IProps) => {
  let initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    tAndc: false,
  };
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState<any>({});

  const checkValidation = () => {
    const {
      firstName,
      lastName,
      email,
      mobileNumber,
      password,
      confirmPassword,
    } = formData;
    const formError: any = {};

    if (firstName === "") formError.firstName = "This is required field!";
    if (lastName === "") formError.lastName = "This is required field!";
    if (email === "") formError.email = "This is required field!";
    if (mobileNumber === "") formError.mobileNumber = "This is required field!";
    if (password === "") formError.password = "This is required field!";
    if (password.length < 11)
      formError.password = "Password should have minimum 11 characters!";
    if (password !== confirmPassword)
      formError.confirmPassword = "Password does not match!";
    return formError;
  };

  const resetError = (name: string) => {
    delete error[name];
    setError(error);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    resetError(name);
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: checked,
      };
    });
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const formError = checkValidation();
    if (Object.keys(formError).length > 0) {
      setError(formError);
    } else {
      const data = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        mobileNumber: formData.mobileNumber,
      };
      props.onCreateAccount(data);
      setFormData(initialState);
    }
  };

  return (
    <Form className="signup-form" onSubmit={submitHandler}>
      <Form.Group controlId="firstname">
        <Form.Label>Etunimi</Form.Label> {/* First Name */}
        <Form.Control
          type="text"
          placeholder="Etunimi"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          isInvalid={error.firstName}
        />
        <Form.Control.Feedback type="invalid">
          {error.firstName}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="lastname">
        <Form.Label>Sukunimi</Form.Label> {/* Last Name */}
        <Form.Control
          type="text"
          placeholder="Sukunimi"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          isInvalid={error.lastName}
        />
        <Form.Control.Feedback type="invalid">
          {error.lastName}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Label>Sähköposti</Form.Label> {/* Email */}
        <Form.Control
          type="email"
          placeholder="Sähköposti"
          name="email"
          value={formData.email}
          onChange={handleChange}
          isInvalid={error.email}
        />
        <Form.Control.Feedback type="invalid">
          {error.email}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="mobileNumber">
        <Form.Label>Puhelinnumero</Form.Label> {/* Phone Number */}
        <Form.Control
          type="number"
          placeholder="Puhelinnumero"
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleChange}
          isInvalid={error.mobileNumber}
        />
        <Form.Control.Feedback type="invalid">
          {error.mobileNumber}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Salasana</Form.Label>
        <Form.Control
          type="password"
          placeholder="Uusi salasana"
          name="password"
          value={formData.password}
          onChange={handleChange}
          isInvalid={error.password}
        />
        <small className="password-length">Vähintään 11 ​​merkkiä</small>{" "}
        {/* At least 11 characters */}
        <Form.Control.Feedback type="invalid">
          {error.password}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="confirmPassword">
        <Form.Control
          type="password"
          placeholder="Vahvista salasana"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          isInvalid={error.confirmPassword}
        />
        <Form.Control.Feedback type="invalid">
          {error.confirmPassword}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="termsCondition" className="termscondition">
        <Form.Control
          type="checkbox"
          name="tAndc"
          onChange={handleCheck}
          checked={formData.tAndc}
        />
        <Form.Label className="termsLabel">
          {/* I agree to Terms and Conditions of Digihappy */}
          Hyväksyn Digihappyn{" "}
          <Link to="/palveluehdot" target="_blank">
            Käyttöehdot
          </Link>
        </Form.Label>
      </Form.Group>

      <Button
        style={{
          float: "right",
        }}
        type="submit"
        className="tallenna user-form-button"
        disabled={!formData.tAndc}
      >
        LUO KÄYTTÄJÄTUNNUS {/* Create account */}
      </Button>
    </Form>
  );
};

export default SignupForm;
