import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import StepsHeader from "../components/StepsHeader";
import CheckoutForm from "../components/CheckoutForm";
import { getUserRequest, savePaymentMethod } from "../redux/actions";
import { AppState } from "../redux/types";
import StepsNotification from "../components/StepsNotification";
import { STRIPE_PUBLIC_KEY } from "../utils/constants";

const PaymentMethod = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state: AppState) => state.cart);
    const user = useSelector((state: AppState) => state.user);
    const { shippingMethod, address, postalCode, city } = cart;
    const [paymentMethod, setPaymentMethod] = useState(cart.paymentMethod || "card");
    const [stripePaymentMethod, setStripePaymentMethod] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(true);
    const category = useSelector((state: AppState) => state.categories.category?.attributes.slug);
    const stripePublicKey = STRIPE_PUBLIC_KEY;

    const history = useHistory();

    if (shippingMethod === "posti") {
        if (!address || !postalCode || !city) {
            history.push("/toimitus");
        }
    }
    if (user.id && !user.stripePublicKey) {
        dispatch(getUserRequest(user.id));
    }

    // back button
    const handleClick = () => {
        history.push(`/toimitus`);
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        dispatch(savePaymentMethod(paymentMethod));
        history.push("/tee_tilaus");
    };

    useEffect(() => {
        console.log(paymentMethod);
        if (paymentMethod === "card" && !stripePaymentMethod) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    }, [paymentMethod, stripePaymentMethod]);

    return (
        <>
            <StepsHeader step1 step2 step3 step4 user={2} payment={1} account={1} />
            <FormContainer>
                <h1 className="paymentmethod-header">Valitse maksutapa</h1>
                <Form.Group>
                    <Col className="paymentmethod-col">
                        <Form.Check className="radio-button" type="radio" label="Pankki- tai luottokortti" id="card" name="paymentMethod" value="card" checked={paymentMethod === "card"} onChange={(e) => setPaymentMethod(e.target.value)} />
                        {(!!user.stripePublicKey || (category === "alarm" && !!stripePublicKey)) && paymentMethod === "card" ? (
                            <CheckoutForm stripePublicKey={user.stripePublicKey || stripePublicKey} setSPM={setStripePaymentMethod} handleForm={submitHandler} handleBack={handleClick} />
                        ) : (
                            <div>
                                <small className="password-length">Stripe is loading...</small>
                            </div>
                        )}
                        <Form.Check
                            className="radio-button"
                            type="radio"
                            label="Sähköpostilasku"
                            id="email_billing"
                            name="paymentMethod"
                            value="email_billing"
                            checked={paymentMethod === "email_billing"}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        {paymentMethod === "email_billing" && (
                            <small className="for-example">Emme suosittele tätä laskutusmuotoa, koska se hidastaa palvelun käyttöönottoa ja palvelun toistuvaisveloitukset ovat asiakkaalle työläämpiä.</small>
                        )}
                        <Form.Check
                            className="radio-button"
                            type="radio"
                            label="Laskutussopimus kotisairaanhoidon, hoivakodin tai palveluasunnon kautta"
                            id="contract_billing"
                            name="paymentMethod"
                            value="contract_billing"
                            checked={paymentMethod === "contract_billing"}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                {!btnDisabled && (
                    <Row>
                        <Col xs={6}>
                            <Button type="button" className="tallenna paymentmethod-back-button" onClick={handleClick}>
                                Takaisin
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button
                                type="button"
                                className="tallenna"
                                disabled={btnDisabled}
                                style={{
                                    position: "absolute",
                                    right: 0,
                                }}
                                onClick={submitHandler}
                            >
                                Seuraava
                            </Button>
                        </Col>
                    </Row>
                )}
            </FormContainer>
            <StepsNotification />
        </>
    );
};

export default PaymentMethod;
