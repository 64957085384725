import { put, takeLatest, delay } from 'redux-saga/effects';
import { AxiosError as Error } from 'axios';
import api from '../../utils/api-helper/api';
import { toast } from 'react-toastify';
import {
  GetItSupportListRequestAction,
  GET_ITSUPPORT_LIST_REQUEST,
  GET_ITSUPPORT_PROFILE_REQUEST,
  GetItSupportProfileRequestAction,
  UPDATE_ITSUPPORT_PROFILE_REQUEST,
  UpdateItSupportProfileRequestAction,
  SEND_INVITE_ITSUPPORT_REQUEST,
  SendInviteItSupportRequest,
  SIGNUP_ITSUPPORT_REQUEST,
  SignUpItSupportRequest,
  DELETE_IT_SUPPORT_PROFILE_REQUEST,
  RemoveItSupportProfileRequestAction,
} from '../types';
import {
  getItSupportListSuccess,
  getItSupportListFail,
  getItSupportProfileSuccess,
  getItSupportProfileFail,
  updateItSupportProfileSuccess,
  updateItSupportProfileFail,
  sendInviteItSupportSuccess,
  sendInviteItSupportFail,
  signUpItSupportSuccess,
  signUpItSupportFail,
  resetItSupportMessage,
  setItSupportMessage,
  removeItSupportProfileRequestSuccess,
  removeItSupportProfileRequestFailed,
  getItSupportListRequest,
} from '../actions';

function* getItSupportListSaga(action: GetItSupportListRequestAction) {
  try {
    //@ts-ignore
    const res = yield api.get('/user/staff/list/it_support');
    if (res.status === 200) {
      yield put(getItSupportListSuccess(res.data.payload));
    }
  } catch (error) {
    yield put(getItSupportListFail(error));
  }
}

function* getItSupportProfileSaga(action: GetItSupportProfileRequestAction) {
  const id = action.payload;

  try {
    //@ts-ignore
    const res = yield api.get(`user/staff/${id}`);
    yield put(getItSupportProfileSuccess(res.data.payload));
  } catch (error) {
    yield put(getItSupportProfileFail(error));
  }
}

function* updateItSupportProfileSaga(
  action: UpdateItSupportProfileRequestAction
) {
  try {
    //@ts-ignore
    const res = yield api.post(
      'user/staff/it_support/edit-profile',
      action.payload
    );
    if (res.data.status === 200) {
      yield put(updateItSupportProfileSuccess({ profile: action.payload }));
      yield put(setItSupportMessage(res.data));
    }
  } catch (error) {
    yield put(updateItSupportProfileFail(error));
  } finally {
    yield delay(1000);
    yield put(resetItSupportMessage());
  }
}

function* sendInviteItSupportSaga(action: SendInviteItSupportRequest) {
  const data = action.payload;

  try {
    //@ts-ignore
    const res = yield api.post('user/staff/request-invite', data);
    if (res.data.status === 200) {
      yield put(sendInviteItSupportSuccess());
      yield put(setItSupportMessage(res.data));
    }
  } catch (error) {
    yield put(sendInviteItSupportFail(error));
  } finally {
    yield delay(1000);
    yield put(resetItSupportMessage());
  }
}

function* signUpItSupportSaga(action: SignUpItSupportRequest) {
  const data = action.payload;

  try {
    //@ts-ignore
    const res = yield api.post('user/staff/add-staff', data);
    if (res.data.status === 200) {
      yield put(signUpItSupportSuccess());
      yield put(setItSupportMessage(res.data));
    }
  } catch (error) {
    yield put(signUpItSupportFail(error));
  } finally {
    yield delay(1000);
    yield put(resetItSupportMessage());
  }
}

function* removeItSupportProfileSaga(
  action: RemoveItSupportProfileRequestAction
) {
  try {
    //@ts-ignore
    const res = yield api.post('user/support-staff-delete', action.payload);
    if (res.data.status === 200) {
      yield put(removeItSupportProfileRequestSuccess());
      yield put(getItSupportListRequest());
      toast.success(res.data?.message, {
        position: 'top-center',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  } catch (error) {
    yield put(removeItSupportProfileRequestFailed());
    if (error instanceof Error) {
      toast.error(error?.response?.data?.message, {
        position: 'top-center',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }
}

const sagaWatcher = [
  takeLatest(GET_ITSUPPORT_LIST_REQUEST, getItSupportListSaga),
  takeLatest(GET_ITSUPPORT_PROFILE_REQUEST, getItSupportProfileSaga),
  takeLatest(UPDATE_ITSUPPORT_PROFILE_REQUEST, updateItSupportProfileSaga),
  takeLatest(SEND_INVITE_ITSUPPORT_REQUEST, sendInviteItSupportSaga),
  takeLatest(SIGNUP_ITSUPPORT_REQUEST, signUpItSupportSaga),
  takeLatest(DELETE_IT_SUPPORT_PROFILE_REQUEST, removeItSupportProfileSaga),
];

export default sagaWatcher;
