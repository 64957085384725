import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, Row, InputGroup} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'

import Message from './Message'
import {clearUserError, registerCustomerRequest} from '../redux/actions'
import {AppState} from '../redux/types'

const RegisterCustomerForm = () => {
    const [username, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [role, setRole] = useState('customer')
    const [message, setMessage] = useState('')
    const [btnDisabled, setBtnDisabled] = useState(true)
    let error = useSelector((state: AppState) => state.user.error)
    const selectedServices = useSelector((state: AppState) => state.cart.inCart)
    const history = useHistory()

    const [showPass, setShowPass] = useState(false)
    const showPassHandler = () => {
        setShowPass(current => !current)
    }

    const dispatch = useDispatch()

    useEffect(() => {
        // console.log(username);
        // console.log(email);
        // console.log(password);
        // console.log(firstName);
        // console.log(lastName);
        if (!username || !email || !password || !firstName || !lastName) {
            setBtnDisabled(true)
        } else {
            setBtnDisabled(false)
        }
        let msg = ''
        if (password.length > 0 && password.length < 11) {
            msg += 'Salasana on liian lyhyt'
        }
        if (username.length > 0 && email.length > 0 && email !== username) {
            msg += 'Sähköpostit eivät täsmää\n'
        }
        setMessage(msg)
    }, [username, email, password, firstName, lastName])

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        if (password.length >= 11) {
            setRole(role)
            const redirect = selectedServices.length > 0 ? '/toimitus' : null;
            dispatch(
                registerCustomerRequest(
                    username,
                    email,
                    password,
                    firstName,
                    lastName,
                    role,
                    history,
                    redirect
                )
            )
            // history.push('/kirjaudu')
            setUserName('')
            setEmail('')
            setPassword('')
            setFirstName('')
            setLastName('')
            setMessage('Kiitos, rekisteröinti onnistui.')
            setTimeout(() => {
                setMessage('');
                dispatch(
                    clearUserError()
                )
            }, 3000)
        }
    }

    return (
        <Container className='register-form-container'>
            {error && <Message variant='danger'>{error}</Message>}
            {message && <Message className='message' variant='danger'>{message}</Message>}
            <Form onSubmit={submitHandler} className='register-form'>
                <h1 className='register-header mb-4 text-center'>Luo tili</h1>
                <Form.Group controlId='username-register'>
                    <Form.Label>Sähköpostiosoite</Form.Label>
                    <Form.Control
                        type='email'
                        name='email'
                        placeholder='Sähköpostiosoite'
                        value={username}
                        onChange={(e) => {
                            setUserName(e.target.value)
                        }}
                    />
                </Form.Group>
                <Form.Group controlId='email-register'>
                    <Form.Label>Toista sähköpostiosoite</Form.Label>
                    <Form.Control
                        name='email'
                        type='email'
                        placeholder='Toista sähköpostiosoite'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId='password-register'>
                    <Form.Label>Salasana</Form.Label>
                    <InputGroup>
                    <Form.Control
                        name='password'
                        type={showPass ? 'text' : 'password'}
                        placeholder='Salasana'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                        <Button variant="outline-secondary shadow-none show-password-btn"
                                onClick={showPassHandler}>
                            <i className={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </Button>
                    </InputGroup>
                    <small className='password-length'>Vähintään 11 merkkiä. Näet salasanasi klikkaamalla silmäkuvaketta</small>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col sm={6}>
                        <Form.Label>Etunimi</Form.Label>
                        <Form.Control
                            required
                            type='text'
                            name='firstName'
                            placeholder='Etunimi'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Col>
                    <Col sm={6}>
                        <Form.Label>Sukunimi</Form.Label>
                        <Form.Control
                            required
                            name='lastName'
                            type='text'
                            placeholder='Sukunimi'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <small className='terms-and-conditions'>
                    Painamalla 'Tallenna' vahvistan hyväksyneeni <Link to='terms' target="_blank" className="conditions">palvelun
                    käyttöehdot</Link> ja <Link to='copyright' target="_blank" className="conditions">tietosuojakäytännön</Link>.
                </small><br/>
                <Button className='register-button tallenna' type='submit' disabled={btnDisabled}>
                    Tallenna
                </Button>
                <Row>
                    <Col className='forgot-password'>
                        <Link className='forgot-password' to='/kirjaudu'>Oletko jo rekisteröitynyt? Kirjaudu sisään</Link>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default RegisterCustomerForm
