import {
    CouponItem,
    CREATE_COUPON_FAIL,
    CREATE_COUPON_REQUEST,
    CREATE_COUPON_SUCCESS,
    DELETE_COUPON_FAIL,
    DELETE_COUPON_REQUEST,
    DELETE_COUPON_SUCCESS,
    DeleteCouponRequestAction,
    GET_COUPONS_FAIL,
    GET_COUPONS_REQUEST,
    GET_COUPONS_SUCCESS,
    RESET_COUPONS,
} from '../types'

// For admin
// TODO: update and remove Coupon
export const createCouponRequest = (coupon: CouponItem) => {
    return {
        type: CREATE_COUPON_REQUEST,
        payload: {
            coupon
        }
    }
}

export const createCouponSuccess = (coupon: CouponItem) => {
    return {
        type: CREATE_COUPON_SUCCESS,
        payload: {
            coupon
        }
    }
}

export const createCouponFail = (error: any) => {
    return {
        type: CREATE_COUPON_FAIL,
        payload: {
            error
        }
    }
}

export const deleteCouponRequest = (id: DeleteCouponRequestAction) => {
    return {
        type: DELETE_COUPON_REQUEST,
        payload: id
    }
}

export const deleteCouponSuccess = () => {
    return {
        type: DELETE_COUPON_SUCCESS,
    }
}

export const deleteCouponFail = (error: any) => {
    return {
        type: DELETE_COUPON_FAIL,
        payload: error,
    }
}

export const getCouponsRequest = () => {
    return {
        type: GET_COUPONS_REQUEST,
    }
}

export const getCouponsSuccess = (coupons: CouponItem[]) => {
    return {
        type: GET_COUPONS_SUCCESS,
        payload: coupons
    }
}
export const getCouponsFail = (error: any) => {
    return {
        type: GET_COUPONS_FAIL,
        payload: {
            error
        }
    }
}

export const ResetCoupons = () => {
    return {
        type: RESET_COUPONS,
        payload: {}
    }
}
