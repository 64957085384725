import { CategoriesActions, CategoriesState, GET_CATEGORIES_SUCCESS } from "../types";

export const categories = (
    state: CategoriesState = {
        category: undefined,
        loading: false,
        error: null,
    },
    action: CategoriesActions
): CategoriesState => {
    switch (action.type) {
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                category: action.payload,
            };
        default:
            return state;
    }
};

export default categories;
