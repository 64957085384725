import styles from "./thanks.module.css";
import StepsHeader from "../../components/StepsHeader";

export default function Thanks() {
    return (
        <div>
            <StepsHeader step1 step2 step3 step4 step5 />
            <div className={styles.container}>
                <h2 className={styles.header}>Tilauksesi on valmis!</h2>
                <p className={styles.message}>Saat sähköpostiin tialusvahvistuksen ja tietoa palvelun käyttöönotosta. Tuotteiden toimitusaika on yleensä 1-2 viikkoa.</p>
                <p className={styles.note}>Huom! Jos käytät Gmail-osoitetta, tarkastathan myös "Tarjoukset" ("Promotions") kansion.</p>
            </div>
        </div>
    );
}
