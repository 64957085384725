import { GET_NURSES_FAIL, GET_NURSES_REQUEST, GET_NURSES_SUCCESS,ADD_NURSE_REQUEST,ADD_NURSE_SUCCESS, ADD_NURSE_FAILED, UPDATE_NURSE_REQUEST,
    UPDATE_NURSE_SUCCESS,UPDATE_NURSE_FAILED, ASSIGNED_SENIOR_REQUEST,ASSIGNED_SENIOR_SUCCESS,
    ASSIGNED_SENIOR_FAILED,GET_NURSE_PROFILE_REQUEST, GET_NURSE_PROFILE_SUCCESS, GET_NURSE_PROFILE_FAILED,ASSIGNED_SENIOR_LIST_REQUEST,
    ASSIGNED_SENIOR_LIST_SUCCESS,ASSIGNED_SENIOR_LIST_FAILED, SIGNUP_NURSE_REQUSET, SIGNUP_NURSE_SUCCESS, SIGNUP_NURSE_FAILED, RESET_NURSE_MESSAGE,
    SET_NURSE_MESSAGE, DELETE_NURSE_REQUEST, DELETE_NURSE_REQUEST_SUCCESS, DELETE_NURSE_REQUEST_FAILED } from '../types'

export const getNursesRequest = () => {
    return {
        type: GET_NURSES_REQUEST
    }
}

export const getNursesSuccess = (list: any[]) => {
    
    return {
        type: GET_NURSES_SUCCESS,
        payload: list
    }
}

export const getNursesFail = (error: any) => {
    return {
        type: GET_NURSES_FAIL,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}

export const addNursesRequest = (data: any)=>{
    return{
        type: ADD_NURSE_REQUEST,
        payload: {
            data
        }
    }
}

export const addNursesSuccess = ()=>{
    return{
        type: ADD_NURSE_SUCCESS,
    }
}
export const addNursesFail = (error: any)=>{
    return{
        type: ADD_NURSE_FAILED,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}
export const updateNursesRequest = (data: any)=>{
    return{
        type: UPDATE_NURSE_REQUEST,
        payload: data,
    }
}
export const updateNursesSuccess = ()=>{
    return{
        type:UPDATE_NURSE_SUCCESS,
    }
}
export const updateNurseFailed = (error: any)=>{
    return{
        type: UPDATE_NURSE_FAILED,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}

export const getAssignedSeniorRequest = (id: string)=>{
    return{
        type: ASSIGNED_SENIOR_REQUEST,
        payload:{
            id
        }
    }
}
export const getAssignedSeniorSuccess = (list: any)=>{
    return{
        type:ASSIGNED_SENIOR_SUCCESS,
        payload: list
    }
}
export const getAssignedSeniorFailed = (error: any)=>{
    return{
        type: ASSIGNED_SENIOR_FAILED,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}
export const getAssignedSeniorListRequest = ()=>{
    return{
        type: ASSIGNED_SENIOR_LIST_REQUEST,
    }
}
export const getAssignedSeniorListSuccess = (list: any)=>{
    return{
        type:ASSIGNED_SENIOR_LIST_SUCCESS,
        payload: list
    }
}
export const getAssignedSeniorListFailed = (error: any)=>{
    return{
        type: ASSIGNED_SENIOR_LIST_FAILED,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}
export const getNurseProfileRequest = (id: string)=>{
    return{
        type: GET_NURSE_PROFILE_REQUEST,
        payload:{
            id
        }
    }
}
export const getNurseProfileSuccess = (data: any)=>{
    return{
        type:GET_NURSE_PROFILE_SUCCESS,
        payload: data,
    }
}
export const getNurseProfileFailed = (error: any)=>{
    return{
        type: GET_NURSE_PROFILE_FAILED,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}
export const signupNurseRequest = (data : any)=>{
    return{
        type: SIGNUP_NURSE_REQUSET,
        payload:{
            data
        }
    }
}
export const signupNurseSuccess = ()=>{
    return{
        type: SIGNUP_NURSE_SUCCESS,
    }
}
export const signupNurseFailed = (error: any)=>{
    return{
        type: SIGNUP_NURSE_FAILED,
        payload: error.response && error.response.data.message
            ? error.response.data
            : {status: error.response.status, message: error.message},
    }
}

export const resetNurseMessage = () => {
    return {
        type: RESET_NURSE_MESSAGE,
    }
}

export const setNurseMessage = (response: any) => {
    return {
        type: SET_NURSE_MESSAGE,
        payload: response,
    }
}

export const removeNurseRequest = (id: number) => {
    return {
        type: DELETE_NURSE_REQUEST,
        payload: {userId: id},
    }
}

export const removeNurseRequestSuccess = () => {
    return {
        type: DELETE_NURSE_REQUEST_SUCCESS,
    }
}

export const removeNurseRequestFailed = () => {
    return {
        type: DELETE_NURSE_REQUEST_FAILED
    }
}