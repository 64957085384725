import {
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_FAIL,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,
  Service,
  ServiceItem,
  DeleteServiceRequestAction,
} from '../types'

// For admin
// TODO: update and remove service
export const createServiceRequest = (service: ServiceItem) => {
  return {
    type: CREATE_SERVICE_REQUEST,
    payload: {
      service
    }
  }
}

export const createServiceSuccess = (service: Service) => {
  return {
    type: CREATE_SERVICE_SUCCESS,
    payload: {
      service
    }
  }
}
 
export const createServiceFail = (error: any) => {
  return {
    type: CREATE_SERVICE_FAIL,
    payload: {
      error
    }
  }
}

export const deleteServiceRequest = (id: DeleteServiceRequestAction) => {
  return {
    type: DELETE_SERVICE_REQUEST,
    payload: id
  }
}

export const deleteServiceSuccess = () => {
  return {
    type: DELETE_SERVICE_SUCCESS,
  }
}

export const deleteServiceFail = (error: any) => {
  return {
    type: DELETE_SERVICE_FAIL,
    payload: error,
  }
}
