import {
    GET_USERS_SUCCESS, UserActions, Users, GET_USER_EDIT_FORM_DATA_REQUEST, GET_USER_EDIT_FORM_DATA_SUCCESS,
    GET_USER_EDIT_FORM_DATA_FAIL, SET_USER_EDIT_FORM_DATA_REQUEST, SET_USER_EDIT_FORM_DATA_SUCCESS, SET_USER_EDIT_FORM_DATA_FAIL, RESET_USERS
} from '../types'

export const users = (
    state: any = {
        list: [],
        profile: {}
    },
    action: UserActions
): Users => {
    switch (action.type) {
        case GET_USERS_SUCCESS:
            return {
                ...state,
                list: action.payload,
                profile: {},
                error: null,
            }

        case GET_USER_EDIT_FORM_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case GET_USER_EDIT_FORM_DATA_SUCCESS:
            return {
                ...state,
                profile: action.payload,
                loading: false
            }

        case SET_USER_EDIT_FORM_DATA_FAIL:
        case GET_USER_EDIT_FORM_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        case SET_USER_EDIT_FORM_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case SET_USER_EDIT_FORM_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case RESET_USERS:
            return {
                ...state,
                list: [],
                loading: false,
                error: null
            }

        default:
            return state
    }
}


export default users
