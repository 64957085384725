import {GET_ITSUPPORT_LIST_REQUEST, GET_ITSUPPORT_LIST_SUCCESS, GET_ITSUPPORT_LIST_FAIL, ItSupportActions, ItSupport,
    GET_ITSUPPORT_PROFILE_REQUEST, GET_ITSUPPORT_PROFILE_SUCCESS, GET_ITSUPPORT_PROFILE_FAIL, UPDATE_ITSUPPORT_PROFILE_REQUEST,
    UPDATE_ITSUPPORT_PROFILE_SUCCESS, UPDATE_ITSUPPORT_PROFILE_FAIL, SEND_INVITE_ITSUPPORT_REQUEST, SEND_INVITE_ITSUPPORT_SUCCESS,
    SEND_INVITE_ITSUPPORT_FAIL, SIGNUP_ITSUPPORT_REQUEST, SIGNUP_ITSUPPORT_SUCCESS, SIGNUP_ITSUPPORT_FAIL, RESET_ITSUPPORT_MESSAGE,
    SET_ITSUPPORT_MESSAGE, DELETE_IT_SUPPORT_PROFILE_REQUEST, DELETE_IT_SUPPORT_PROFILE_REQUEST_SUCCESS, DELETE_IT_SUPPORT_PROFILE_REQUEST_FAILED} from '../types';

const initialState: any = {
    list: [],
    loading: false,
    error: null,
    itSupportProfile: {},
    message: {},
}

const itSupport = (state=initialState, action:ItSupportActions): ItSupport => {
    switch(action.type) {
        case SIGNUP_ITSUPPORT_REQUEST:
        case SEND_INVITE_ITSUPPORT_REQUEST:
        case GET_ITSUPPORT_PROFILE_REQUEST:
        case GET_ITSUPPORT_LIST_REQUEST:
        case DELETE_IT_SUPPORT_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                itSupportProfile: {},
            }

        case GET_ITSUPPORT_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false,
                error: null,
            }
        
        case GET_ITSUPPORT_PROFILE_FAIL:
        case GET_ITSUPPORT_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case GET_ITSUPPORT_PROFILE_SUCCESS:
            return {
                ...state,
                itSupportProfile: action.payload,
                loading: false,
                error: null,
            }

        case UPDATE_ITSUPPORT_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case UPDATE_ITSUPPORT_PROFILE_SUCCESS:
            const {profile} = action.payload;
            const {id, email, firstName, lastName, mobileNumber} = profile;
            const _list = state.list.map(
                (item: any) => item.userId === id
                    ? {
                        ...item,
                        email: email,
                        firstName: firstName,
                        lastName:lastName,
                        mobileNumber:mobileNumber,
                        id:id
                    }
                    : item
            );
            return {
                ...state,
                loading: false,
                list: _list,
                itSupportProfile: {},
                error: null,
            }

        case SIGNUP_ITSUPPORT_SUCCESS:
        case SEND_INVITE_ITSUPPORT_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case SIGNUP_ITSUPPORT_FAIL:
        case SEND_INVITE_ITSUPPORT_FAIL:
        case UPDATE_ITSUPPORT_PROFILE_FAIL:
            return {
                ...state,
                message: action.payload,
                loading: false,
            }

        case RESET_ITSUPPORT_MESSAGE:
            return {
                ...state,
                message: {},
            }

        case SET_ITSUPPORT_MESSAGE:
            return {
                ...state,
                message: action.payload,
            }

        case DELETE_IT_SUPPORT_PROFILE_REQUEST_SUCCESS:
        case DELETE_IT_SUPPORT_PROFILE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
}

export default itSupport;