import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import Loader from './Loader';
import { toast } from 'react-toastify';
import { useState } from 'react';

const LoginForm = ({
  username,
  setUserName,
  password,
  setPassword,
  error,
  loading,
  submitHandler,
}: any) => {
  const [showPass, setShowPass] = useState(false);
  const showPassHandler = () => {
    setShowPass((current) => !current);
  };
  if (error) {
    toast.error(error, {
      hideProgressBar: true,
      position: 'bottom-center',
      theme: 'colored',
      autoClose: 2000,
    });
  }

  return (
    <>
      <Container className='login-container'>
        <Row className='justify-content-md-right login-row'>
          <Col md={{ span: 6, offset: 6 }} className='login-col'>
            {/*{error && <Message variant='danger'>{error}</Message>}*/}
            {loading && <Loader />}
            <Form onSubmit={submitHandler} className='login-form'>
              <h1 className='signin'>Kirjaudu sisään</h1>
              <Form.Group controlId='login-username'>
                <Form.Label className='label'>Käyttäjänimi</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Käyttäjänimi/sähköpostiosoite'
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId='login-password'>
                <Form.Label className='label'>Salasana</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPass ? 'text' : 'password'}
                    placeholder='Salasana'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    variant='outline-secondary shadow-none show-password-btn'
                    onClick={showPassHandler}
                  >
                    <i
                      className={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}
                    ></i>
                  </Button>
                </InputGroup>
                <div className='show-password'>
                  Näet salasanasi klikkaamalla silmäkuvaketta
                </div>
              </Form.Group>

              <Button type='submit' className='login-button tallenna'>
                Kirjaudu
              </Button>
              <Row className='forgot-password py-3'>
                <Col className='forgot-password'>
                  <Link className='forgot-password' to='/salasana/vaihda'>
                    Unohtuiko salasana?
                  </Link>
                </Col>
              </Row>

              {/*<Row>*/}
              {/*    <Col className='forgot-password'>*/}
              {/*        <Link className='forgot-password' to='/luo_tili'>Etkö ole vielä rekisteröitynyt? Luo*/}
              {/*        tili</Link>*/}
              {/*    </Col>*/}
              {/*</Row>*/}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginForm;
