import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, InputGroup, Row} from 'react-bootstrap'
import {useHistory, useParams} from "react-router-dom";
import {createBlogRequest, getBlogsRequest} from '../redux/actions'
import {AppState, BlogItem} from "../redux/types";
import MDEditor from "@uiw/react-md-editor";
import {LinkContainer} from "react-router-bootstrap";
import replaceSpecialCharacters from "replace-special-characters"
import ReactCrop, {centerCrop, type Crop, makeAspectCrop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const blogURL = (title: any) => `${replaceSpecialCharacters(title).trim().split(/[\W]+/).join('-')}`;

const BlogForm = () => {
    const ratio = {w: 3, h: 2};
    const cropPreviewWidth = 400; // px
    const myCanvas = useRef(null);
    const cropPreviewHeight = Math.floor(cropPreviewWidth / ratio.w * ratio.h); // px
    const {id} = useParams<{ id: string }>();
    const resources = useSelector((state: AppState) => state.blogs);
    const {list} = resources
    let blog: BlogItem | undefined = undefined;
    try {
        blog = list?.find(o => o.id === parseInt(id));
    } catch (error) {
        console.log(error);
    }
    const [formData, setFormData] = useState({
        id: blog ? parseInt(blog.id) : -1,
        title: blog?.title || '',
        cover: blog?.cover || '',
        cover_type: blog?.cover_type || 'image',
        topics: blog?.topics.map(function (i: any) {
            return i.name
        }).join() || '',
        content_preview: blog?.content_preview || '',
        author_id: undefined,
        featured: blog?.featured || false,
        createdAt: undefined,
        updatedAt: undefined,
        url: blog?.url
    })

    const [content, setContent] = useState(blog?.content || '');
    const [crop, setCrop] = useState<Crop>()
    const [naturalImage, setNaturalImage] = useState()

    useEffect(() => {
        //@ts-ignore
        if (blog?.cropWidth && blog.cropX && blog.cropHeight && blog.cropY && naturalImage?.width && naturalImage?.height) {
            setCrop({
                //@ts-ignore
                x: blog?.cropX * (naturalImage.width / naturalImage.naturalWidth),
                //@ts-ignore
                y: blog?.cropY * (naturalImage.height / naturalImage.naturalHeight),
                //@ts-ignore
                width: blog?.cropWidth * (naturalImage.width / naturalImage.naturalWidth),
                //@ts-ignore
                height: blog?.cropHeight * (naturalImage.height / naturalImage.naturalHeight),
                unit: 'px'
            })
        }
    }, [blog, naturalImage]);

    const dispatch = useDispatch()
    const history = useHistory()

    const user = useSelector((state: AppState) => state.user)
    useEffect(() => {
        if (user && user.isAdmin) {
            dispatch(getBlogsRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let {value, name} = e.target;
        if (name === 'url') {
            value = blogURL(value)
        }
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            }
        })

        if (name === 'title') {
            setFormData((prevValue: any) => {
                return {
                    ...prevValue,
                    url: blogURL(value),
                }
            })
        }
    }

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {checked, name} = e.target
        console.log(name)
        console.log(checked)
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: checked,
            }
        })
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        // @ts-ignore
        dispatch(createBlogRequest({
            id: formData.id === -1 ? undefined : formData.id,
            title: formData.title,
            cover: formData.cover,
            cover_type: formData.cover_type,
            topics: formData.topics,
            content_preview: formData.content_preview,
            content: content,
            author_id: formData.author_id,
            featured: formData.featured,
            createdAt: formData.createdAt,
            updatedAt: formData.updatedAt,
            url: formData.url,
            // @ts-ignore
            cropX: Math.floor(crop?.x * (naturalImage.naturalWidth / naturalImage.width)),
            // @ts-ignore
            cropY: Math.floor(crop?.y * (naturalImage.naturalHeight / naturalImage.height)),
            // @ts-ignore
            cropWidth: Math.floor(crop?.width * (naturalImage.naturalWidth / naturalImage.width)),
            // @ts-ignore
            cropHeight: Math.floor(crop?.height * (naturalImage.naturalHeight / naturalImage.height))
        }))
        history.push('/admin/blogs')
        dispatch(getBlogsRequest())
    }

    const [editURL, setEditURL] = useState(false);
    const editURLHandler = () => {
        setEditURL(current => !current)
    }

    const generateURLHandler = () => {
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                url: blogURL(prevValue?.title),
            }
        })
    }

    function onImageLoad(e: any) {
        const {naturalWidth: width, naturalHeight: height} = e.currentTarget
        setNaturalImage(e.currentTarget)
        if (!blog?.cropY) {
            const crop = centerCrop(
                makeAspectCrop(
                    {
                        // You don't need to pass a complete crop into
                        // makeAspectCrop or centerCrop.
                        unit: '%',
                        width: 100,
                    },
                    ratio.w / ratio.h,
                    width,
                    height
                ),
                width,
                height
            )
            setCrop(crop)
        }
    }

    useEffect(() => {
        const canvasRender = () => {
            //@ts-ignore
            const ctx = myCanvas.current?.getContext('2d')
            if (!ctx) {
                throw new Error('No 2d context')
            }
            // const pixelRatio = window.devicePixelRatio
            // ctx.scale(pixelRatio, pixelRatio)
            ctx.imageSmoothingQuality = 'high'
            // @ts-ignore
            const cropX = crop.x * (cropPreviewWidth / crop.width)
            // @ts-ignore
            const cropY = crop.y * (cropPreviewHeight / crop.height)
            ctx.save()
            ctx.translate(-cropX, -cropY)
            //@ts-ignore
            ctx.scale(naturalImage.width / crop.width, naturalImage.height / crop.height)
            ctx.drawImage(
                naturalImage,
                0,
                0,
                //@ts-ignore
                naturalImage?.naturalWidth,
                //@ts-ignore
                naturalImage?.naturalHeight,
                0,
                0,
                //@ts-ignore
                cropPreviewWidth,
                //@ts-ignore
                cropPreviewHeight,
            )

            ctx.restore()
        }
        //@ts-ignore
        if (crop?.x && crop?.y && crop?.width && crop?.height && naturalImage?.width && naturalImage?.height) {
            canvasRender();
        }
    }, [crop?.x, crop?.y, crop?.width, crop?.height, cropPreviewHeight, cropPreviewWidth, naturalImage]);

    // @ts-ignore
    return (
        <Container className='register-form-container' data-color-mode="light">
            <Form onSubmit={submitHandler} className='service-form'>
                <Form.Group controlId='blog-title'>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        placeholder='Text'
                        name='title'
                        value={formData.title}
                        onChange={handleChange}
                        readOnly={user.readOnly}
                    />
                </Form.Group>
                <Form.Group controlId='blog-url'>
                    <Form.Label>URL</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type='text'
                            name='url'
                            placeholder='Autogenerated URL'
                            value={formData.url}
                            onChange={handleChange}
                            readOnly={user.readOnly || !editURL}
                        />
                        <Button
                            variant="outline-secondary shadow-none show-refresh-btn"
                            onClick={generateURLHandler}
                            disabled={user.readOnly}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </Button>
                        <Button
                            variant="outline-secondary shadow-none show-password-btn"
                            onClick={editURLHandler}
                            disabled={user.readOnly}
                        >
                            <i className={editURL ? 'fas fa-lock' : 'fas fa-unlock-alt'}></i>
                        </Button>
                    </InputGroup>
                    <div
                        style={{
                            padding: '8px 0',
                            color: '#666555',
                            fontSize: '0.8rem',
                        }}
                    >
                        {formData.url ?
                            `${window.location.origin}/blog/${formData.url}` :
                            `${window.location.origin}/blog/${formData.id}`
                        }
                    </div>
                </Form.Group>
                <Form.Group controlId="blog-featured">
                    <Form.Check
                        label="Featured"
                        type="checkbox"
                        name='featured'
                        checked={formData.featured}
                        onChange={handleCheck}
                        disabled={user.readOnly}
                    />
                </Form.Group>
                <Form.Group controlId="blog-cover_type">
                    <Form.Label>Cover type</Form.Label>
                    <Form.Control
                        as="select"
                        name="cover_type"
                        aria-label="Image"
                        value={formData.cover_type}
                        onChange={handleChange}
                    >
                        <option
                            id='image'
                            value="image"
                        >
                            Image
                        </option>
                        <option
                            id='video'
                            value="video"
                        >
                            Video
                        </option>
                        <option
                            id='youtube_video'
                            value="youtube_video"
                        >
                            YouTube video
                        </option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId='blog-cover'>
                    <Form.Label>Cover</Form.Label>
                    <Form.Control
                        placeholder='Cover'
                        name='cover'
                        value={formData.cover}
                        onChange={handleChange}
                        readOnly={user.readOnly}
                    />
                    {formData.cover_type === 'video' && <small className="for-example">
                        Paste full link: <span
                        style={{color: 'lightgrey'}}>https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/C0009.MP4</span>
                    </small>}
                    {formData.cover_type === 'youtube_video' && <small className="for-example">
                        Insert YouTube video ID: <span style={{color: 'lightgrey'}}>foOL310imec</span>
                    </small>}
                </Form.Group>
                {formData.cover_type === 'image' && <div>
                    <ReactCrop
                        crop={crop}
                        onChange={crop => {
                            setCrop(crop);
                        }}
                        locked={false}
                        aspect={ratio.w / ratio.h}
                        keepSelection={true}
                    >
                        <img
                            src={formData.cover}
                            alt="crop"
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                </div>}
                {formData.cover_type === 'youtube_video' && <div>
                    <ReactCrop
                        crop={crop}
                        onChange={c => {
                            setCrop(c)
                        }}
                        locked={false}
                        aspect={ratio.w / ratio.h}
                        keepSelection={true}
                    >
                        <img
                            src={`https://img.youtube.com/vi/${formData.cover}/maxresdefault.jpg`}
                            alt="crop"
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                </div>}
                {(formData.cover_type === 'image' || formData.cover_type === 'youtube_video') && <canvas
                    ref={myCanvas}
                    width={cropPreviewWidth}
                    height={cropPreviewHeight}
                    style={{
                        display: "none"
                    }}
                />
                }
                <Form.Group controlId='blog-end'>
                    <Form.Label>Topics (use <code>,</code> as separator)</Form.Label>
                    <Form.Control
                        placeholder={"Topics"}
                        name='topics'
                        value={formData.topics}
                        onChange={handleChange}
                        readOnly={user.readOnly}
                    />
                </Form.Group>
                <Form.Group controlId='blog-cover'>
                    <Form.Label>Content preview</Form.Label>
                    <Form.Control
                        placeholder='Content preview'
                        name='content_preview'
                        value={formData.content_preview}
                        onChange={handleChange}
                        readOnly={user.readOnly}
                    />
                </Form.Group>
                {user.readOnly ? <MDEditor.Markdown
                    source={content}
                /> : <MDEditor
                    value={content}
                    // @ts-ignore
                    onChange={setContent}
                />
                }
                <Row>
                    <Col>
                        <LinkContainer to={`/admin/blogs`}>
                            <Button className='tallenna paymentmethod-back-button'>
                                Takaisin
                            </Button>
                        </LinkContainer>
                    </Col>
                    {!user.readOnly &&
                        <Col
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button
                                className='create-service-button tallenna' type='submit'
                                disabled={!formData?.title}
                            >
                                Tallenna
                            </Button>
                        </Col>
                    }
                </Row>
            </Form>
        </Container>
    )
}

export default BlogForm
