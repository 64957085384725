const Senior = ({
  member,
  mobileNumber,
  lastName,
  nurseringHome,
  homeAddress,
  department,
}: any) => {
  return (
    <>
      <p className='role'>
        <strong>Seniori</strong>
      </p>
      <li className='membership'>{member} </li>
    </>
  )
}

export default Senior
