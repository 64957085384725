const StepsHeader = () => {
    return (
        <>
                <div
                    className='steps-notification'
                >
                    <div>
                        Ongelmia?
                        <br/>
                        Soita
                        <br/>
                        <a className='linkki' href="tel:010 517 2070">010 517 2070</a>
                        <br/>
                        tai whatsappaa
                        <br/>
                        <a className='linkki' href=" https://wa.me/358503210100" target="_blank">050 321 0100</a>
                    </div>
                </div>
        </>
    )
}

export default StepsHeader
