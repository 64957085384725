import { put, select, takeLatest } from "redux-saga/effects";

import { getProductsFail, getProductsSuccess } from "../actions";
import { GET_PRODUCTS_REQUEST, GetProductsRequestAction, CMSProductType } from "../types";
import cmsApi from "../../utils/api-helper/cmsApi";
import { CMS_ENVIRONMENT } from "../../utils/constants";

function* getProductsSaga(action: GetProductsRequestAction) {
    try {
        //@ts-ignore
        const res = yield cmsApi.get("/products?populate[category][fields][0]=slug&populate[FirstBox][populate][Image][fields][0]=url");
        const products = (res.data.data as CMSProductType[]).map((product) => ({
            id: product.id,
            name: product.attributes.Name,
            category: product.attributes.category.data.attributes.slug,
            image: product.attributes.FirstBox[0]?.__component === "boxes.image" ? product.attributes.FirstBox[0].Image.data.attributes.url : "",
            price: product.attributes.Price,
            isActive: product.attributes.Active,
            stripeProductId: CMS_ENVIRONMENT === "prod" ? product.attributes.StripeProductId : product.attributes.StripeProductTestId,
            priceType: product.attributes.PriceType?.toLowerCase(),
            period: product.attributes.Period,
            sortNumber: product.attributes.SortNumber,
            showNameLine: product.attributes.ShowNameLine,
            locked: product.attributes.Locked,
            firstBox: product.attributes.FirstBox,
            secondBox: product.attributes.SecondBox,
            thirdBox: product.attributes.ThirdBox,
            emphasized: product.attributes.Emphasized,
            selected: product.attributes.Selected,
        }));
        yield put(getProductsSuccess(products));
    } catch (error) {
        yield put(getProductsFail(error));
    }
}

// for admin

function* saveState() {
    //@ts-ignore
    const state = yield select();
    yield localStorage.setItem("initState", JSON.stringify(state));
}

const sagaWatcher = [takeLatest(GET_PRODUCTS_REQUEST, getProductsSaga), takeLatest("*", saveState)];

export default sagaWatcher;
