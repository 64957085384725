import {
    CREATE_ORDER_FAIL,
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    DELETE_ORDER_FAIL,
    DELETE_ORDER_REQUEST,
    DELETE_ORDER_SUCCESS,
    DeleteOrderRequestAction,
    GET_ORDERS_FAIL,
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    Order,
    PickupPoint,
    RESET_ORDERS,
} from '../types'

export const getOrdersRequest = () => {
    console.log('here')
    return {
        type: GET_ORDERS_REQUEST
    }
}

export const getOrdersSuccess = (list: Order[]) => {
    return {
        type: GET_ORDERS_SUCCESS,
        payload: list
    }
}

export const getOrdersFail = (error: any) => {
    return {
        type: GET_ORDERS_FAIL,
        payload: {
            error: error.response && error.response.data.message ?
                error.response.data.message :
                error.message
        }
    }
}

export const createOrderRequest = (order: {
    name: string,
    address: string,
    shippingPrice: number,
    shippingMethod: string,
    city: string,
    country: string,
    email: string,
    mobile: string,
    totalPrice: number,
    price: number,
    postalCode: string,
    paymentMethod: any,
    stripePaymentMethod: any,
    taxPrice: number,
    services: any[],
    pickupPoint: string,
    pickupPointData: PickupPoint,
    usePickupPoint: boolean,
}) => {
    console.log('order in action request', order)
    return {
        type: CREATE_ORDER_REQUEST,
        payload: order
    }
}

export const createOrderSuccess = (session: any) => {
    console.log('order in action success', session)
    return {
        type: CREATE_ORDER_SUCCESS,
        payload: session
    }
}

export const createOrderFail = (error: any) => {
    return {
        type: CREATE_ORDER_FAIL,
        payload: {
            error: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        }
    }
}


export const deleteOrderRequest = (id: DeleteOrderRequestAction) => {
    return {
        type: DELETE_ORDER_REQUEST,
        payload: id
    }
}

export const deleteOrderSuccess = () => {
    return {
        type: DELETE_ORDER_SUCCESS,
    }
}

export const deleteOrderFail = (error: any) => {
    return {
        type: DELETE_ORDER_FAIL,
        payload: error,
    }
}

export const ResetOrders = () => {
    return {
        type: RESET_ORDERS,
        payload: {}
    }
}
