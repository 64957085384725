import {GET_ORDERS_SUCCESS, OrderActions, Orders, RESET_ORDERS,} from '../types'

export const orders = (
    state: any = {
        list: [],
    },
    action: OrderActions
): Orders => {
    // console.log(action)
    switch (action.type) {
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                list: action.payload,
            }

        case RESET_ORDERS:
            return {
                list: [],
                loading: false,
                error: null
            }

        default:
            return state
    }
}

export default orders