const texts = [
  // 0 Homepage: bottom pic
  {
    text1: 'Toimiva senioritabletti palveluna',
    text2: 'Lisätietoja > ',
    text3: 'Tilaa',
    link: '/tilaa',
  },
  // 1 Homepage: textarea
  {
    text1: 'Kuukausimaksullinen palvelu',
  },
  // 2 Homepage: textarea
  {
    text1: 'Senioriystävällinen Samsung tai Apple tabletti, jalusta, rajaton data',
    text2: 'Ominaisuudet muokattu yksilöllisesti seniorin toimintakyvyn mukaan',
    text3: 'Helpot videopuhelut Digihappy apilla',
    text4: 'Suositut sovellukset mm. äänikirjat, uutiset, viihde',
    text5: 'Käyttöönotto, jatkuva etähallinta ja it-tuki',
    text6: 'Perhetili',
    text7: 'Parempi kuin senioripuhelin. Saat kaiken helppona palveluna'
  },
  // 3 Homepage: manfamily pic
  {
    text1: 'Helppo videoyhteys. Tarvittaessa automaattinen vastaus',
    text2: 'Lue lisää > ',
    link: '/',
  },
  // 4 Homepage: anotherwoman pic
  {
    text1: 'Helppo käyttää',
    text2: 'Ominaisuudet yksilöllisesti toimintakyvyn mukaan',
    text3: 'Lue lisää > ',
    link: '/',
  },
  // 5 Homepage: it-woman pic
  {
    text1: 'Valmis käyttöön. Kontaktitiedot. It-tuki. Päivitykset.',
    text2: 'Lue lisää > ',
    link: '/',
  },
  // 6 Homepage: doctor pic
  {
    text1: 'Ajankohtaista ja kiinnostavaa tietoa terveydestä',
    text2: 'Lue lisää > ',
    link: '/',
  },
  // 7 Homepage: girl pic
  {
    text1: 'Digihappy seniorille',
    text2: 'Koska välität',
  },
  // 8 Contact page
  {
    text1: 'Ota yhteyttä',
    text2: 'Autamme sinua ja läheisiäsi'
  },
  // 9 Services page: first img
  {
    text1: 'Senioritabletti palveluna',
    text2: 'Videopuhelut myös ilman kosketusta ja',
    text3: 'aktiivista tekemistä',
    href: '#best'
  },
  // 10 Subscribe page
  {
    text4: 'Tilaa Senioritabletti-palvelu',
    href: '#products'
  },
  // 11 For Companies page
  {
    text1: 'Kotisairaanhoitoon, palveluasuntoon ja hoivakotiin',
    text2: 'Tabletti vanhuksille palveluna',
    text3: 'Toimiva yhteys hoitajaan ja läheisiin sekä kiinnostavaa tekemistä'
  },
  // 12 Usein kysyttyä
  {
    text1: 'Usein kysyttyä',
    text2: 'Täältä löydät',
    text21: 'vastauksia usein',
    text22: 'kysyttyihin kysymyksiin'
  },
]

export default texts
