import { LocalUserChoices, PreJoin } from '@livekit/components-react';
import { useDispatch } from 'react-redux';
import { joinRoomRequest } from '../../redux/actions/videoMeet';

function PreJoinRoom(props: any) {
  const dispatch = useDispatch();

  const handleSubmit = (values: LocalUserChoices) => {
    const { username, ...userChoice } = values;
    const joinRequest = {
      userName: username,
      userChoice: userChoice,
      preJoinToken: props.preJoinToken,
    };
    console.log(props,"prejoin room")
    console.log(values,"prejoin room values")
    console.log(joinRequest,"prejoin request")
    dispatch(joinRoomRequest(joinRequest));
    sessionStorage.setItem('web-domain', `${process.env.REACT_APP_VIDEO_URL}`);
  };

  return (
    <div className='pre-join-room-container'>
      <div className='pre-join-section'>
        <PreJoin
          camLabel='video'
          micLabel='audio'
          joinLabel='Join Now'
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

export default PreJoinRoom;
