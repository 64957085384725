import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteServiceRequest, getServicesRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const ServicesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getServicesRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const resources = useSelector((state: AppState) => state.resources);
  const { services, loading, error } = resources;

  const deleteHandler = (id: any) => {
    if (window.confirm("Haluatko varmasti poistaa palvelun?")) {
      dispatch(deleteServiceRequest(id));
    }
  };

  const addNewService = () => {
    history.push("/admin/service/new");
  };

  return (
    <>
      <h1
        className="my-3 ml-3"
        style={{ color: "#4e5180", fontFamily: "Poppins, sanf-serif" }}
      >
        Palvelut
      </h1>
      {user.isAdmin && (
        <Button onClick={addNewService}>Add a new service</Button>
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive className="users-table">
          <thead className="user-title">
            <tr className="user">
              <th className="text-center">ID</th>
              <th className="text-center">Lajittele numero</th>
              <th className="text-center">NIMI</th>
              <th className="text-center">KUVAUS VASEN SARAKE</th>
              <th className="text-center">KUVAUS OIKEA SARAKE</th>
              <th className="text-center">HINTA</th>
              <th className="text-center">KATEGORIA</th>
              <th className="text-center">Aktiivinen</th>
              <th className="text-center">Stripe product ID</th>
              <th className="text-center">
                {user.readOnly || !user.isAdmin ? "Näkymä" : "Päivitä /Poista"}
              </th>
            </tr>
          </thead>
          <tbody>
            {services &&
              services.map((service: any) => (
                <tr key={service.id}>
                  <td>{service.id}</td>
                  <td>{service.sortNumber}</td>
                  <td>{service.name}</td>
                  <td>{service.descriptionFirst}</td>
                  <td>{service.descriptionSecond}</td>
                  <td>{service.price}</td>
                  <td>{service.category}</td>
                  <td>
                    {service.isActive ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "#4e5180" }}
                      ></i>
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>
                  <td>
                    {service.category !== "shipping" && (
                      <div>{service.stripeProductId || "none"}</div>
                    )}
                    {service.category === "shipping" && (
                      <div>
                        <div title="paid">
                          <i
                            className="fas fa-euro-sign"
                            style={{ display: "inline", paddingRight: 4 }}
                          />
                          {service.stripeShippingRateId || "none"}
                        </div>
                        <hr />
                        <div title="free">
                          <i
                            className="fas fa-gift"
                            style={{ display: "inline", paddingRight: 4 }}
                          />
                          {service.stripeShippingRateIdFree || "none"}
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/service/${service.id}`}>
                      <Button className="btn-sm">
                        <i
                          className={
                            user.readOnly || !user.isAdmin
                              ? "fas fa-eye"
                              : "fas fa-edit"
                          }
                          style={{ color: "#fff" }}
                        />
                      </Button>
                    </LinkContainer>
                    {!user.readOnly && user.isAdmin && (
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => deleteHandler(service.id)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ServicesList;
