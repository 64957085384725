import { GET_SHIPPING_FAIL, GET_SHIPPING_REQUEST, GET_SHIPPING_SUCCESS, ShippingMethod } from "../types";

export const getShippingSuccess = (shipping: ShippingMethod[]) => {
    return {
        type: GET_SHIPPING_SUCCESS,
        payload: shipping,
    };
};
export const getShippingFail = (error: any) => {
    return {
        type: GET_SHIPPING_FAIL,
        payload: {
            error,
        },
    };
};
export const getShippingRequest = () => {
    return {
        type: GET_SHIPPING_REQUEST,
    };
};
