import { ShippingMethodsActions, ShippingMethodsState, GET_SHIPPING_SUCCESS } from "../types";

export const shippingMethods = (
    state: ShippingMethodsState = {
        shipping: undefined,
        loading: false,
        error: null,
    },
    action: ShippingMethodsActions
): ShippingMethodsState => {
    switch (action.type) {
        case GET_SHIPPING_SUCCESS:
            return {
                ...state,
                loading: false,
                shipping: action.payload,
            };
        default:
            return state;
    }
};

export default shippingMethods;
