import { put, takeLatest } from 'redux-saga/effects';
import { JOIN_ROOM_REQUEST, JoinRoomRequestAction } from '../types';
import {
  joinRoomRequestFail,
  joinRoomRequestSuccess,
} from '../actions/videoMeet';
import { toast } from 'react-toastify';
import api from '../../utils/api-helper/api';

function* joinRoomRequestSaga(action: JoinRoomRequestAction) {
  try {
    //@ts-ignore
    const res = yield api.post(`/meet/${action.payload.preJoinToken}`, {
      user_name: action.payload.userName,
    });
console.log(res,"saga join room request success")
    if (res.status === 200) {
      const payload = {
        userName: res.data.payload.user_name,
        roomId: res.data.payload.room_id,
        userChoice: action.payload.userChoice,
        preJoinToken: action.payload.preJoinToken,
      };
      yield put(joinRoomRequestSuccess(payload));
    }
  } catch (error: any) {
    console.log(error,"saga join room request error")
    if (error) {
      toast.error(error.response.data.message, {
        position: 'top-center',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    yield put(joinRoomRequestFail(error));
  }
}

const sagaWatcher = [takeLatest(JOIN_ROOM_REQUEST, joinRoomRequestSaga)];

export default sagaWatcher;
