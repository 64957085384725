import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addNursesRequest } from "../redux/actions";
import FormContainer from "./FormContainer";
import { AppState } from "../redux/types";
import UserInviteForm from "./UserInviteForm";

const NurseForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  const seniorHomes = useSelector((state: AppState) => state.seniorHomes.list);

  useEffect(() => {
    if (user && !user.isAdmin) {
      history.push("/kirjaudu");
    }
  }, [dispatch, history, user]);

  const handleSendInvite = (data: any) => {
    const _data = {
      ...data,
      isNurse: true,
    };
    dispatch(addNursesRequest(_data));
    history.push("/admin/nurse");
  };

  return (
    <FormContainer>
      <h1 className="user-form-header" style={{ fontSize: "2.4rem" }}>
        LISÄÄ UUSI SAIRAANHOITAJA
      </h1>
      {/* Add New Nurse */}
      <UserInviteForm
        onSendInvite={handleSendInvite}
        seniorHomes={seniorHomes}
      />
    </FormContainer>
  );
};
export default NurseForm;
