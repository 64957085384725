import { ProductsActions, ProductsState, GET_PRODUCTS_SUCCESS, UPDATE_PRODUCTS } from "../types";

export const products = (
    state: ProductsState = {
        products: [],
        loading: false,
        error: null,
    },
    action: ProductsActions
): ProductsState => {
    switch (action.type) {
        case GET_PRODUCTS_SUCCESS:
            return {
                error: null,
                loading: false,
                products: action.payload,
            };
        case UPDATE_PRODUCTS:
        default:
            return state;
    }
};

export default products;
