import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteCouponRequest, getCouponsRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const CouponsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getCouponsRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const resources = useSelector((state: AppState) => state.coupons);
  const { list, loading, error } = resources;

  const deleteHandler = (id: any) => {
    if (window.confirm("Haluatko varmasti poistaa kupongin?")) {
      dispatch(deleteCouponRequest(id));
    }
  };

  const addNewCoupon = () => {
    history.push("/admin/coupon/new");
  };

  return (
    <>
      <h1
        className="my-3 ml-3"
        style={{ color: "#4e5180", fontFamily: "Poppins, sanf-serif" }}
      >
        Coupons
      </h1>
      {user.isAdmin && <Button onClick={addNewCoupon}>Add a new coupon</Button>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive className="users-table">
          <thead className="user-title">
            <tr className="user">
              <th className="text-center">ID</th>
              <th className="text-center">CODE</th>
              <th className="text-center">Free shipping</th>
              <th className="text-center">TRIAL DAYS</th>
              <th className="text-center">
                {user.readOnly || !user.isAdmin ? "Näkymä" : "Päivitä /Poista"}
              </th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list?.map((coupon: any) => (
                <tr key={coupon.id}>
                  <td>{coupon.id}</td>
                  <td>{coupon.code}</td>
                  <td>
                    {coupon?.data?.free_shipping ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "#4e5180" }}
                      />
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }} />
                    )}
                  </td>
                  <td>{coupon.data?.period}</td>
                  <td>
                    <LinkContainer to={`/admin/coupon/${coupon.id}`}>
                      <Button className="btn-sm">
                        <i
                          className={
                            user.readOnly || !user.isAdmin
                              ? "fas fa-eye"
                              : "fas fa-edit"
                          }
                          style={{ color: "#fff" }}
                        />
                      </Button>
                    </LinkContainer>
                    {!user.readOnly && user.isAdmin && (
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => deleteHandler(coupon.id)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default CouponsList;
