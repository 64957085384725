import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAlertsRequest } from "../../redux/actions";
import { AppState } from "../../redux/types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button";
import Banner from "../../components/layout/banner/Banner";
import { ServicesType } from "../phone/Phone";
import { classNames } from "../../utils/classnames";
import styles from "./watch.module.css";

const Watch = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const services: ServicesType[] = [
        {
            content: (
                <>
                    <div className="services-info-title">Paikantava Posifon turvaranneke</div>
                    <ul className="services-info-text">
                        <li>Gps 4G paikannus, vesitiivis IP67</li>
                        <li>Turvaranneke, kevyt 23 g, silikonia</li>
                        <li>Kaulamalli kevyt 21 g</li>
                        <li>Lisävarusteet valinnan mukaan</li>
                        <li>
                            <a href="https://www.posifon.care/" className="global-link" target="_blank" rel="noreferrer">
                                Ruotsin suosituin
                            </a>{" "}
                            paikantava turvahälytin
                        </li>
                        <li>Digihappy takaisinostaa hyväkuntoisen alle vuoden käytössä olleen laitteen</li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/seniori-turvaranneke.png",
            alt: "Musta turvaranneke seniorin ranteessa",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Luotettavat turvahälytykset</div>
                    <ul className="services-info-text">
                        <li>Seniori hälyttää apua napin painalluksella</li>
                        <li>Hälytys menee läheisten kännyköihin</li>
                        <li>Puheyhteys aukeaa heti ja paikannus</li>
                        <li>Automaattihälytykset kun seniori poistuu alueelta, kaatuu tai akku loppuu</li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/turvaranneke-seniorille-kaulamalli.webp",
            alt: "Käsi pitelee kaulamalli-turvahälytintä",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Liittymä</div>
                    <ul className="services-info-text">
                        <li>Liittymä on Digihappylta ja valmiina laitteessa.</li>
                        <li>Hälytykset, paikannus ja puheyhteys toimivat liittymän ansiosta kaikkialla.</li>
                        <li>Laitteen toiminta EI ole sidottu seniorin kännykkään.</li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/paras-senioriturvaranneke.jpg",
            alt: "Valkoinen kissa nauttii vanhemman henkilön silittelystä, henkilöllä on turvahälytin ranteessaan",
            credit: "BILD: : Björn Johansson/Posifon",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Posifon Care appi läheisille</div>
                    <ul className="services-info-text">
                        <li>Läheinen käyttää kännykällä helppoa Posifon appia</li>
                        <li>
                            Ilmoitukset ja hälytykset menevät läheisten kännyköihin
                            <ul>
                                <li>Järjestys, vastaanottajat ja kellonajat laajasti muokattavissa</li>
                            </ul>
                        </li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/helppo-senioripuhelin.webp",
            alt: "Mies, jolla on silmälasit ja parta, katsoo puhelintaan.",
        },
    ];

    const [play, setPlay] = useState(0);
    return (
        <>
            <Helmet>
                <title>Turvaranneke vanhukselle I Gps-paikannus, hälytys. It-tuki</title>
                <meta name="description" content="Paikantava turvaranneke on paras turva vanhukselle. Helppo hälytys ja puheyhteys. Asennus ja it-tuki." />
            </Helmet>
            <Banner
                backgroundImage="/assets/images/banners/turvaranneke-senorille.jpg"
                label="Iäkäs nainen vilkaisee rannekelloaan ollessaan ulkona puistossa"
                height={`calc(100vh - ${
                    56 +
                    56 *
                        list?.filter((el) => {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
            >
                <div className="global-banner-text-container">
                    <h1 className={classNames("global-banner-title global-white", styles.bannerHeading)}>Turvaranneke</h1>
                    <p className="global-banner-text global-white">Napin painalluksella hälytys, puheyhteys ja paikannus.</p>
                    <Link to="/osta/turvaranneke" style={{ marginTop: "auto" }}>
                        <Button secondary emphasized>
                            OSTA TURVARANNEKE
                        </Button>
                    </Link>
                </div>
            </Banner>
            <Container fluid className="my-3 services-container services-container-80" style={{ minWidth: "min(900px, 100%)" }}>
                <h1 className="services-header">Turvaranneke ja Digihappy palvelu - Teemme kaiken puolestasi</h1>
                <div className="global-box-line-container">
                    {services.map((service, key) => (
                        <div className="global-box-line" key={key}>
                            {key % 2 === 0 ? (
                                <React.Fragment>
                                    <div className="global-box global-background-gray">{service.content}</div>
                                    <div className={classNames("global-image-container", "global-credit-image-container")}>
                                        <img src={service.image} alt={service.alt} className="global-image" />
                                        {service.credit && <span className="global-credit-text-right">{service.credit}</span>}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className={classNames("global-image-container", "global-credit-image-container")}>
                                        <img src={service.image} alt="H" className="global-image" about={service.alt} />
                                        {service.credit && <span className="global-credit-text">{service.credit}</span>}
                                    </div>
                                    <div className="global-box global-background-gray">{service.content}</div>
                                </React.Fragment>
                            )}
                        </div>
                    ))}
                </div>
            </Container>
            <Container fluid className="my-3 services-container service-table-container" id="palvelut">
                <div className="services-button-container">
                    <Button onClick={() => setPlay(1)}>Katso video</Button>
                    <div className="global-video-wrapper">
                        <iframe
                            src={`https://www.youtube.com/embed/PFzWQqfrVac?autoplay=${play}`}
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay"
                            allowFullScreen
                            title="Digihappy Senioritabletit"
                            style={{
                                margin: "0 auto",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                aspectRatio: 16 / 9,
                            }}
                        />
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Watch;
