// src/cmsApi.ts
import axios from "axios";

const cmsApi = axios.create({
    baseURL: `${process.env.REACT_APP_CMS_ENDPOINT}/api`,
});

cmsApi.interceptors.request.use((config: any) => {
    const token = process.env.REACT_APP_CMS_API_TOKEN;
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
});

export default cmsApi;
