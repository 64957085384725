import {Nav} from 'react-bootstrap'
import {Link} from 'react-router-dom'

const TextOnImage = ({
                         height,
                         width,
                         backgroundRepeat,
                         backgroundImage,
                         backgroundSize,
                         backgroundPosition,
                         backgroundColor,
                         imgClassName,
                         className1,
                         className2,
                         className3,
                         className4,
                         text1,
                         text2,
                         text21,
                         text22,
                         text3,
                         text4,
                         text5,
                         link,
                         href,
                     }: any) => {
    return (
        <div className={`img-fluid ${imgClassName}`}
             style={{
                 height: `${height}`,
                 width: `${width}`,
                 backgroundColor: `${backgroundColor}`,
                 backgroundRepeat: `${backgroundRepeat}`,
                 backgroundImage: `url(${backgroundImage})`,
                 backgroundSize: `${backgroundSize}`,
                 backgroundPosition: `${backgroundPosition}`,
             }}
        >
            {text1 && <div className={className1}>
                {text1}
            </div>
            }
            {text2 &&
                <div className={className2}>
                    {text2}
                    {text21 && <div>
                        {text21}
                    </div>}
                    {text22 && <div>
                        {text22}
                    </div>}
                    <Link
                        className={className3}
                        href={`${href}`}
                        to={`${link}`}
                    >
                        {text3}
                    </Link>
                </div>
            }
            {text5 &&
                <div className={className2}>
                    {text5}
                </div>
            }
            {text4 && <div><Nav.Link className={className4} href={`${href}`}>{text4}</Nav.Link></div>}
        </div>
    )
}

            export default TextOnImage
