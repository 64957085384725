import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { signupNurseRequest } from "../redux/actions/nurses";
import FormContainer from "./FormContainer";
import SignupForm from "./SignupForm";
import Message from "./Message";
import { AppState } from "../redux/types";

const RegisterNurseForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [getPath, setPath] = useState("");
  const [token, setToken] = useState("");
  const nurses: any = useSelector((state: AppState) => state.nurses);

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    let token = query.get("token");

    if (token) {
      const pathname = location.pathname.split("/");
      setPath(pathname[3]);
      setToken(token);
    } else {
      history.push("/kirjaudu");
    }
  }, [query, location, history]);

  const handleCreateAccount = (data: any) => {
    const _data = {
      ...data,
      role: getPath,
      token: token,
    };

    dispatch(signupNurseRequest(_data));
  };

  return (
    <div>
      <div className="alert-message">
        {Object.keys(nurses.message).length !== 0 && (
          <Message variant={nurses.message?.status === 200 ? "info" : "danger"}>
            <div className="message-text">{nurses.message?.message}</div>
          </Message>
        )}
      </div>
      <h1 className="user-form-header">Sairaanhoitaja</h1> {/* Nurse */}
      <FormContainer>
        <h3 className="user-form-header">Luo tili</h3> {/* Create an account */}
        <SignupForm onCreateAccount={handleCreateAccount} />
      </FormContainer>
    </div>
  );
};
export default RegisterNurseForm;
