export function classNames(...args: (string | undefined | Record<string, boolean>)[]): string {
    return args
        .filter((arg) => {
            if (typeof arg === "string") {
                return arg.trim().length > 0;
            } else if (typeof arg === "object") {
                return Object.keys(arg).some((key) => arg[key]);
            }
            return false;
        })
        .map((arg) => {
            if (typeof arg === "string") {
                return arg.trim();
            } else if (typeof arg === "object") {
                return Object.keys(arg)
                    .filter((key) => arg[key])
                    .join(" ");
            }
            return "";
        })
        .join(" ");
}
