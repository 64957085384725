import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

import FormContainer from "../components/FormContainer";
import StepsHeader from "../components/StepsHeader";

import { saveShippingAddress } from "../redux/actions";
import { AppState } from "../redux/types";
import api from "../utils/api-helper/api";
import StepsNotification from "../components/StepsNotification";

const ShippingScreen = () => {
    const cart = useSelector((state: AppState) => state.cart);
    const user = useSelector((state: AppState) => state.user);
    const shippingMethods = useSelector((state: AppState) => state.shippingMethods.shipping);
    const shipping_direct = shippingMethods?.find((el) => el.shippingMethod === "direct");
    const shipping_posti = shippingMethods?.find((el) => el.shippingMethod === "posti");
    const category = useSelector((state: AppState) => state.categories.category?.attributes.slug);
    const [shippingMethod, setShippingMethod] = useState(cart.shippingMethod);
    const [name, setName] = useState(cart.name || `${user.firstName} ${user.lastName}`);
    const [address, setAddress] = useState(cart.address);
    const [postalCode, setPostalCode] = useState(cart.postalCode);
    const [city, setCity] = useState(cart.city);
    const [country, setCountry] = useState("Suomi");
    const [email, setEmail] = useState(cart.email || user.email);
    const [mobile, setMobile] = useState(cart.mobile || user.mobileNumber);
    const [pickupPoints, setPickupPoints] = useState([]);
    const [pickupPoint, setPickupPoint] = useState(cart.pickupPoint);
    const [usePickupPoint, setUsePickupPoint] = useState(true);
    const [pickupPointLoading, setPickupPointLoading] = useState(false);
    // const [freeTierCode, setFreeTierCode] = useState('')
    // const [isFree, setIsFree] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState((shippingMethod !== "direct" && shippingMethod !== "posti") || (shippingMethod === "posti" && (!name || !address || !postalCode || !city || !country || !pickupPoint)));
    const { list } = useSelector((state: AppState) => state.seniorHomes);
    const [nursingHome, setNursingHome] = useState(cart.nursingHome || list?.filter((el) => el.show)?.[0]?.reference);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if ((shippingMethod !== "direct" && shippingMethod !== "posti") || (shippingMethod === "posti" && (!name || !address || !postalCode || !city || !country || !pickupPoint?.id))) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    }, [shippingMethod, name, address, postalCode, city, country, pickupPoint]);

    const checkPostalCode = useCallback(() => {
        if (country === "Suomi") {
            console.log(postalCode?.length);
            return postalCode?.length === 5;
        }

        return true;
    }, [postalCode, country]);

    const fetchPickupPoints = useCallback(() => {
        setPickupPointLoading(true);
        api.get(`/user/orders/points?zipCode=${postalCode}`)
            .then((resp) => {
                console.log(resp.data);
                if (resp.status === 200) {
                    setPickupPoints(resp.data.payload);
                } else {
                    setPickupPoints([]);
                }
            })
            .catch((err) => {
                setPickupPoints([]);
            })
            .finally(() => {
                setPickupPointLoading(false);
            });
    }, [postalCode]);

    useEffect(() => {
        if (!checkPostalCode()) return;
        fetchPickupPoints();
    }, [fetchPickupPoints, checkPostalCode]);

    useEffect(() => {
        if (postalCode === cart.postalCode) {
            setPickupPoint(cart.pickupPoint);
        } else {
            setPickupPoint({
                address1: null,
                address2: null,
                city: null,
                contact: null,
                countryCode: null,
                email: null,
                fax: null,
                id: null,
                name: null,
                openingHours: null,
                phone: null,
                serviceCode: null,
                serviceType: null,
                sms: null,
                state: null,
                zipcode: null,
            });
        }
    }, [setPostalCode, cart.pickupPoint, cart.postalCode, postalCode]);

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        dispatch(
            saveShippingAddress({
                shippingMethod,
                name,
                address,
                postalCode,
                city,
                country,
                email,
                mobile,
                pickupPoint,
                usePickupPoint,
                nursingHome,
            })
        );
        history.push("/maksutapa");
    };

    console.log(cart.shippingMethod);
    return (
        <>
            <StepsHeader step1 step2 step3 user={2} payment={1} account={1} />
            <FormContainer>
                <h1 className="shipping-header">Valitse toimitustapa</h1>
                <Form className="shipping-form" onSubmit={submitHandler}>
                    <Form.Group controlId="shippingMethod">
                        {/*<Form.Label>Anna koodi, jos sinulla on ilmainen kokeilu -koodi</Form.Label>*/}
                        <Form.Check
                            className="radio-button"
                            type="radio"
                            label={
                                <div>
                                    <Row>
                                        <Col xs={10}>Toimitus asiakkaalle Postin noutopisteeseen</Col>
                                        <Col xs={2}>{(shipping_posti?.price || 0).toFixed(2)} €</Col>
                                    </Row>
                                </div>
                            }
                            id="posti"
                            name="shippingMethod"
                            value="posti"
                            checked={shippingMethod === "posti"}
                            onChange={(e) => setShippingMethod(e.target.value)}
                        />
                        {category !== "alarm" && (
                            <Form.Check
                                className="radio-button"
                                type="radio"
                                label={
                                    <div>
                                        <Row>
                                            <Col xs={10}>Yhteistilaus kotisairaanhoitoon, palveluasuntoon tai hoivakotiin</Col>
                                            <Col xs={2}>{(shipping_direct?.price || 0).toFixed(2)} €</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={10}>
                                                <small className="for-example">Valitse tämä vain, jos yhteistilauksesta on sovittu edellä mainitun tuottajan kanssa</small>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                id="direct"
                                name="shippingMethod"
                                value="direct"
                                checked={shippingMethod === "direct"}
                                onChange={(e) => setShippingMethod(e.target.value)}
                            />
                        )}
                    </Form.Group>
                    {category !== "alarm" && (
                        <div
                            id="contact-info"
                            style={{
                                display: shippingMethod === "direct" ? "block" : "none",
                            }}
                        >
                            <Form.Group>
                                <Form.Label>Valitse kotisairaanhoito, palveluasunto tai hoivakoti</Form.Label>
                                <Form.Control as="select" aria-label="Asuu vanhainkodissa" value={nursingHome} onChange={(e) => setNursingHome(e.target.value)}>
                                    {list
                                        ?.filter((el) => el.show)
                                        ?.map((item) => {
                                            return (
                                                <option key={`nursing_home_${item.reference}`} id={item.reference} value={item.reference} disabled={!item.show}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                </Form.Control>
                            </Form.Group>
                        </div>
                    )}
                    <div
                        id="contact-info"
                        style={{
                            display: shippingMethod === "posti" ? "block" : "none",
                        }}
                    >
                        <Form.Group controlId="name">
                            <Form.Label>*Nimi</Form.Label>
                            <Form.Control type="text" placeholder="Nimi" name="name" value={name} onChange={(e) => setName(e.target.value)} required={shippingMethod === "posti"} />
                            {/*<small className="for-example">John Doe</small>*/}
                        </Form.Group>
                        <Form.Group controlId="address">
                            <Form.Label>*Katusoite</Form.Label>
                            <Form.Control type="text" placeholder="Katuosoite" name="address" onChange={(e) => setAddress(e.target.value)} value={address} required={shippingMethod === "posti"} />
                            {/*<small className="for-example">Tekniikantie 2</small>*/}
                        </Form.Group>
                        <Form.Group controlId="postal-code">
                            <Form.Label>*Postinumero</Form.Label>
                            <Form.Control type="text" placeholder="Postinumero" name="postalCode" onChange={(e) => setPostalCode(e.target.value)} value={postalCode} required={shippingMethod === "posti"} />
                            <small className="for-example">Postinumero on viidestä numerosta muodostuva koodi (esim. 00100). </small>
                        </Form.Group>
                        <Form.Group controlId="city">
                            <Form.Label>*Kaupunki</Form.Label>
                            <Form.Control type="text" placeholder="Kaupunki" name="city" onChange={(e) => setCity(e.target.value)} value={city} required={shippingMethod === "posti"} />
                            {/*<small className="for-example">Espoo</small>*/}
                        </Form.Group>
                        <Form.Group controlId="country">
                            <Form.Label>*Maa</Form.Label>
                            <Form.Control type="text" placeholder="Maa" name="country" onChange={(e) => setCountry(e.target.value)} value={country} required={shippingMethod === "posti"} disabled={true} />
                            {/*<small className="for-example">Suomi</small>*/}
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Sähköpostiosoite</Form.Label>
                            <Form.Control type="email" placeholder="Sähköpostiosoite" name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                            {/*<small className="for-example">digihappy@mediti.fi</small>*/}
                        </Form.Group>
                        <Form.Group controlId="mobile">
                            <Form.Label>Puhelin</Form.Label>
                            <Form.Control type="text" placeholder="Puhelin" name="mobile" onChange={(e) => setMobile(e.target.value)} value={mobile} />
                            <small className="for-example">Esimerkiksi +358 40 123456</small>
                        </Form.Group>
                        <Form.Group controlId="mobile">
                            <Form.Check
                                type="checkbox"
                                label={`Valitse postitoimituksen noutopaikka`}
                                id="usePickupPoint"
                                name="usePickupPoint"
                                checked={usePickupPoint}
                                disabled={true}
                                onChange={(e) => setUsePickupPoint(e.target.checked)}
                            />
                        </Form.Group>
                        {usePickupPoint &&
                            checkPostalCode() &&
                            (pickupPointLoading ? (
                                "Noutopisteet latautuvat.."
                            ) : (
                                <Form.Group>
                                    {pickupPoints.length > 0
                                        ? pickupPoints.map((el: any, index) => (
                                              <Form.Check
                                                  key={index}
                                                  className="radio-button"
                                                  type="radio"
                                                  label={`${el?.name} (${el?.address1}, ${el?.city})`}
                                                  id={`pickupPoint-${el?.id}`}
                                                  name="pickupPoint"
                                                  value={el?.id}
                                                  checked={el?.id === pickupPoint?.id}
                                                  onChange={(e) => setPickupPoint(el)}
                                              />
                                          ))
                                        : pickupPoints?.length === 0 && (
                                              <div>
                                                  <div>Ei löydetty</div>
                                                  <Button variant="outline-primary" onClick={fetchPickupPoints}>
                                                      Lataa luettelo uudelleen
                                                  </Button>
                                              </div>
                                          )}
                                </Form.Group>
                            ))}
                    </div>
                    <Button type="submit" className="tallenna" disabled={btnDisabled}>
                        Seuraava
                    </Button>
                </Form>
            </FormContainer>
            <StepsNotification />
        </>
    );
};

export default ShippingScreen;
