import {
    CREATE_SENIOR_HOME_SUCCESS,
    DELETE_SENIOR_HOME_SUCCESS,
    GET_SENIOR_HOMES_SUCCESS,
    SeniorHomesActions,
    SeniorHomesState
} from '../types'

export const seniorHomes = (
    state: any = {
        list: [],
        loading: false,
        deleted_at: null,
        error: null,
    },
    action: SeniorHomesActions
): SeniorHomesState => {
    switch (action.type) {
        case CREATE_SENIOR_HOME_SUCCESS:
            return {
                ...state,
                loading: false,
                senior_homes: action.payload
            }
        case DELETE_SENIOR_HOME_SUCCESS:
            return {
                ...state,
                deleted_at: Math.round(new Date().getTime() / 1000)
            }

        case GET_SENIOR_HOMES_SUCCESS:
            return {
                ...state,
                list: action.payload
            }

        default:
            return state
    }
}

export default seniorHomes
