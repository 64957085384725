import {
    CouponsActions,
    CouponsState,
    CREATE_COUPON_SUCCESS,
    DELETE_COUPON_SUCCESS,
    GET_COUPONS_SUCCESS,
    RESET_COUPONS
} from '../types'

export const alerts = (
    state: any = {
        list: [],
        loading: false,
        deleted_at: null,
        error: null,
    },
    action: CouponsActions
): CouponsState => {
    switch (action.type) {
        case CREATE_COUPON_SUCCESS:
            return {
                ...state,
                loading: false,
                alert: action.payload
            }
        case DELETE_COUPON_SUCCESS:
            return {
                ...state,
                deleted_at: Math.round(new Date().getTime() / 1000)
            }

        case GET_COUPONS_SUCCESS:
            return {
                ...state,
                list: action.payload
            }

        case RESET_COUPONS:
            return {
                list: [],
                loading: false,
                deleted_at: null,
                error: null,
            }

        default:
            return state
    }
}

export default alerts
