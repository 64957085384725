

export const getLabel = (firstName: string, lastName:string) => {
    const firstNameTitle = firstName[0].toUpperCase() + firstName.slice(1).toLowerCase();
    const lastNameTitle = lastName[0].toUpperCase() + lastName.slice(1).toLowerCase();
    return `${firstNameTitle} ${lastNameTitle}`;
}

const getOption = (item: any) => {
    const firstName = item.firstName;
    const lastName = item.lastName;
    let _item: any = {
        label: getLabel(firstName, lastName),
        value: item.userId ? item.userId : item.id,
    }
    if (item.userId) {
        _item = {..._item, imageUrl: item.imageUrl};
    }
    return _item
}

export const getOptions = (_value:any, defaultValue:any) => {
    const _options = _value.map((item:any) => getOption(item));
    const _defaultValue = defaultValue.map((item:any) => item.value);
    return _options.sort((_option:any) => _defaultValue.includes(_option.value) ? -1 : 1);
}

export const getDefaultOptions = (value:any, defaultValue:any) => {
    const _defaultValue = defaultValue?.map((item:any) => item.id);
    return defaultValue ? value?.filter(
        (_value: any) => _defaultValue.includes(_value.userId ? _value.userId : _value.id))
            .map((item: any) => getOption(item)
    ) : [];
}
